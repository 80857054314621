import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    TextField, MenuItem, Grid, Typography
} from "@material-ui/core";
import {forwardRef, useEffect, useState} from 'react';
import {gql, useQuery} from "@apollo/client";
import BackdropLoading from "../../common/BackdropLoading";

const REJECT_REASONS_QUERY = gql`
    query reject_reasons {
        reject_reasons {
            id
            name
            description
        }
    }
`;

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeStatusConfirmDialog = ({ open, setOpen, handleConfirm, newStatus }) => {
    const [rejectReasons, setRejectReasons] = useState([]);
    const [rejectReasonId, setRejectReasonId] = useState(1);
    const [openLoading, setOpenLoading] = useState(false);

    const { data, loading } = useQuery(REJECT_REASONS_QUERY, {skip: newStatus === undefined || newStatus.id !== 8});

    useEffect(() => {
        setOpenLoading(loading);
        if (loading === false && data) {
            setRejectReasons(data.reject_reasons);
        }
    }, [loading, data]);

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogTitle id="confirm-dialog-title">Cambiar estado</DialogTitle>
                <DialogContent style={{overflow: 'hidden'}}>
                    <Grid container direction={"row"} spacing={3}>
                        <Grid item xs={12}>
                            <Typography>Al cambiar el estado del expediente se enviará un email informativo a los clientes</Typography>
                        </Grid>
                        { newStatus?.id === 8 && rejectReasons.length > 0 &&
                            <Grid item xs={12}>
                                <TextField
                                    id="rejectReason"
                                    select
                                    label="Motivo de rechazo"
                                    value={rejectReasonId}
                                    onChange={(e) => { setRejectReasonId(e.target.value) }}
                                    style={{ width: "100%" }}
                                    variant="outlined">
                                    {rejectReasons.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirm(true, rejectReasonId)} color="primary" variant="outlined">
                        Aceptar
                    </Button>
                    <Button onClick={() => setOpen(false)} color="default" variant="outlined">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleConfirm(false, rejectReasonId)} color="primary" variant="outlined">
                        Aceptar sin enviar email
                    </Button>
                </DialogActions>
            </Dialog>
            <BackdropLoading open={openLoading} />
        </>
    );
}

export default ChangeStatusConfirmDialog;
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    count: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        fontWeight: 600,
        height: "100%"       
    }
}));

const CountChar = ({ title, count }) => {
    const classes = useStyles();

    return (
        <Paper style={{padding: 15, height:"100%"}}>
            <Typography variant="h5">{title}</Typography>
            <Typography variant="h1" color="primary" className={classes.count}>{count}</Typography>
        </Paper>
    );
}

export default CountChar;
import { useState } from 'react'
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from '@material-ui/core'

const AttachFile = ({ open, setOpen, title, action, handler }) => {
    const [file, setFile] = useState(null)

    const handleFile = (e) => {
        setFile(e.target.files[0])
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    type="file"
                    onChange={handleFile}
                    variant="outlined"
                    label="Arrastra el archivo"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => handler(file)}>
                    {action}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AttachFile

import Loading from '../common/Loading'
import { useState, useEffect } from 'react'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import Error from '../common/Error'
import ClientForm from './ClientForm'
import CustomTableContainer from '../common/CustomTableContainer'
import CustomModal from '../common/CustomModal'
import Notification from '../common/Notification'

const CLIENTS_QUERY = gql`
    query clients {
        clients {
            id
            created_at
            updated_at
            first_name
            last_name
            dni
            nif
            dob
            genre
            email
            phone
            phone2
            city {
                id
                name
                province {
                    id
                    name
                }
            }
            nationality
            job
            exercise
            preferred_contact
            contact_time
            active
            expedients {
                id
                title
            }
        }
    }
`

const headers = [
    { id: 'id', label: 'ID', numeric: true, width: '5%' },
    { id: 'first_name', label: 'Nombre' },
    { id: 'last_name', label: 'Apellidos' },
    { id: 'dni', label: 'DNI' },
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Teléfono' },
    { id: 'city', label: 'Ciudad' },
    { id: 'expedients', label: 'Expedientes' }
]

const Clients = () => {
    const [openNewClient, setOpenNewClient] = useState(false)
    const [openUpdateClient, setOpenUpdateClient] = useState(false)
    const [clients, setClients] = useState([])
    const [clientToUpdate, setClientToUpdate] = useState({})
    const [openNotification, setOpenNotification] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationSeverity, setNotificationSeverity] = useState('success')

    const {
        loading: clientsLoading,
        error: clientsError,
        data: clientsData,
        refetch: refetchClientsData,
        networkStatus
    } = useQuery(CLIENTS_QUERY, { notifyOnNetworkStatusChange: true })



    const formatClient = (client) => {
        return {
            ...client,
            city_id: client.city ? client.city.id : '',
            city: client.city ? client.city.name : '',
            province_id: client.city ? client.city.province.id : '',
            expedients: client.expedients.reduce((result, expedient) => {
                return result + (result !== '' ? ', ' : '') + expedient.id
            }, '')
        }
    }

    useEffect(() => {
        if (
            clientsLoading === false &&
            clientsData &&
            networkStatus === NetworkStatus.ready
        ) {
            setClients(
                clientsData.clients.map((client) => {
                    return formatClient(client)
                })
            )
        }
    }, [clientsLoading, clientsData, networkStatus])

    //cleanup
    useEffect(() => {
        return () => {}
    }, [])

    const handleRowClick = (event, id) => {
        setClientToUpdate(clients.filter((client) => client.id === id)[0])
        setOpenUpdateClient(true)
    }

    const isClientEmailDuplicated = (email, id) => {
        return (
            clients.filter(
                (client) =>
                    client.email &&
                    client.email.toUpperCase() === email.toUpperCase() &&
                    client.id !== id
            ).length > 0
        )
    }

    if (clientsError) {
        console.log(clientsError)
        return <Error error={clientsError.message} />
    } else if (!clients) {
        return <Loading />
    }

    return (
        <>
            <CustomTableContainer
                title="Clientes"
                headers={headers}
                rows={clients}
                handleRowClick={handleRowClick}
                handleNew={() => setOpenNewClient(true)}
                refresh={refetchClientsData}
            />
            <CustomModal open={openNewClient} title="Nuevo cliente">
                <ClientForm
                    refreshData={refetchClientsData}
                    closeForm={() => setOpenNewClient(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                    isEmailDuplicated={isClientEmailDuplicated}
                />
            </CustomModal>
            <CustomModal
                open={openUpdateClient}
                title={`Editar cliente ${clientToUpdate.id}`}
            >
                <ClientForm
                    refreshData={refetchClientsData}
                    closeForm={() => setOpenUpdateClient(false)}
                    dataToUpdate={clientToUpdate}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                    isEmailDuplicated={isClientEmailDuplicated}
                />
            </CustomModal>

            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
        </>
    )
}

export default Clients

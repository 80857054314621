import { Grid, Typography, Divider, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, gql, useMutation, NetworkStatus } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../common/ConfirmDialog';
import VehicleModelForm from './VehicleModelForm';
import CustomModal from '../common/CustomModal';
import BackdropLoading from '../common/BackdropLoading';



const CREATE_VEHICLE = gql`
    mutation createVehicle ($vehicle: VehicleInput!) {
        createVehicle (vehicle: $vehicle) {
            id
            model {
                id
                name
                brand {
                    id
                    name
                }
                type
            }
            plate
        }
    }
`;

const UPDATE_VEHICLE = gql`
    mutation updateVehicle ($id: Int!, $vehicle: VehicleInput!) {
        updateVehicle (id: $id, vehicle: $vehicle) {
            id
            model {
                id
                name
                brand {
                    id
                    name
                }
                type
            }
            plate
        }
    }
`;

const DELETE_VEHICLE = gql`
    mutation deleteVehicle ($id: Int!) {
        deleteVehicle (id: $id)
    }
`;

const VEHICLE_BRANDS_QUERY = gql`
    query vehicleBrands {
        vehicleBrands {
            id
            name
        }
    }
`;

const VEHICLE_MODELS_QUERY = gql`
    query vehicleModelsByBrand ($brand_id: Int!) {
        vehicleModelsByBrand (brand_id: $brand_id) {
            id
            name
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1000,
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        overflow: 'auto',
        maxHeight: "90%"
    },
    formContainer: {
        marginTop: theme.spacing(4)
    },
    section: {
        marginBottom: theme.spacing(4)
    },
    descriptionField: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    deleteButtonField: {
        marginTop: theme.spacing(2),
        float: "right"
    }
}));


const VehicleForm = ({ refreshData, closeForm, dataToUpdate, openNotification, setNotificationMessage, setNotificationSeverity }) => {
    const classes = useStyles();

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const vehicle = {
            plate,
            model_id: modelId !== '' ? modelId : null
        };
        if (dataToUpdate) {
            updateVehicle({ variables: { id: dataToUpdate.id, vehicle: vehicle } }).catch(err => {
                showNotificationError(err);
            });
        } else {
            createVehicle({ variables: { vehicle: vehicle } }).catch(err => {
                showNotificationError(err);
            });
        }
    };

    const handleDeleteButton = () => {
        deleteVehicle({ variables: { id: dataToUpdate.id } }).catch(err => {
            showNotificationError(err);
            setOpenDeleteDialog(false);
        });
    }

    const processSuccess = useCallback((message) => {
        refreshData();
        closeForm();
        setNotificationMessage(message);
        setNotificationSeverity('success');
        openNotification();
    }, [refreshData, closeForm, setNotificationMessage, setNotificationSeverity, openNotification]);

    const showNotificationError = useCallback((message) => {
        setNotificationMessage(message);
        setNotificationSeverity('error');
        openNotification();
    }, [setNotificationMessage, setNotificationSeverity, openNotification]);

    const [plate, setPlate] = useState(dataToUpdate?.plate);
    const [brandId, setBrandId] = useState(dataToUpdate?.brand_id ?? '');
    const [brands, setBrands] = useState([]);
    const [modelId, setModelId] = useState(dataToUpdate?.model_id ?? '');
    const [models, setModels] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openNewVehicleModel, setOpenNewVehicleModel] = useState(false);

    const [createVehicle, { data: createVehicleData, loading: createVehicleLoading,
        error: createVehicleError }] = useMutation(CREATE_VEHICLE);

    const [updateVehicle, { data: updateVehicleData, loading: updateVehicleLoading,
        error: updateVehicleError }] = useMutation(UPDATE_VEHICLE);

    const [deleteVehicle, { data: deleteVehicleData, loading: deleteVehicleLoading,
        error: deleteVehicleError }] = useMutation(DELETE_VEHICLE);

    const { data: vehicleBrandsData, loading: vehicleBrandsLoading } = useQuery(VEHICLE_BRANDS_QUERY);

    const { data: vehicleModelsData, loading: vehicleModelsLoading, refetch: vehicleModelsRefetch, networkStatus } 
        = useQuery(VEHICLE_MODELS_QUERY, { skip: (brandId === null || brandId === "" || brandId === ''), variables: { brand_id: brandId }, notifyOnNetworkStatusChange: true });


    useEffect(() => {
        if(createVehicleLoading === false && createVehicleData) {
            processSuccess(`Vehículo ${createVehicleData.createVehicle.id} creado`);
        } else if (createVehicleError) {
            showNotificationError(createVehicleError.message);
        }
    }, [createVehicleData, createVehicleLoading, createVehicleError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(updateVehicleLoading === false && updateVehicleData) {
            processSuccess(`Vehículo ${updateVehicleData.updateVehicle.id} actualizado`);
        } else if (updateVehicleError) {
            showNotificationError(updateVehicleError.message);
        }
    }, [updateVehicleData, updateVehicleLoading, updateVehicleError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(deleteVehicleLoading === false && deleteVehicleData) {
            if (deleteVehicleData.deleteVehicle) {
                processSuccess(`Vehículo ${deleteVehicleData.deleteVehicle} eliminado`);
            } else {
                showNotificationError('Error eliminando el vehículo');
            }
        } else if (deleteVehicleError) {
            showNotificationError(deleteVehicleError.message);
        }
    }, [deleteVehicleData, deleteVehicleLoading, deleteVehicleError, processSuccess, showNotificationError]);

    useEffect(() => {
        if (vehicleBrandsLoading === false && vehicleBrandsData) {
            setBrands([{ id: '', name: '' }, ...vehicleBrandsData.vehicleBrands]);
        }
    }, [vehicleBrandsLoading, vehicleBrandsData]);

    useEffect(() => {
        if (vehicleModelsLoading === false && vehicleModelsData && networkStatus === NetworkStatus.ready) {
            setModels([{ id: '', name: '' }, ...vehicleModelsData.vehicleModelsByBrand]);
        }
    }, [vehicleModelsLoading, vehicleModelsData, networkStatus]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    const changeBrand = e => {
        setModelId('');
        setBrandId(e.target.value);
    };

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <Grid container direction="column" className={classes.formContainer}>
                    <Typography variant="subtitle1">Datos del vehículo</Typography>
                    <Divider />
                    <Grid container className={classes.section}>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="plate"
                                label="Matrícula"
                                value={plate || ''}
                                onChange={(e)=>{setPlate(e.target.value)}}
                                required
                                inputProps={{
                                    maxLength: 20,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="brand"
                                select
                                label="Marca del vehículo"
                                value={brands.length === 0 ? '' : (brandId || '')}
                                onChange={changeBrand}
                                helperText="Selecciona la marca del vehículo"
                            >
                                {brands.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field} container alignItems="center" spacing={2} justifyContent="flex-start">
                            <Grid item>
                                <TextField
                                    id="brand"
                                    select
                                    label="Modelo del vehículo"
                                    value={models.length === 0 ? '' : (modelId || '')}
                                    onChange={(e) => { setModelId(e.target.value) }}
                                    helperText="Selecciona el modelo del vehículo"
                                >
                                    {models.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="default"
                                    startIcon={<AddIcon />}
                                    className={classes.button}
                                    onClick={() => setOpenNewVehicleModel(true)}
                                >
                                    Nuevo modelo
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid container justifyContent="space-between">
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                type="submit"
                                className={classes.button}
                            >
                                Guardar
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelIcon />}
                                className={classes.button}
                                onClick={closeForm}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        {dataToUpdate &&
                            <Grid item className={classes.deleteButtonField}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => setOpenDeleteDialog(true)}
                                >
                                    Eliminar
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </form>
            <BackdropLoading open={createVehicleLoading || updateVehicleLoading || deleteVehicleLoading || vehicleBrandsLoading} />
            <ConfirmDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                title="Eliminar vehículo"
                text="¿Está seguro de que quiere eliminar el vehículo?"
                confirmText="Eliminar"
                handleConfirm={handleDeleteButton}
            />
            <CustomModal open={openNewVehicleModel} title="Nuevo modelo">
                <VehicleModelForm refreshData={vehicleModelsRefetch}
                    closeForm={() => setOpenNewVehicleModel(false)}
                    openNotification={openNotification}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
        </>
    );
};

export default VehicleForm;
import { Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, TextField, Grid, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { parse, isMatch } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    filterField: {
        backgroundColor: "white"
    }
}));

const descendingComparator = (a, b, orderBy) => {
    if (typeof b[orderBy] === 'string' && isMatch(b[orderBy], 'dd/MM/yyyy')) {
        const aDate = parse(a[orderBy], 'dd/MM/yyyy', new Date());
        const bDate = parse(b[orderBy], 'dd/MM/yyyy', new Date());
        if (bDate < aDate) {
            return -1;
        } else if (bDate > aDate) {
            return 1;
        }
    }else if (typeof b[orderBy] === 'string') {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
            return -1;
        } else if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
            return 1;
        }
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        } else if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }
    return 0;
};

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const getSearchField = (row, field) => {
    return row[field] + '';
};

const CustomTable = ({ headers, rows, handleRowClick, orderField, orderAsc }) => {
    const classes = useStyles();
    const [order, setOrder] = useState(orderAsc ? 'asc' : 'desc');
    const [orderBy, setOrderBy] = useState(orderField ?? 'id');
    const [filterArray, setFilterArray] = useState([]);

    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const makeFilter = (e, header) => {
        if (e.target.value && e.target.value.length > 0) {
            const index = filterArray.findIndex(row => row.header === header);
            if (index >= 0) {
                const newFilterArray = [...filterArray];
                newFilterArray[index] = {header: header, value: e.target.value};
                setFilterArray(newFilterArray);
            } else {
                const newFilterArray = [...filterArray];
                newFilterArray.push({header: header, value: e.target.value})
                setFilterArray(newFilterArray);
            }
        } else {
            setFilterArray(filterArray.filter(row => row.header !== header));
        }
    };

    const filterRows = (rowsToFilter) => {
        let filteredRows = [...rowsToFilter];
        filterArray.map(filter => {
            return filteredRows = filteredRows.filter(row => getSearchField(row, filter.header).toUpperCase().includes(filter.value.toUpperCase()))
        });
        return filteredRows;
    };

    return (
        <Table aria-label="simple table" size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    {headers.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            align={headCell.numeric ? 'right' : 'left'}
                            width={headCell.width}
                        >
                            <Grid container direction="column">
                                <Grid container direction="row">
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </Grid>
                                <TextField
                                    id={`${headCell.id}-filter`}
                                    variant="outlined"
                                    className={classes.filterField}
                                    size="small"
                                    onChange={(e) => makeFilter(e, headCell.id)}
                                    inputProps={{
                                        style: {
                                            textAlign: headCell.numeric ? 'right' : 'left',
                                            padding: 5,
                                            maxLength: 100
                                        }
                                    }}
                                />
                            </Grid>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {filterRows(stableSort(rows, getComparator(order, orderBy)))
                    .map((row) => {
                        return (
                            <TableRow key={row.id} hover onClick={(event) => handleRowClick(event, row.id)}>
                                {headers.map((headCell, index) => (
                                    <TableCell key={index} align={headCell.numeric ? 'right' : 'left'}>
                                        {headCell.color ? 
                                            <Chip style={{backgroundColor: headCell.color(row[headCell.id])}} label={row[headCell.id]} />
                                            : row[headCell.id]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )
                    })}
            </TableBody>
        </Table>
    );
};

export default CustomTable;

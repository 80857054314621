import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const Notification = ({ open, setOpen, message, severity }) => {

    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={(e) => { setOpen(false) }}>
            <Alert variant="filled" severity={severity}>
                { message }
            </Alert>
        </Snackbar>
    );
}

export default Notification;
import { TextField, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';


const useStyles = makeStyles((theme) => ({
    section: {
        marginBottom: theme.spacing(4)
    },
    descriptionField: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    }
}));


const ExpedientDetailsInfoSection = ({ title, setTitle, description, setDescription, handleHistory }) => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField id="title"
                    label="Título"
                    variant="outlined"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    required
                    inputProps={{
                        maxLength: 50,
                    }}
                    style={{ width: "100%" }}
                    className={classes.field}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField id="description"
                    label="Descripción"
                    value={description || ''}
                    onChange={e => setDescription(e.target.value)}
                    variant="outlined"
                    multiline
                    rows={15}
                    className={classes.descriptionField}
                    inputProps={{
                        maxLength: 5000,
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.stopPropagation();
                        }
                    }}
                />
                <IconButton size="small" aria-label="history" onClick={e => handleHistory('description', 'Descripción')}>
                    <HistoryIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default ExpedientDetailsInfoSection;

import { Drawer, Divider, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import BusinessIcon from '@material-ui/icons/Business';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { Link } from 'react-router-dom';
import { useState } from "react";
import clsx from 'clsx';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden"
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1
    },
    fab: {
        alignItems: "center"
    }
}));

const Menu = ({ closeExpedientDetail }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })
            }}>
            <Toolbar />
            <List>
                <ListItem button key="Dashboard" component={Link} to="/dashboard">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button key="Expedients" onClick={() => {closeExpedientDetail()}} component={Link} to="/">
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Expedientes" />
                </ListItem>
                <ListItem button key="Clients" component={Link} to="/clientes">
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Clientes" />
                </ListItem>
                <ListItem button key="Clinics" component={Link} to="/clinicas">
                    <ListItemIcon>
                        <LocalHospitalIcon />
                    </ListItemIcon>
                    <ListItemText primary="Clínicas" />
                </ListItem>
                <ListItem button key="InsuranceCompanies" component={Link} to="/aseguradoras">
                    <ListItemIcon>
                        <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Aseguradoras" />
                </ListItem>
                <ListItem button key="Vehicles" component={Link} to="/vehiculos">
                    <ListItemIcon>
                        <DirectionsCarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Vehículos" />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button key="Ajustes">
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Ajustes" />
                </ListItem>
            </List>
            <List style={{ marginTop: "auto", overflowX: "hidden" }}>
                <Divider/>
                <ListItem button key="popen" onClick={(e) => setOpen(!open)}>
                    <ListItemIcon>
                        {open ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                    </ListItemIcon>
                    <ListItemText primary="Cerrar" />
                </ListItem>
            </List>
        </Drawer>
    );
}

export default Menu;
import {
    Typography,
    TextField,
    Grid,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    }
}))

const MAX_CLIENTS = 8
const peopleAffectedNumber = [...Array(MAX_CLIENTS).keys()].map((i) => i + 1)

const DEAL = [
    { id: 1, value: 'FRIENDLY', name: 'Parte amistoso' },
    { id: 2, value: 'ATTESTATION', name: 'Atestado' }
]

const MODE = [
    { id: 1, value: 'FACE_TO_FACE', name: 'Presencial' },
    { id: 2, value: 'ONLINE', name: 'Online' }
]

const CONTACT_CHANNEL_LIST = [
    { id: 'PHONE', value: 'Teléfono' },
    { id: 'EMAIL', value: 'Email' },
    { id: 'FACE-TO-FACE', value: 'Presencial' },
    { id: 'FORM', value: 'Formulario web' }
]

const ExpedientDetailsOtherDetailsSection = ({
    affectedPeople,
    setAffectedPeople,
    deal,
    setDeal,
    contact,
    setContact,
    mode,
    setMode,
    incidentReasonId,
    setIncidentReasonId,
    incidentReasons,
    contactChannel,
    setContactChannel
}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Accordion style={{ border: '1px solid #D7D2D1' }}>
                <AccordionSummary
                    style={{ borderBottom: '1px solid #D7D2D1' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="status-content"
                    id="status-header"
                >
                    <Typography variant="h6">Otros detalles</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="affected_people"
                                select
                                label="Personas implicadas"
                                value={affectedPeople}
                                onChange={(e) => {
                                    setAffectedPeople(e.target.value)
                                }}
                                required
                                style={{ width: '100%' }}
                                variant="outlined"
                            >
                                {peopleAffectedNumber.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="deal"
                                select
                                label="Tipo de parte"
                                value={deal}
                                onChange={(e) => {
                                    setDeal(e.target.value)
                                }}
                                style={{ width: '100%' }}
                                variant="outlined"
                            >
                                {DEAL.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="referred_contact"
                                label="Contacto"
                                value={contact || ''}
                                onChange={(e) => {
                                    setContact(e.target.value)
                                }}
                                style={{ width: '100%' }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="mode"
                                select
                                label="Atención al cliente"
                                value={mode}
                                onChange={(e) => {
                                    setMode(e.target.value)
                                }}
                                style={{ width: '100%' }}
                                variant="outlined"
                            >
                                {MODE.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="incident_reason"
                                select
                                label="Motivo del accidente"
                                value={
                                    incidentReasons.length === 0
                                        ? ''
                                        : incidentReasonId || ''
                                }
                                onChange={(e) => {
                                    setIncidentReasonId(e.target.value)
                                }}
                                variant="outlined"
                                style={{ width: '100%' }}
                            >
                                <MenuItem key={'empty'} value={null} />
                                {incidentReasons.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="contact_channel"
                                select
                                label="Canal de contacto"
                                value={contactChannel}
                                onChange={(e) => {
                                    setContactChannel(e.target.value)
                                }}
                                style={{ width: '100%' }}
                                variant="outlined"
                            >
                                {CONTACT_CHANNEL_LIST.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ExpedientDetailsOtherDetailsSection

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'

require('dotenv').config()

const AuthorizedApolloProvider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0()

    const httpLink = createHttpLink({
        uri: process.env.REACT_APP_TRAFILEX_API_URI
    })

    const authLink = setContext(async () => {
        const token = await getAccessTokenSilently()
        return {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    })

    const apolloClient = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
            typePolicies: {
                Expedient: {
                    keyFields: ['id'],
                    fields: {
                        ExpedientOppositeVehicles: {
                            merge(existing, incoming) {
                                return incoming
                            }
                        }
                    }
                },
                Client: {
                    keyFields: ['id']
                },
                Clinic: {
                    keyFields: ['id']
                },
                InsuranceCompany: {
                    keyFields: ['id']
                },
                Vehicle: {
                    keyFields: ['id']
                },
                VehicleModel: {
                    keyFields: ['id']
                },
                VehicleBrand: {
                    keyFields: ['id']
                },
                Status: {
                    keyFields: ['id']
                },
                Employee: {
                    keyFields: ['id']
                },
                ExpedientHistory: {
                    keyFields: [
                        'id',
                        'modification_date',
                        'modification_employee'
                    ]
                },
                Document: {
                    keyFields: ['id']
                }
            }
        })
    })

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider

import { TableContainer, Paper, Toolbar, Typography, Button } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import CustomTable from './CustomTable';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        width: '100%'
    },
    table: {
        width: '100%',
        maxHeight: props => props.maxHeight ?? '80vh'
    },
    title: {
        flex: '1 1 100%',
    },
    button: {
        marginRight: theme.spacing(2),
        width: "30%"
    }
}));

const CustomTableContainer = ({ title, headers, rows, handleRowClick, handleNew, refresh, maxHeight, orderField, orderAsc }) => {
    const classes = useStyles({ maxHeight });
    return (
        <div className={classes.root}>
            <Paper>
                <Toolbar>
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {title}
                    </Typography>
                    <Button
                        color="primary"
                        startIcon={<RefreshIcon />}
                        className={classes.button}
                        onClick={() => refresh()}
                    >
                        Refrescar
                    </Button>
                    <Button
                        color="primary"
                        startIcon={<AddBoxIcon />}
                        className={classes.button}
                        onClick={handleNew}
                    >
                        Nuevo
                    </Button>
                </Toolbar>
                <TableContainer className={classes.table}>
                    <CustomTable headers={headers} rows={rows} handleRowClick={handleRowClick} orderField={orderField} orderAsc={orderAsc} />
                </TableContainer>
            </Paper>
        </div>
    );
};

export default CustomTableContainer;

import { Paper, TableContainer, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { compareAsc, addYears, format, addMonths } from 'date-fns';
import CustomTable from '../common/CustomTable';

const PRESCRIBE_EXPEDIENTS_HEADERS = [
    { id: 'id', label: 'ID', numeric: true, width: "5%" },
    { id: 'title', label: 'Título', width:"70%" },
    { id: 'prescribe_date', label: 'F. prescripción', width:"25%" }
];

const calculateNextMonthPrescribes = (expedient) => {
    const prescribeDate = addYears(expedient.incident_date, 1);
    return compareAsc(Date.now(), prescribeDate) <= 0 && compareAsc(addMonths(Date.now(), 1), prescribeDate) >= 0;
};

const getExpedientsToPrescribe = (expedients) => expedients.filter(expedient => calculateNextMonthPrescribes(expedient))
    .map(expedient => {return {...expedient, "prescribe_date": format(addYears(expedient.incident_date, 1), "dd/MM/yyyy")}});


const ExpedientToPrescribe = ({ expedients }) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(getExpedientsToPrescribe(expedients));
    }, [expedients]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <Paper style={{padding: 15, height: "100%"}}>
            <Typography variant="h5">Expedientes a prescribir</Typography>
            <TableContainer style={{ maxHeight: 300 }}>
                <CustomTable headers={PRESCRIBE_EXPEDIENTS_HEADERS} rows={rows} handleRowClick={() => {}} orderField="prescribe_date" orderAsc={true}/>
            </TableContainer>
        </Paper>
    );
}

export default ExpedientToPrescribe;
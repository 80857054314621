import { makeStyles } from '@material-ui/core/styles';
import { Modal, Paper, Typography, Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
    modal: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        overflow: "scroll"
    },
    root: {
        width: 1100,
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        overflow: 'auto',
        maxHeight: "90%"
    }
}));

const CustomModal = ({ children, open, title, enableClose, setOpen }) => {
    const classes = useStyles();

    return (
        <Modal className={classes.modal} open={open}>
            <Paper className={classes.root}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">{title}</Typography>
                    </Grid>
                    {enableClose &&
                        <Grid item>
                            <IconButton aria-label="Acciones" onClick={(e) => setOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    }   
                </Grid>
                {children}
            </Paper>
        </Modal>
    );
}

export default CustomModal;
import { useState, useEffect, useCallback, useContext } from 'react'
import { Paper, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation, gql, useQuery, NetworkStatus } from '@apollo/client'
import Notification from '../common/Notification'
import BackdropLoading from '../common/BackdropLoading'
import ExpedientDetailsHeader from './detail/ExpedientDetailsHeader'
import ExpedientDetailsInfoSection from './detail/ExpedientDetailsInfoSection'
import ExpedientDetailsLocationSection from './detail/ExpedientDetailsLocationSection'
import ExpedientDetailsClientSection from './detail/ExpedientDetailsClientSection'
import ExpedientDetailsStatusSection from './detail/ExpedientDetailsStatusSection'
import ExpedientDetailsVehicleSection from './detail/ExpedientDetailsVehicleSection'
import ExpedientDetailsCompensationSection from './detail/ExpedientDetailsCompensationSection'
import ExpedientDetailsOtherDetailsSection from './detail/ExpedientDetailsOtherDetailsSection'
import ExpedientHistoryTable from './history/ExpedientHistoryTable'
import CustomModal from '../common/CustomModal'
import EmployeeContext from '../../contexts/EmployeeContext'
import Error from '../common/Error'

const EXPEDIENT_QUERY = gql`
    query expedient($id: Int!) {
        expedient(id: $id) {
            id
            created_at
            created_by {
                id
                first_name
                last_name
                email
            }
            updated_at
            updated_by {
                id
                first_name
                last_name
                email
            }
            title
            description
            incident_date
            affected_people
            estimated_amount
            account_payment
            final_amount
            insurance_offer
            referred_contact
            deal
            address
            postal_code
            clients {
                client {
                    id
                    first_name
                    last_name
                    dni
                    email
                    genre
                }
                vehicle {
                    id
                    plate
                    model {
                        id
                        name
                        type
                        brand {
                            id
                            name
                        }
                    }
                }
                insurance_company {
                    id
                    name
                }
                clinic {
                    id
                    name
                }
                start_treatment_date
            }
            opposite_vehicles {
                vehicle {
                    id
                    plate
                    model {
                        id
                        name
                        type
                        brand {
                            id
                            name
                        }
                    }
                }
                insurance_company {
                    id
                    name
                }
            }
            employee {
                id
                first_name
                last_name
                email
            }
            status {
                id
                name
                description
                type
            }
            city {
                id
                name
                province {
                    id
                    name
                }
            }
            reject_reason {
                id
                name
                description
            }
            mode
            incident_reason {
                id
                name
            }
            partner {
                id
                name
            }
            contact_channel
        }
    }
`

const UPDATE_EXPEDIENT = gql`
    mutation updateExpedient($id: Int!, $expedient: ExpedientInput!) {
        updateExpedient(id: $id, expedient: $expedient) {
            id
            updated_at
            updated_by {
                id
                first_name
                last_name
                email
            }
        }
    }
`

const DELETE_EXPEDIENT = gql`
    mutation deleteExpedient($id: Int!) {
        deleteExpedient(id: $id)
    }
`

const PROVINCES_QUERY = gql`
    query provincesByCountry($country_id: Int!) {
        provincesByCountry(country_id: $country_id) {
            id
            name
        }
    }
`

const CITIES_QUERY = gql`
    query citiesByProvince($province_id: Int!) {
        citiesByProvince(province_id: $province_id) {
            id
            name
        }
    }
`

const CLIENTS_QUERY = gql`
    query clients {
        clients {
            id
            first_name
            last_name
            dni
            email
        }
    }
`

const INSURANCE_COMPANIES_QUERY = gql`
    query insurance_companies {
        insurance_companies {
            id
            name
        }
    }
`

const CLINICS_QUERY = gql`
    query clinics {
        clinics {
            id
            name
        }
    }
`

const EMPLOYEES_QUERY = gql`
    query employees {
        employees {
            id
            first_name
            last_name
        }
    }
`

const STATUS_QUERY = gql`
    query status($id: Int!) {
        status(id: $id) {
            id
            name
            description
            type
            next_status {
                id
                name
                description
                type
                next_description
                actions {
                    id
                }
            }
            previous_status {
                id
                name
                description
                type
                next_description
                actions {
                    id
                }
            }
        }
    }
`

const INCIDENT_REASONS_QUERY = gql`
    query incident_reasons {
        incident_reasons {
            id
            name
        }
    }
`
const HISTORY_QUERY = gql`
    query expedientHistory($id: Int!) {
        expedient_history(id: $id) {
            id
            modification_date
            modification_employee {
                id
                first_name
                last_name
            }
            description
        }
    }
`

const COUNTRY_ID = 1
const MAX_CLIENTS = 8
const MAX_VEHICLES = 8

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(3),
        marginTop: theme.spacing(1)
    },
    mainPanel: {
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(2),
            borderRight: '1px solid #D7D2D1'
        }
    },
    rightPanel: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(2)
        }
    }
}))

const ExpedientDetails = ({ expedientId, refresh, goBack }) => {
    const classes = useStyles()

    const employeeLogged = useContext(EmployeeContext)

    const [expedient, setExpedient] = useState()

    const [createdAt, setCreatedAt] = useState()
    const [createdBy, setCreatedBy] = useState()
    const [updatedAt, setUpdatedAt] = useState()
    const [updatedBy, setUpdatedBy] = useState()
    const [description, setDescription] = useState()
    const [title, setTitle] = useState()
    const [affectedPeople, setAffectedPeople] = useState()
    const [selectedDate, setSelectedDate] = useState()
    const [deal, setDeal] = useState()
    const [contact, setContact] = useState()
    const [postalCode, setPostalCode] = useState()
    const [address, setAddress] = useState()
    const [estimatedAmount, setEstimatedAmount] = useState()
    const [finalAmount, setFinalAmount] = useState()
    const [insuranceOffer, setInsuranceOffer] = useState()
    const [accountPayment, setAccountPayment] = useState()
    const [employee, setEmployee] = useState()
    const [employees, setEmployees] = useState([])
    const [status, setStatus] = useState()
    const [nextStatus, setNextStatus] = useState([])
    const [previousStatus, setPreviousStatus] = useState([])
    const [mode, setMode] = useState()
    const [province, setProvince] = useState()
    const [provinces, setProvinces] = useState([])
    const [city, setCity] = useState()
    const [cities, setCities] = useState([])
    const [clientsSelected, setClientsSelected] = useState()
    const [clients, setClients] = useState([])
    const [clientsNumber, setClientsNumber] = useState()
    const [vehiclesSelected, setVehiclesSelected] = useState()
    const [vehiclesNumber, setVehiclesNumber] = useState()
    const [insuranceCompanies, setInsuranceCompanies] = useState([])
    const [clinics, setClinics] = useState([])
    const [openNotification, setOpenNotification] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationSeverity, setNotificationSeverity] = useState('success')
    const [openLoading, setOpenLoading] = useState(false)
    const [historyField, setHistoryField] = useState('description')
    const [historyTitle, setHistoryTitle] = useState('descripción')
    const [openHistory, setOpenHistory] = useState(false)
    const [historyRows, setHistoryRows] = useState([])
    const [incidentReasonId, setIncidentReasonId] = useState()
    const [incidentReasons, setIncidentReasons] = useState([])
    const [partner, setPartner] = useState()
    const [contactChannel, setContactChannel] = useState()

    const {
        loading: expedientLoading,
        error: expedientError,
        data: expedientData,
        networkStatus
    } = useQuery(EXPEDIENT_QUERY, {
        variables: {
            id: expedientId
        },
        notifyOnNetworkStatusChange: true
    })

    const [
        updateExpedient,
        {
            data: updateExpedientData,
            loading: updateExpedientLoading,
            error: updateExpedientError
        }
    ] = useMutation(UPDATE_EXPEDIENT)

    const [
        deleteExpedient,
        {
            data: deleteExpedientData,
            loading: deleteExpedientLoading,
            error: deleteExpedientError
        }
    ] = useMutation(DELETE_EXPEDIENT)

    const updateExpedientHandler = (e) => {
        e.preventDefault()
        const updatedExpedient = {
            created_at: createdAt,
            created_by: createdBy?.id,
            updated_by: employeeLogged.id,
            title: title,
            description: description,
            incident_date: selectedDate,
            affected_people: affectedPeople,
            clients: clientsSelected.map((client) => {
                return {
                    client_id: client.client.id,
                    vehicle_id: client.vehicle?.id,
                    insurance_company_id: client.insurance_company?.id,
                    clinic_id: client.clinic?.id,
                    start_treatment_date:
                        client.start_treatment_date === null &&
                        expedient.status.id !== status.id &&
                        status.id === 3
                            ? Date.now()
                            : client.start_treatment_date
                }
            }),
            opposite_vehicles: vehiclesSelected.map((vehicle) => {
                return {
                    vehicle_id: vehicle.vehicle.id,
                    insurance_company_id: vehicle?.insurance_company?.id
                }
            }),
            employee_id: employee.id,
            status_id: status.id,
            city_id: city,
            deal: deal !== '' ? deal : null,
            estimated_amount: estimatedAmount,
            referred_contact: contact,
            postal_code: postalCode,
            address: address,
            final_amount: finalAmount,
            insurance_offer: insuranceOffer,
            account_payment: accountPayment,
            mode: mode,
            incident_reason_id:
                incidentReasonId !== '' ? incidentReasonId : null,
            contact_channel: contactChannel
        }
        updateExpedient({
            variables: { id: expedient.id, expedient: updatedExpedient }
        })
    }

    const { data: provincesData, loading: provincesLoading } = useQuery(
        PROVINCES_QUERY,
        { variables: { country_id: COUNTRY_ID } }
    )

    const { data: citiesData, loading: citiesLoading } = useQuery(
        CITIES_QUERY,
        { skip: province === undefined, variables: { province_id: province } }
    )

    const {
        data: clientsData,
        loading: clientsLoading,
        refetch: refetchClientsData,
        networkStatus: clientsNetworkStatus
    } = useQuery(CLIENTS_QUERY, { notifyOnNetworkStatusChange: true })

    const {
        data: insuranceCompanyData,
        loading: insuranceCompanyLoading,
        refetch: refetchInsuranceCompanyData,
        networkStatus: insuranceCompaniesNetworkStatus
    } = useQuery(INSURANCE_COMPANIES_QUERY, {
        notifyOnNetworkStatusChange: true
    })

    const {
        data: clinicsData,
        loading: clinicsLoading,
        refetch: refetchClinicsData,
        networkStatus: clinicsNetworkStatus
    } = useQuery(CLINICS_QUERY, { notifyOnNetworkStatusChange: true })

    const { data: employeesData, loading: employeesLoading } =
        useQuery(EMPLOYEES_QUERY)

    const { data: statusData, loading: statusLoading } = useQuery(
        STATUS_QUERY,
        { skip: !status, variables: { id: status?.id } }
    )

    const {
        data: historyData,
        loading: historyLoading,
        refetch: refetchHistoryData,
        networkStatus: historyNetworkStatus
    } = useQuery(HISTORY_QUERY, {
        skip: !expedient,
        variables: { id: expedient?.id }
    })

    const {
        data: incidentReasonsData,
        loading: incidentReasonsLoading,
        refetch: refetchIncidentReasonsData,
        networkStatus: incidentReasonsNetworkStatus
    } = useQuery(INCIDENT_REASONS_QUERY, { notifyOnNetworkStatusChange: true })

    const processSuccess = useCallback(
        (message) => {
            refresh()
            refetchHistoryData()
            setNotificationMessage(message)
            setNotificationSeverity('success')
            setOpenNotification(true)
        },
        [
            refresh,
            refetchHistoryData,
            setNotificationMessage,
            setNotificationSeverity,
            setOpenNotification
        ]
    )

    const showNotificationError = useCallback(
        (message) => {
            setNotificationMessage(message)
            setNotificationSeverity('error')
            setOpenNotification(true)
        },
        [setNotificationMessage, setNotificationSeverity, setOpenNotification]
    )

    useEffect(() => {
        setOpenLoading(expedientLoading)
        if (
            expedientLoading === false &&
            expedientData &&
            networkStatus === NetworkStatus.ready
        ) {
            setExpedient(expedientData.expedient)
            setCreatedAt(expedientData.expedient.created_at)
            setCreatedBy(expedientData.expedient.created_by)
            setUpdatedAt(expedientData.expedient.updated_at)
            setUpdatedBy(expedientData.expedient.updated_by)
            setDescription(expedientData.expedient.description)
            setTitle(expedientData.expedient.title)
            setEmployee(expedientData.expedient.employee)
            setAffectedPeople(expedientData.expedient.affected_people)
            setSelectedDate(expedientData.expedient.incident_date)
            setDeal(expedientData.expedient.deal)
            setContact(expedientData.expedient.referred_contact)
            setPostalCode(expedientData.expedient.postal_code)
            setAddress(expedientData.expedient.address)
            setEstimatedAmount(expedientData.expedient.estimated_amount)
            setFinalAmount(expedientData.expedient.final_amount)
            setInsuranceOffer(expedientData.expedient.insurance_offer)
            setAccountPayment(expedientData.expedient.account_payment)
            setMode(expedientData.expedient.mode)
            setIncidentReasonId(
                expedientData.expedient.incident_reason?.id ?? ''
            )
            setClientsSelected(
                expedientData.expedient.clients.map((client) => {
                    return {
                        client: client.client,
                        vehicle: client.vehicle,
                        insurance_company: client.insurance_company,
                        clinic: client.clinic,
                        start_treatment_date: client.start_treatment_date
                    }
                })
            )
            setVehiclesSelected(
                expedientData.expedient.opposite_vehicles.map((vehicle) => {
                    return {
                        vehicle: vehicle.vehicle,
                        insurance_company: vehicle.insurance_company
                    }
                })
            )
            setCity(expedientData.expedient.city?.id)
            setProvince(expedientData.expedient.city?.province.id)
            setStatus(expedientData.expedient.status)
            setClientsNumber(expedientData.expedient.clients.length)
            setVehiclesNumber(
                expedientData.expedient.opposite_vehicles?.length ?? 0
            )
            setPartner(expedientData.expedient.partner)
            setContactChannel(expedientData.expedient.contact_channel)
        } else if (expedientError) {
            setNotificationMessage(
                'Error al cargar los detalles del expediente'
            )
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [expedientError, expedientLoading, expedientData, networkStatus])

    useEffect(() => {
        setOpenLoading(updateExpedientLoading)
        if (updateExpedientLoading === false && updateExpedientData) {
            setUpdatedAt(updateExpedientData.updateExpedient.updated_at)
            setUpdatedBy(updateExpedientData.updateExpedient.updated_by)
            processSuccess(
                `Expediente ${updateExpedientData.updateExpedient.id} actualizado`
            )
        } else if (updateExpedientError) {
            showNotificationError(updateExpedientError.message)
        }
    }, [
        updateExpedientLoading,
        updateExpedientData,
        updateExpedientError,
        processSuccess,
        showNotificationError
    ])

    useEffect(() => {
        setOpenLoading(provincesLoading)
        if (provincesLoading === false && provincesData) {
            setProvinces(provincesData.provincesByCountry)
        }
    }, [provincesLoading, provincesData])

    useEffect(() => {
        setOpenLoading(citiesLoading)
        if (citiesLoading === false && citiesData) {
            setCities(citiesData.citiesByProvince)
        }
    }, [citiesLoading, citiesData])

    useEffect(() => {
        setOpenLoading(clientsLoading)
        if (
            clientsLoading === false &&
            clientsData &&
            clientsNetworkStatus === NetworkStatus.ready
        ) {
            setClients(clientsData.clients)
        }
    }, [clientsLoading, clientsData, clientsNetworkStatus])

    useEffect(() => {
        setOpenLoading(insuranceCompanyLoading)
        if (
            insuranceCompanyLoading === false &&
            insuranceCompanyData &&
            insuranceCompaniesNetworkStatus === NetworkStatus.ready
        ) {
            setInsuranceCompanies(insuranceCompanyData.insurance_companies)
        }
    }, [
        insuranceCompanyLoading,
        insuranceCompanyData,
        insuranceCompaniesNetworkStatus
    ])

    useEffect(() => {
        setOpenLoading(clinicsLoading)
        if (
            clinicsLoading === false &&
            clinicsData &&
            clinicsNetworkStatus === NetworkStatus.ready
        ) {
            setClinics(clinicsData.clinics)
        }
    }, [clinicsLoading, clinicsData, clinicsNetworkStatus])

    useEffect(() => {
        setOpenLoading(employeesLoading)
        if (employeesLoading === false && employeesData) {
            setEmployees(employeesData.employees)
        }
    }, [employeesLoading, employeesData])

    useEffect(() => {
        setOpenLoading(statusLoading)
        if (statusLoading === false && statusData) {
            setNextStatus(statusData.status?.next_status ?? [])
            setPreviousStatus(statusData.status?.previous_status ?? [])
        }
    }, [statusLoading, statusData])

    useEffect(() => {
        setOpenLoading(deleteExpedientLoading)
        if (deleteExpedientLoading === false && deleteExpedientData) {
            if (deleteExpedientData.deleteExpedient) {
                processSuccess(
                    `Expediente ${deleteExpedientData.deleteExpedient} eliminado`
                )
                goBack()
            } else {
                showNotificationError('Error eliminando el expediente')
            }
        } else if (deleteExpedientError) {
            showNotificationError(deleteExpedientError.message)
        }
    }, [
        deleteExpedientData,
        deleteExpedientLoading,
        deleteExpedientError,
        processSuccess,
        showNotificationError,
        goBack
    ])

    useEffect(() => {
        setOpenLoading(historyLoading)
        if (
            historyLoading === false &&
            historyData &&
            historyNetworkStatus === NetworkStatus.ready
        ) {
            setHistoryRows(historyData.expedient_history)
        }
    }, [historyLoading, historyData, historyNetworkStatus])

    useEffect(() => {
        if (
            incidentReasonsLoading === false &&
            incidentReasonsData &&
            incidentReasonsNetworkStatus === NetworkStatus.ready
        ) {
            setIncidentReasons(incidentReasonsData.incident_reasons)
        }
    }, [
        incidentReasonsLoading,
        incidentReasonsData,
        incidentReasonsNetworkStatus
    ])

    //cleanup
    useEffect(() => {
        return () => {}
    }, [])

    const addClientToClientsSelected = (client, index) => {
        const newClientsSelected = clientsSelected.slice()
        newClientsSelected[index] = {
            ...newClientsSelected[index],
            client: client
        }
        setClientsSelected(newClientsSelected)
    }

    const addVehicleToClientsSelected = (vehicle, index) => {
        const newClientsSelected = clientsSelected.slice()
        newClientsSelected[index] = {
            ...newClientsSelected[index],
            vehicle: vehicle
        }
        setClientsSelected(newClientsSelected)
    }

    const addInsuranceCompanyToClientsSelected = (insuranceCompany, index) => {
        const newClientsSelected = clientsSelected.slice()
        newClientsSelected[index] = {
            ...newClientsSelected[index],
            insurance_company: insuranceCompany
        }
        setClientsSelected(newClientsSelected)
    }

    const addClinicToClientsSelected = (clinic, index) => {
        const newClientsSelected = clientsSelected.slice()
        newClientsSelected[index] = {
            ...newClientsSelected[index],
            clinic: clinic
        }
        setClientsSelected(newClientsSelected)
    }

    const addStartTreatmentDateToClientsSelected = (
        startTreatmentDate,
        index
    ) => {
        const newClientsSelected = clientsSelected.slice()
        newClientsSelected[index] = {
            ...newClientsSelected[index],
            start_treatment_date: startTreatmentDate
        }
        setClientsSelected(newClientsSelected)
    }

    const removeClient = () => {
        if (clientsNumber > 1) {
            if (clientsSelected.length === clientsNumber) {
                const newClientsSelected = clientsSelected.slice()
                newClientsSelected.pop()
                setClientsSelected(newClientsSelected)
            }
            setClientsNumber(clientsNumber - 1)
        }
    }

    const addClient = () => {
        if (clientsNumber < MAX_CLIENTS) {
            setClientsNumber(clientsNumber + 1)
        }
    }

    const addVehicleToVehiclesSelected = (vehicle, index) => {
        const newVehiclesSelected = vehiclesSelected.slice()
        newVehiclesSelected[index] = {
            ...newVehiclesSelected[index],
            vehicle: vehicle
        }
        setVehiclesSelected(newVehiclesSelected)
    }

    const addInsuranceCompanyToVehiclesSelected = (insuranceCompany, index) => {
        const newVehiclesSelected = vehiclesSelected.slice()
        newVehiclesSelected[index] = {
            ...newVehiclesSelected[index],
            insurance_company: insuranceCompany
        }
        setVehiclesSelected(newVehiclesSelected)
    }

    const removeVehicle = () => {
        if (vehiclesNumber >= 1) {
            if (vehiclesSelected.length === vehiclesNumber) {
                const newVehiclesSelected = vehiclesSelected.slice()
                newVehiclesSelected.pop()
                setVehiclesSelected(newVehiclesSelected)
            }
            setVehiclesNumber(vehiclesNumber - 1)
        }
    }

    const addVehicle = () => {
        if (vehiclesNumber < MAX_VEHICLES) {
            setVehiclesNumber(vehiclesNumber + 1)
        }
    }

    const changeProvince = (e) => {
        setCity('')
        setProvince(e.target.value)
    }

    const handleDeleteButton = () => {
        deleteExpedient({ variables: { id: expedient.id } }).catch((err) => {
            showNotificationError(err)
        })
    }

    const handleHistory = (field, fieldName) => {
        setHistoryTitle(fieldName)
        setHistoryField(field)
        setOpenHistory(true)
    }

    const isClientEmailDuplicated = (email, id) => {
        return (
            clients.filter(
                (client) =>
                    client.email &&
                    client.email.toUpperCase() === email.toUpperCase() &&
                    client.id !== id
            ).length > 0
        )
    }

    if (expedientError) return <Error message={expedientError.message} />
    if (expedient === undefined) return <BackdropLoading open={true} />

    return (
        <>
            <Paper className={classes.root}>
                <form
                    onSubmit={updateExpedientHandler}
                    onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                >
                    <Grid container direction="column">
                        <ExpedientDetailsHeader
                            expedient={expedient}
                            goBack={goBack}
                            setOpenNotification={setOpenNotification}
                            setNotificationMessage={setNotificationMessage}
                            setNotificationSeverity={setNotificationSeverity}
                            clientsSelected={clientsSelected}
                            refetchClientsData={refetchClientsData}
                            refetchInsuranceCompanyData={
                                refetchInsuranceCompanyData
                            }
                            refetchClinicsData={refetchClinicsData}
                            handleDeleteButton={handleDeleteButton}
                            isEmailDuplicated={isClientEmailDuplicated}
                            refetchIncidentReasonsData={
                                refetchIncidentReasonsData
                            }
                        />
                        <Grid container direction="row">
                            <Grid
                                container
                                item
                                direction="column"
                                xs={12}
                                md={9}
                                className={classes.mainPanel}
                            >
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        maxWidth: '100%'
                                    }}
                                >
                                    <ExpedientDetailsInfoSection
                                        title={title}
                                        setTitle={setTitle}
                                        description={description}
                                        setDescription={setDescription}
                                        handleHistory={handleHistory}
                                    />
                                    <ExpedientDetailsLocationSection
                                        provinces={provinces}
                                        province={province}
                                        changeProvince={changeProvince}
                                        cities={cities}
                                        city={city}
                                        setCity={setCity}
                                        address={address}
                                        setAddress={setAddress}
                                        postalCode={postalCode}
                                        setPostalCode={setPostalCode}
                                        statusId={status.id}
                                    />
                                    <ExpedientDetailsClientSection
                                        clientsNumber={clientsNumber}
                                        clients={clients}
                                        clientsSelected={clientsSelected}
                                        addClientToClientsSelected={
                                            addClientToClientsSelected
                                        }
                                        addVehicleToClientsSelected={
                                            addVehicleToClientsSelected
                                        }
                                        showNotificationError={
                                            showNotificationError
                                        }
                                        setOpenLoading={setOpenLoading}
                                        insuranceCompanies={insuranceCompanies}
                                        addInsuranceCompanyToClientsSelected={
                                            addInsuranceCompanyToClientsSelected
                                        }
                                        clinics={clinics}
                                        addClinicToClientsSelected={
                                            addClinicToClientsSelected
                                        }
                                        addClient={addClient}
                                        removeClient={removeClient}
                                        addStartTreatmentDateToClientsSelected={
                                            addStartTreatmentDateToClientsSelected
                                        }
                                    />
                                    <ExpedientDetailsVehicleSection
                                        vehiclesNumber={vehiclesNumber}
                                        vehiclesSelected={vehiclesSelected}
                                        addVehicleToVehiclesSelected={
                                            addVehicleToVehiclesSelected
                                        }
                                        showNotificationError={
                                            showNotificationError
                                        }
                                        setOpenLoading={setOpenLoading}
                                        insuranceCompanies={insuranceCompanies}
                                        addInsuranceCompanyToVehiclesSelected={
                                            addInsuranceCompanyToVehiclesSelected
                                        }
                                        addVehicle={addVehicle}
                                        removeVehicle={removeVehicle}
                                    />
                                    <ExpedientDetailsCompensationSection
                                        estimatedAmount={estimatedAmount}
                                        setEstimatedAmount={setEstimatedAmount}
                                        insuranceOffer={insuranceOffer}
                                        setInsuranceOffer={setInsuranceOffer}
                                        accountPayment={accountPayment}
                                        setAccountPayment={setAccountPayment}
                                        finalAmount={finalAmount}
                                        setFinalAmount={setFinalAmount}
                                    />
                                </Box>
                            </Grid>
                            <Grid
                                container
                                item
                                direction="column"
                                xs={12}
                                md={3}
                                className={classes.rightPanel}
                            >
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        maxWidth: '100%'
                                    }}
                                >
                                    <ExpedientDetailsStatusSection
                                        expedient_id={expedient.id}
                                        status={status}
                                        setStatus={setStatus}
                                        nextStatus={nextStatus}
                                        previousStatus={previousStatus}
                                        employee={employee}
                                        setEmployee={setEmployee}
                                        employees={employees}
                                        createdAt={createdAt}
                                        setCreatedAt={setCreatedAt}
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                        createdBy={createdBy}
                                        updatedAt={updatedAt}
                                        updatedBy={updatedBy}
                                        partner={partner}
                                        setUpdatedAt={setUpdatedAt}
                                        setUpdatedBy={setUpdatedBy}
                                        setOpenLoading={setOpenLoading}
                                    />
                                    <ExpedientDetailsOtherDetailsSection
                                        affectedPeople={affectedPeople}
                                        setAffectedPeople={setAffectedPeople}
                                        deal={deal}
                                        setDeal={setDeal}
                                        contact={contact}
                                        setContact={setContact}
                                        mode={mode}
                                        setMode={setMode}
                                        incidentReasonId={incidentReasonId}
                                        setIncidentReasonId={
                                            setIncidentReasonId
                                        }
                                        incidentReasons={incidentReasons}
                                        contactChannel={contactChannel}
                                        setContactChannel={setContactChannel}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <BackdropLoading open={openLoading} />
            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
            <CustomModal
                open={openHistory}
                title={`Historial ${historyTitle}`}
                enableClose={true}
                setOpen={setOpenHistory}
            >
                <ExpedientHistoryTable
                    historyRows={historyRows}
                    field={historyField}
                    fieldName={historyTitle}
                />
            </CustomModal>
        </>
    )
}

export default ExpedientDetails

import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts'
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    TextField,
    Typography
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import {
    format,
    getDay,
    getMonth,
    getYear,
    isThisMonth,
    isThisWeek
} from 'date-fns'
import RefreshIcon from '@material-ui/icons/Refresh'

const STATUS_REJECTED = 8
const STATUS_OPPORTUNITY = 0
const OPPORTUNITY_STATUS_LIST = [STATUS_OPPORTUNITY, STATUS_REJECTED]

const options = [
    { id: 1, label: 'Esta semana' },
    { id: 2, label: 'Este mes' },
    { id: 3, label: 'Por año' }
]

const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado'
]
const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
]

const transformToMonth = (expedients) => {
    let data = []
    expedients.forEach((expedient) => {
        const createdDate = format(expedient.created_at, 'dd/MM/yyyy')
        const createdDateDefault = format(expedient.created_at, 'MM/dd/yyyy')
        const index = data.findIndex((elem) => elem.label === createdDate)
        const isOpportunity = OPPORTUNITY_STATUS_LIST.includes(
            expedient.status.id
        )
        if (index >= 0) {
            if (!isOpportunity) {
                data[index].value++
                data[index].clients += expedient.clients.length
            }
            data[index].opportunities++
        } else {
            if (isOpportunity) {
                data.push({
                    label: createdDate,
                    value: 0,
                    clients: 0,
                    opportunities: 1,
                    createdDateDefault
                })
            } else {
                data.push({
                    label: createdDate,
                    value: 1,
                    clients: expedient.clients.length,
                    opportunities: 1,
                    date: createdDateDefault
                })
            }
        }
    })
    return data.sort((a, b) => new Date(a.date) - new Date(b.date))
}

const transformToWeek = (expedients) => {
    let data = []
    expedients.forEach((expedient) => {
        const dayInWeek = getDay(expedient.created_at)
        const day = days[dayInWeek]
        const order = dayInWeek === 0 ? 7 : dayInWeek
        const index = data.findIndex((elem) => elem.label === day)
        const isOpportunity = OPPORTUNITY_STATUS_LIST.includes(
            expedient.status.id
        )
        if (index >= 0) {
            if (!isOpportunity) {
                data[index].value++
                data[index].clients += expedient.clients.length
            }
            data[index].opportunities++
        } else {
            if (isOpportunity) {
                data.push({
                    label: day,
                    value: 0,
                    clients: 0,
                    opportunities: 1,
                    order
                })
            } else {
                data.push({
                    label: day,
                    value: 1,
                    clients: expedient.clients.length,
                    opportunities: 1,
                    order
                })
            }
        }
    })

    return data.sort((a, b) => a.order - b.order)
}

const transformToYear = (expedients) => {
    let data = []
    expedients.forEach((expedient) => {
        const monthInYear = getMonth(expedient.created_at)
        const month = months[monthInYear]
        const index = data.findIndex((elem) => elem.label === month)
        const isOpportunity = OPPORTUNITY_STATUS_LIST.includes(
            expedient.status.id
        )
        if (index >= 0) {
            if (!isOpportunity) {
                data[index].value++
                data[index].clients += expedient.clients.length
            }
            data[index].opportunities++
        } else {
            if (isOpportunity) {
                data.push({
                    label: month,
                    value: 0,
                    clients: 0,
                    opportunities: 1,
                    monthInYear
                })
            } else {
                data.push({
                    label: month,
                    value: 1,
                    clients: expedient.clients.length,
                    opportunities: 1,
                    order: monthInYear
                })
            }
        }
    })
    return data.sort((a, b) => a.order - b.order)
}

const transformData = (expedients, option, yearActive) => {
    switch (option) {
        case 1:
            const thisWeekExpedients = expedients.filter((expedient) => {
                return isThisWeek(expedient.created_at, { weekStartsOn: 1 })
            })
            return transformToWeek(thisWeekExpedients)
        case 3:
            const thisYearExpedients = expedients.filter((expedient) => {
                return (
                    yearActive === new Date(expedient.created_at).getFullYear()
                )
            })

            return transformToYear(thisYearExpedients)
        default:
            const thisMonthExpedients = expedients.filter((expedient) => {
                return isThisMonth(expedient.created_at)
            })
            return transformToMonth(thisMonthExpedients)
    }
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const suffixExpedients =
            payload[0].value > 1 ? 'nuevos expedientes' : 'nuevo expediente'
        const suffixClients =
            payload[2].value === 1 ? 'nuevo cliente' : 'nuevos clientes'
        const suffixOpportunities =
            payload[4].value > 1 ? 'nuevas oportunidades' : 'nueva oportunidad'
        return (
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h6">{label}</Typography>
                    <Typography variant="body1">{`${payload[0].value} ${suffixExpedients}`}</Typography>
                    <Typography variant="body1">{`${payload[2].value} ${suffixClients}`}</Typography>
                    <Typography variant="body1">{`${payload[4].value} ${suffixOpportunities}`}</Typography>
                </CardContent>
            </Card>
        )
    }

    return null
}

const NewExpedientsBarChart = ({ expedients, refresh }) => {
    const [option, setOption] = useState(2)
    const [data, setData] = useState([])
    const [yearsWithExpedients, setYearsWithExpedients] = useState([])
    const [yearActive, setYearActive] = useState(getYear(Date.now()))

    const getYearsWithExpedients = (expedients) => {
        let years = expedients.map((exp) => getYear(exp.created_at))
        return [...new Set(years)]
    }

    useEffect(() => {
        let years = getYearsWithExpedients(expedients).sort((a, b) => b - a)
        setYearsWithExpedients(years)
        if (option === 3) {
            setYearActive(years[0])
        }
    }, [expedients, option])

    useEffect(() => {
        setData(transformData(expedients, option, yearActive))
    }, [expedients, option, yearActive])

    //cleanup
    useEffect(() => {
        return () => {}
    }, [])

    const onChange = (e) => {
        setOption(e.target.value)
    }
    const onChangeYear = (e) => {
        setYearActive(e.target.value)
    }

    return (
        <Paper style={{ padding: 15 }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5" align="center">
                        Nuevos expedientes
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        id="option"
                        select
                        value={option}
                        onChange={onChange}
                        variant="outlined"
                    >
                        {options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {option === 3 && (
                        <TextField
                            id="years"
                            select
                            value={yearActive}
                            onChange={onChangeYear}
                            variant="outlined"
                            style={{ marginLeft: 8 }}
                        >
                            {yearsWithExpedients.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    <IconButton
                        color="primary"
                        onClick={() => refresh()}
                        style={{ marginLeft: 8 }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="value" fill="#c30d5b" barSize={15} />
                    <Line type="monotone" dataKey="value" stroke="#c30d5b" />
                    <Bar dataKey="clients" fill="#2A50E8" barSize={15} />
                    <Line type="monotone" dataKey="clients" stroke="#2A50E8" />
                    <Bar dataKey="opportunities" fill="#FFCA1B" barSize={15} />
                    <Line
                        type="monotone"
                        dataKey="opportunities"
                        stroke="#FFCA1B"
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </Paper>
    )
}

export default NewExpedientsBarChart

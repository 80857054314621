import Loading from '../common/Loading';
import { useState, useEffect } from 'react';
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import Error from '../common/Error';
import ClinicForm from './ClinicForm';
import CustomTableContainer from '../common/CustomTableContainer';
import CustomModal from '../common/CustomModal';
import Notification from '../common/Notification';

const CLINICS_QUERY = gql`
    query clinics {
        clinics {
            id
            name
            email
            phone
            phone2
            city {
                id
                name
                province {
                    id
                    name
                }
            }
            address1
            address2
            clients {
                expedient {
                    id
                }
                client {
                    id
                    first_name
                    last_name
                }
                start_treatment_date
            }
        }
    }
`;

const clientAmountColors = amount => amount > 0 ? '#92de9a' : '#ff9a9a'

const headers = [
    { id: 'id', label: 'ID', numeric: true, width: '5%'},
    { id: 'name', label: 'Nombre', width: '15%' },
    { id: 'email', label: 'Email', width: '20%' },
    { id: 'phone', label: 'Teléfono', width: '10%' },
    { id: 'city', label: 'Ciudad', width: '10%' },
    { id: 'province', label: 'Provincia', width: '15%'},
    { id: 'clients_amount_last_6_months', label: 'Clientes últ. 6 meses', width: '10%', color: clientAmountColors },
    { id: 'last_treatment_date', label: 'Fecha último cliente', width: '20%' }
];

const Clinics = () => {

    const [clinics, setClinics] = useState([]);
    const { data: clinicsData, loading: clinicsLoading, error: clinicsError, refetch: refetchClinicsData, networkStatus } =
        useQuery(CLINICS_QUERY, { notifyOnNetworkStatusChange: true });
    const [openNewClinic, setOpenNewClinic] = useState(false);
    const [openUpdateClinic, setOpenUpdateClinic] = useState(false);
    const [clinicToUpdate, setClinicToUpdate] = useState({});
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('success');

    const formatClinic = (clinic) => {
        return {
            ...clinic,
            city_id: clinic.city ? clinic.city.id : null,
            city: clinic.city ? clinic.city.name : null,
            province_id: clinic.city ? clinic.city.province.id : null,
            province: clinic.city ? clinic.city.province.name : null,
            clients_amount_last_6_months: clinic.clients.filter(client => {
                const sixMonthsAgo = new Date();
                sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
                const clientStartTreatmentDate = new Date(client.start_treatment_date);
                return clientStartTreatmentDate >= sixMonthsAgo;
            }).length,
            last_treatment_date: clinic.clients.filter(client => client.start_treatment_date)
                .map(client => new Date(client.start_treatment_date))
                .sort((a, b) => b - a)[0]?.toLocaleDateString(),
        }
    };

    useEffect(() => {
        if (clinicsLoading === false && clinicsData && networkStatus === NetworkStatus.ready) {
            setClinics(clinicsData.clinics.map((clinic) => {
                return formatClinic(clinic);
            }));
        }
    }, [clinicsLoading, clinicsData, networkStatus]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    const handleRowClick = (event, id) => {
        setClinicToUpdate(clinics.filter(clinic => clinic.id === id)[0]);
        setOpenUpdateClinic(true);
    };


    if (clinicsError) {
        return <Error error={clinicsError.message} />;
    } else if (!clinics) {
        return <Loading />;
    }

    return (
        <>
            <CustomTableContainer
                title="Clínicas"
                headers={headers}
                rows={clinics}
                handleRowClick={handleRowClick}
                handleNew={() => setOpenNewClinic(true)}
                refresh={refetchClinicsData}
                orderField='clients_amount_last_6_months'
                orderAsc={false}
            />
            <CustomModal open={openNewClinic} title="Nueva clínica">
                <ClinicForm refreshData={refetchClinicsData}
                    closeForm={() => setOpenNewClinic(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <CustomModal open={openUpdateClinic} title={`Editar clínica ${clinicToUpdate.id}`}>
                <ClinicForm refreshData={refetchClinicsData}
                    closeForm={() => setOpenUpdateClinic(false)}
                    dataToUpdate={clinicToUpdate}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
        </>
    );
}

export default Clinics;

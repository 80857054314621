import { Grid, TextField, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CreatableAutocomplete from '../common/CreatableAutocomplete'
import { useState, useEffect, useCallback } from 'react'
import ConfirmDialog from '../common/ConfirmDialog'
import { useQuery, gql, useMutation, NetworkStatus } from '@apollo/client'
import WarningDialog from '../common/WarningDialog'
import Notification from '../common/Notification'
import BackdropLoading from '../common/BackdropLoading'

const VEHICLES_QUERY = gql`
  query vehicles {
    vehicles {
      id
      model {
        id
        name
        brand {
          id
          name
        }
        type
      }
      plate
    }
  }
`

const VEHICLE_BRANDS_QUERY = gql`
  query vehicleBrands {
    vehicleBrands {
      id
      name
    }
  }
`

const VEHICLE_MODELS_QUERY = gql`
  query vehicleModels {
    vehicleModels {
      id
      name
      brand {
        id
        name
      }
      type
    }
  }
`

const CREATE_VEHICLE = gql`
  mutation createVehicle($vehicle: VehicleInput!) {
    createVehicle(vehicle: $vehicle) {
      id
      model {
        id
        name
        brand {
          id
          name
        }
        type
      }
      plate
    }
  }
`

const CREATE_BRAND = gql`
  mutation createVehicleBrand($vehicleBrand: VehicleBrandInput!) {
    createVehicleBrand(vehicle_brand: $vehicleBrand) {
      id
      name
    }
  }
`

const CREATE_MODEL = gql`
  mutation createVehicleModel($vehicleModel: VehicleModelInput!) {
    createVehicleModel(vehicle_model: $vehicleModel) {
      id
      name
      brand {
        id
        name
      }
      type
    }
  }
`

const VEHICLE_TYPES = [
  { id: 1, value: 'CAR', name: 'Coche' },
  { id: 2, value: 'MOTORBIKE', name: 'Motocicleta' },
  { id: 3, value: 'BIKE', name: 'Bicicleta' },
  { id: 4, value: 'TRUCK', name: 'Camión' },
]

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(2),
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}))

const FastVehicleForm = ({
  index,
  addVehicle,
  vehicleIn,
  brandIn,
  modelIn,
  showNotificationError,
  plateRequired = false,
}) => {
  const classes = useStyles()

  const [vehicles, setVehicles] = useState([])
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [openVehicleDialog, setOpenVehicleDialog] = useState(false)
  const [openModelDialog, setOpenModelDialog] = useState(false)
  const [openBrandDialog, setOpenBrandDialog] = useState(false)
  const [vehicle, setVehicle] = useState(vehicleIn)
  const [brand, setBrand] = useState(brandIn)
  const [model, setModel] = useState(modelIn)
  const [type, setType] = useState(modelIn?.type ?? 'CAR')
  const [newVehicle, setNewVehicle] = useState(vehicleIn)
  const [newBrand, setNewBrand] = useState(brandIn)
  const [newModel, setNewModel] = useState(modelIn)
  const [openWarning, setOpenWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationSeverity, setNotificationSeverity] = useState('success')

  const {
    data: vehiclesData,
    loading: vehiclesLoading,
    refetch: refetchVehiclesData,
    networkStatus: fastVehiclesNetworkStatus,
  } = useQuery(VEHICLES_QUERY, { notifyOnNetworkStatusChange: true })
  const {
    data: vehicleBrandsData,
    loading: vehicleBrandsLoading,
    refetch: refetchBrandsData,
    networkStatus: fastBrandsNetworkStatus,
  } = useQuery(VEHICLE_BRANDS_QUERY, { notifyOnNetworkStatusChange: true })
  const {
    data: vehicleModelsData,
    loading: vehicleModelsLoading,
    refetch: refetchModelsData,
    networkStatus: fastModelsNetworkStatus,
  } = useQuery(VEHICLE_MODELS_QUERY, { notifyOnNetworkStatusChange: true })

  const [
    createVehicle,
    {
      data: createVehicleData,
      loading: createVehicleLoading,
      error: createVehicleError,
    },
  ] = useMutation(CREATE_VEHICLE)
  const [
    createVehicleBrand,
    {
      data: createVehicleBrandData,
      loading: createVehicleBrandLoading,
      error: createVehicleBrandError,
    },
  ] = useMutation(CREATE_BRAND)
  const [
    createVehicleModel,
    {
      data: createVehicleModelData,
      loading: createVehicleModelLoading,
      error: createVehicleModelError,
    },
  ] = useMutation(CREATE_MODEL)

  const processVehicleSuccess = useCallback(
    (message, vehicleCreated) => {
      refetchVehiclesData()
      setVehicle(vehicleCreated)
      setBrand(vehicleCreated.model?.brand)
      setModel(vehicleCreated.model)
      addVehicle(vehicleCreated, index)
      setNotificationMessage(message)
      setNotificationSeverity('success')
      setOpenNotification(true)
    },
    [refetchVehiclesData, addVehicle, index]
  )

  const processBrandSuccess = useCallback(
    (message, brand) => {
      refetchBrandsData()
      setBrand(brand)
      setNotificationMessage(message)
      setNotificationSeverity('success')
      setOpenNotification(true)
    },
    [refetchBrandsData]
  )

  const processModelSuccess = useCallback(
    (message, model) => {
      refetchModelsData()
      setModel(model)
      setNotificationMessage(message)
      setNotificationSeverity('success')
      setOpenNotification(true)
    },
    [refetchModelsData]
  )

  useEffect(() => {
    if (
      vehiclesLoading === false &&
      vehiclesData &&
      fastVehiclesNetworkStatus === NetworkStatus.ready
    ) {
      setVehicles(vehiclesData.vehicles)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesLoading, vehiclesData, fastVehiclesNetworkStatus])

  useEffect(() => {
    if (
      vehicleBrandsLoading === false &&
      vehicleBrandsData &&
      fastBrandsNetworkStatus === NetworkStatus.ready
    ) {
      setBrands(vehicleBrandsData.vehicleBrands)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleBrandsLoading, vehicleBrandsData, fastBrandsNetworkStatus])

  useEffect(() => {
    if (
      vehicleModelsLoading === false &&
      vehicleModelsData &&
      fastModelsNetworkStatus === NetworkStatus.ready
    ) {
      setModels(vehicleModelsData.vehicleModels)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleModelsLoading, vehicleModelsData, fastModelsNetworkStatus])

  useEffect(() => {
    if (createVehicleLoading === false && createVehicleData) {
      processVehicleSuccess(
        `Vehículo ${createVehicleData.createVehicle.id} creado`,
        createVehicleData.createVehicle
      )
    } else if (createVehicleError) {
      showNotificationError(createVehicleError.message)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createVehicleData, createVehicleLoading, createVehicleError])

  useEffect(() => {
    if (createVehicleBrandLoading === false && createVehicleBrandData) {
      processBrandSuccess(
        `Marca ${createVehicleBrandData.createVehicleBrand.id} creada`,
        createVehicleBrandData.createVehicleBrand
      )
    } else if (createVehicleBrandError) {
      showNotificationError(createVehicleBrandError.message)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createVehicleBrandData,
    createVehicleBrandLoading,
    createVehicleBrandError,
  ])

  useEffect(() => {
    if (createVehicleModelLoading === false && createVehicleModelData) {
      processModelSuccess(
        `Modelo ${createVehicleModelData.createVehicleModel.id} creado`,
        createVehicleModelData.createVehicleModel
      )
    } else if (createVehicleModelError) {
      showNotificationError(createVehicleModelError.message)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createVehicleModelData,
    createVehicleModelLoading,
    createVehicleModelError,
  ])

  //cleanup
  useEffect(() => {
    return () => {}
  }, [])

  const onChangeVehicle = (vehicle) => {
    if (vehicle && vehicle.inputValue) {
      // Nuevo vehiculo
      setNewVehicle(vehicle.inputValue)
      setOpenVehicleDialog(true)
    } else if (vehicle && typeof vehicle !== 'undefined') {
      //Vehiculo ya creado
      setVehicle(vehicle)
      setBrand(vehicle.model?.brand)
      setModel(vehicle.model)
      setType(vehicle.model?.type)
      addVehicle(vehicle, index)
    } else {
      //null or undefined
      setVehicle(null)
      addVehicle(null, index)
    }
  }

  const onChangeBrand = (brand) => {
    if (brand && brand.inputValue) {
      // Nueva marca
      setNewBrand(brand.inputValue)
      setOpenBrandDialog(true)
    } else if (brand && typeof brand !== 'undefined') {
      //Marca ya creada
      setBrand(brand)
    } else {
      //null or undefined
      setBrand(null)
    }
    setModel(null)
  }

  const onChangeModel = (model) => {
    if (model && model.inputValue) {
      // Nuevo modelo
      if (brand) {
        setNewModel(model.inputValue)
        setOpenModelDialog(true)
      } else {
        setWarningMessage('Marca necesaria para crear un nuevo modelo')
        setOpenWarning(true)
      }
    } else if (model && typeof model !== 'undefined') {
      //Modelo ya creado
      setModel(model)
      setType(model?.type)
    } else {
      //null or undefined
      setModel(null)
    }
  }

  const onChangeType = (type) => {
    setType(type)
  }

  const handleNewVehicleConfirm = () => {
    setOpenVehicleDialog(false)
    createVehicle({
      variables: { vehicle: { plate: newVehicle, model_id: model?.id } },
    }).catch((err) => {
      showNotificationError(err)
    })
  }

  const handleNewBrandConfirm = () => {
    setOpenBrandDialog(false)
    createVehicleBrand({
      variables: { vehicleBrand: { name: newBrand } },
    }).catch((err) => {
      showNotificationError(err)
    })
  }

  const handleNewModelConfirm = () => {
    setOpenModelDialog(false)
    createVehicleModel({
      variables: {
        vehicleModel: { name: newModel, type: type, brand_id: brand.id },
      },
    }).catch((err) => {
      showNotificationError(err)
    })
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={3} className={classes.field}>
          <CreatableAutocomplete
            id={`vehicle-brand-${index}`}
            options={brands}
            disabled={vehicle?.id != null}
            onChange={(event, newValue) => onChangeBrand(newValue)}
            style={{ width: 200 }}
            value={brand || null}
            label="Marca del vehículo"
            name="name"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.field}>
          <TextField
            id={`vehicle-type-${index}`}
            select
            label="Tipo de vehículo"
            style={{ width: 200 }}
            value={type || 'CAR'}
            onChange={(event) => onChangeType(event.target.value)}
            disabled={vehicle?.id != null || model?.id != null}
          >
            {VEHICLE_TYPES.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.field}>
          <CreatableAutocomplete
            id={`vehicle-model-${index}`}
            options={models.filter((model) => model.brand.id === brand?.id)}
            disabled={vehicle?.id != null || brand?.id == null}
            onChange={(event, newValue) => onChangeModel(newValue)}
            style={{ width: 200 }}
            value={model || null}
            label="Modelo del vehículo"
            name="name"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.field}>
          <CreatableAutocomplete
            id={`vehicle-${index}`}
            options={
              model
                ? vehicles.filter((vehicle) => vehicle.model?.id === model.id)
                : vehicles
            }
            onChange={(event, newValue) => onChangeVehicle(newValue)}
            style={{ width: 200 }}
            value={vehicle || null}
            label="Matrícula del vehículo"
            name="plate"
            required={plateRequired}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={openVehicleDialog}
        setOpen={setOpenVehicleDialog}
        title="Nuevo vehículo"
        text={
          model
            ? `¿Desear crear el vehículo ${brand?.name} ${model?.name} con matrícula ${newVehicle}?`
            : `¿Desear crear un vehículo sin marca ni modelo con matrícula ${newVehicle}?`
        }
        confirmText="Crear"
        handleConfirm={handleNewVehicleConfirm}
      />
      <ConfirmDialog
        open={openModelDialog}
        setOpen={setOpenModelDialog}
        title="Nuevo modelo"
        text={`¿Desear crear el modelo ${newModel} de la marca ${brand?.name}?`}
        confirmText="Crear"
        handleConfirm={handleNewModelConfirm}
      />
      <ConfirmDialog
        open={openBrandDialog}
        setOpen={setOpenBrandDialog}
        title="Nueva marca"
        text={`¿Desear crear la marca ${newBrand}?`}
        confirmText="Crear"
        handleConfirm={handleNewBrandConfirm}
      />
      <WarningDialog
        open={openWarning}
        setOpen={setOpenWarning}
        title="Datos requeridos"
        text={warningMessage}
      />
      <Notification
        open={openNotification}
        setOpen={setOpenNotification}
        message={notificationMessage}
        severity={notificationSeverity}
      />
      <BackdropLoading
        open={
          vehiclesLoading ||
          vehicleBrandsLoading ||
          vehicleModelsLoading ||
          createVehicleLoading ||
          createVehicleBrandLoading ||
          createVehicleModelLoading
        }
      />
    </>
  )
}

export default FastVehicleForm

import { Paper, Grid, TextField, Typography, MenuItem, TableContainer } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { getMonth, getYear } from 'date-fns';
import CustomTable from '../common/CustomTable';

const MONTHS = [
    {id: 0, label: "Enero"},
    {id: 1, label: "Febrero"},
    {id: 2, label: "Marzo"},
    {id: 3, label: "Abril"},
    {id: 4, label: "Mayo"},
    {id: 5, label: "Junio"},
    {id: 6, label: "Julio"},
    {id: 7, label: "Agosto"},
    {id: 8, label: "Septiembre"},
    {id: 9, label: "Octubre"},
    {id: 10, label: "Noviembre"},
    {id: 11, label: "Diciembre"}
];

const CLIENTS_CLINICS_HEADERS = [
    { id: 'clinic', label: 'Clínica' },
    { id: 'client', label: 'Cliente' }
];

const getYearsWithStartTreatment = (expedients) => [...new Set(expedients?.filter(expedient => expedient.clients && expedient.clients
        .filter(client => client.clinic && client.start_treatment_date).length > 0)
        .flatMap(expedient => expedient.clients)
        .filter(client => client.clinic && client.start_treatment_date)
        .map(client => getYear(client.start_treatment_date)))].sort();

const getClinicsByClient = (expedients, month, year) => [...new Set(expedients?.filter(expedient => expedient.clients && expedient.clients
    .filter(client => client.clinic && client.start_treatment_date && getMonth(client.start_treatment_date) === month && getYear(client.start_treatment_date) === year).length > 0)
    .flatMap(expedient => expedient.clients)
    .filter(client => client.clinic && client.start_treatment_date)
    .map((client, index) => { return { id: index, clinic: client.clinic.name, client: `${client.client.first_name} ${client.client.last_name}` } }))];


const ClientsInClinics = ({ expedients }) => {
    const [month, setMonth] = useState(getMonth(Date.now()));
    const [year, setYear] = useState(getYear(Date.now()));
    const [years, setYears] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(getClinicsByClient(expedients, month, year));
    }, [expedients, month, year]);

    useEffect(() => {
        setYears(getYearsWithStartTreatment(expedients));
    }, [expedients]);

    return (
        <Paper style={{padding: 15, height: "100%"}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h5" align="center">Clínicas con clientes en tratamiento</Typography>
                </Grid>
                <Grid item>
                    <Grid container justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <TextField
                                id="option"
                                select
                                value={month}
                                onChange={e => setMonth(e.target.value)}
                                variant="outlined"
                            >
                                {MONTHS.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="option"
                                select
                                value={years.length !== 0 ? year : ''}
                                onChange={e => setYear(e.target.value)}
                                variant="outlined"
                            >
                                {years.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TableContainer style={{ maxHeight: 300 }}>
                <CustomTable headers={CLIENTS_CLINICS_HEADERS} rows={rows} handleRowClick={() => {}} orderField="clinic" orderAsc={true}/>
            </TableContainer>
        </Paper>
    );
}

export default ClientsInClinics;
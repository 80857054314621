import { Typography, TextField, Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { calculateReceiveAmount } from '../../../functions/compensation';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    }
}));

const MAX_AMOUNT_VAL = 9999999999;

const ExpedientDetailsCompensationSection = ({ estimatedAmount, setEstimatedAmount, insuranceOffer, setInsuranceOffer, accountPayment, setAccountPayment,
    finalAmount, setFinalAmount }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Accordion style={{ border: "1px solid #D7D2D1" }}>
                <AccordionSummary style={{ borderBottom: "1px solid #D7D2D1" }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="compensation-content"
                    id="compensation-header">
                    <Typography variant="h6">Indemnización</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid container item>
                            <Grid item xs={12} sm={6} lg={3} className={classes.field}>
                                <NumberFormat
                                    customInput={TextField}
                                    id="estimated_amount"
                                    label="Indemnización estimada"
                                    value={estimatedAmount || ''}
                                    onValueChange={(value) => { setEstimatedAmount(value.floatValue) }}
                                    suffix="€"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale="2"
                                    isAllowed={({ floatValue }) => floatValue == null || floatValue <= MAX_AMOUNT_VAL}
                                    style={{ width: 200 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3} className={classes.field}>
                                <NumberFormat
                                    customInput={TextField}
                                    id="insurance_offer"
                                    label="Oferta de la aseguradora"
                                    value={insuranceOffer || ''}
                                    onValueChange={(value) => { setInsuranceOffer(value.floatValue) }}
                                    suffix="€"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale="2"
                                    isAllowed={({ floatValue }) => floatValue == null || floatValue <= MAX_AMOUNT_VAL}
                                    style={{ width: 200 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3} className={classes.field}>
                                <NumberFormat
                                    customInput={TextField}
                                    id="account_payment"
                                    label="Pago a cuenta"
                                    value={accountPayment || ''}
                                    onValueChange={(value) => { setAccountPayment(value.floatValue) }}
                                    suffix="€"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale="2"
                                    isAllowed={({ floatValue }) => floatValue == null || floatValue <= MAX_AMOUNT_VAL}
                                    style={{ width: 200 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={12} sm={6} lg={3} className={classes.field}>
                                <NumberFormat
                                    customInput={TextField}
                                    id="final_amount"
                                    label="Idemnización final"
                                    value={finalAmount || ''}
                                    onValueChange={(value) => { setFinalAmount(value.floatValue) }}
                                    suffix="€"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale="2"
                                    isAllowed={({ floatValue }) => floatValue == null || floatValue <= MAX_AMOUNT_VAL}
                                    style={{ width: 200 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3} className={classes.field}>
                                <NumberFormat
                                    customInput={TextField}
                                    id="amount_to_receive"
                                    label="Importe para el despacho"
                                    value={finalAmount ? calculateReceiveAmount(finalAmount) : ''}
                                    suffix="€"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    decimalScale="2"
                                    style={{ width: 200 }}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default ExpedientDetailsCompensationSection;
import { Button, ButtonGroup } from '@material-ui/core'
import { useState } from 'react'
import AttachFile from './AttachFile'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import EditIcon from '@material-ui/icons/Edit'
import AttachFileIcon from '@material-ui/icons/AttachFile'
const actions = {
    Generate: {
        id: 'Generate',
        label: 'Generar documento',
        icon: <NoteAddIcon />
    },
    Sign: { id: 'Sign', label: 'Adjuntar firmado', icon: <EditIcon /> },
    Attach: {
        id: 'Attach',
        label: 'Adjuntar documento',
        icon: <AttachFileIcon />
    },
    Download: {
        id: 'Download',
        label: 'Descargar documento',
        icon: <CloudDownloadIcon />
    },
    Delete: { id: 'Delete', label: 'Eliminar documento', icon: <DeleteIcon /> }
}

const buttons = {
    NOCREATED: [actions.Generate, actions.Attach],
    NEW: [actions.Sign, actions.Download, actions.Delete],
    PENDING: [actions.Download, actions.Delete],
    SIGNED: [actions.Download, actions.Delete]
}
const DocumentationBtn = ({
    document,
    handleDocumentGeneration,
    handleDocumentDelete,
    handleDocumentUpdate,
    handleDocumentDownload,
    handleDocumentAttach
}) => {
    // states
    const [openAttachBox, setOpenAttachBox] = useState(false)
    const [actionAttachBox, setActionAttachBox] = useState('')

    // Actions
    const handleTypeOfActionClick = (
        action,
        actionLabel,
        docType,
        docPlate,
        docId
    ) => {
        switch (action) {
            case 'Generate':
                handleDocumentGeneration(docType, docPlate)
                break
            case 'Regenerate':
                break
            case 'Sign':
                setOpenAttachBox(true)
                setActionAttachBox(actionLabel)
                break
            case 'Attach':
                setOpenAttachBox(true)
                setActionAttachBox(actionLabel)
                break
            case 'Resign':
                break
            case 'Delete':
                handleDocumentDelete(docId)
                break
            case 'Download':
                handleDocumentDownload(docId)
                break
            default:
                console.log('No action')
        }
    }

    const signHandler = (file) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
            const fileData = e.target.result
            const encrypted = btoa(fileData)
            handleDocumentUpdate(document.id, 'SIGNED', encrypted)
            setOpenAttachBox(false)
        }
        fileReader.readAsBinaryString(file)
    }

    const attachNewDocumentHandler = (file) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
            const fileData = e.target.result
            const encrypted = btoa(fileData)
            handleDocumentAttach(encrypted, document.type, document.plate)
            setOpenAttachBox(false)
        }
        fileReader.readAsBinaryString(file)
    }
    return (
        <>
            <ButtonGroup
                size="small"
                variant="contained"
                aria-label="contained primary button group"
                color="primary"
            >
                {buttons[document.status ?? 'NOCREATED'].map((element) => (
                    <Button
                        key={`${element.id}-${document.type}-${document.plate}`}
                        id={element.id}
                        onClick={() =>
                            handleTypeOfActionClick(
                                element.id,
                                element.label,
                                document.type,
                                document.plate,
                                document.id
                            )
                        }
                        title={element.label}
                    >
                        {element.icon}
                    </Button>
                ))}
            </ButtonGroup>
            <AttachFile
                open={openAttachBox}
                setOpen={setOpenAttachBox}
                title={actionAttachBox}
                action={actionAttachBox}
                handler={
                    actionAttachBox === 'Adjuntar documento'
                        ? attachNewDocumentHandler
                        : signHandler
                }
            />
        </>
    )
}

export default DocumentationBtn

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import SaveIcon from '@material-ui/icons/Save'
import {
    Typography,
    Grid,
    Button,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useState } from 'react'
import ClientForm from '../../client/ClientForm'
import CustomModal from '../../common/CustomModal'
import InsuranceCompanyForm from '../../insuranceCompany/InsuranceCompanyForm'
import ClinicForm from '../../clinic/ClinicForm'
import EmailForm from '../../email/EmailForm'
import ConfirmDialog from '../../common/ConfirmDialog'
import Documentation from '../documentation/Documentation'
import IncidentReasonForm from '../../incidentReason/IncidentReasonForm'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid #D7D2D1'
    }
}))

const ExpedientDetailsHeader = ({
    expedient,
    goBack,
    setOpenNotification,
    setNotificationMessage,
    setNotificationSeverity,
    clientsSelected,
    refetchClientsData,
    refetchInsuranceCompanyData,
    refetchClinicsData,
    handleDeleteButton,
    isEmailDuplicated,
    refetchIncidentReasonsData
}) => {
    const classes = useStyles()

    const [openActionMenu, setOpenActionMenu] = useState()
    const [openEmail, setOpenEmail] = useState(false)
    const [openNewClient, setOpenNewClient] = useState(false)
    const [openNewInsuranceCompany, setOpenNewInsuranceCompany] =
        useState(false)
    const [openNewClinic, setOpenNewClinic] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openDocumentation, setOpenDocumentation] = useState(false)
    const [openNewIncidentReason, setOpenNewIncidentReason] = useState(false)

    const handleOpenEmail = () => {
        setOpenEmail(true)
        setOpenActionMenu(null)
    }

    const handleOpenNewClient = () => {
        setOpenNewClient(true)
        setOpenActionMenu(null)
    }

    const handleOpenNewInsuranceCompany = () => {
        setOpenNewInsuranceCompany(true)
        setOpenActionMenu(null)
    }

    const handleOpenNewClinic = () => {
        setOpenNewClinic(true)
        setOpenActionMenu(null)
    }

    const handleOpenDelete = () => {
        setOpenDeleteDialog(true)
        setOpenActionMenu(null)
    }

    const confirmDelete = () => {
        handleDeleteButton()
        setOpenDeleteDialog(false)
    }

    const handleOpenDocumentation = () => {
        setOpenDocumentation(true)
        setOpenActionMenu(null)
    }

    const handleOpenNewIncidentReason = () => {
        setOpenNewIncidentReason(true)
        setOpenActionMenu(null)
    }

    return (
        <>
            <Grid
                container
                className={classes.root}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="default"
                        startIcon={<KeyboardBackspaceIcon />}
                        onClick={() => goBack()}
                    >
                        Volver
                    </Button>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                    <Typography variant="h6">
                        Expediente {expedient.id}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item>
                        <IconButton
                            aria-label="Acciones"
                            onClick={(e) => setOpenActionMenu(e.currentTarget)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="actions-menu"
                            anchorEl={openActionMenu}
                            keepMounted
                            open={Boolean(openActionMenu)}
                            onClose={() => setOpenActionMenu(null)}
                        >
                            <MenuItem onClick={handleOpenEmail}>
                                Enviar email
                            </MenuItem>
                            <MenuItem onClick={handleOpenDocumentation}>
                                Generar documentación
                            </MenuItem>
                            <MenuItem onClick={handleOpenNewClient}>
                                Nuevo cliente
                            </MenuItem>
                            <MenuItem onClick={handleOpenNewInsuranceCompany}>
                                Nueva compañía de seguros
                            </MenuItem>
                            <MenuItem onClick={handleOpenNewClinic}>
                                Nueva clínica
                            </MenuItem>
                            <MenuItem onClick={handleOpenNewIncidentReason}>
                                Nuevo motivo de accidente
                            </MenuItem>
                            <MenuItem onClick={handleOpenDelete}>
                                Eliminar expediente
                            </MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            type="submit"
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <CustomModal open={openNewClient} title="Nuevo cliente">
                <ClientForm
                    refreshData={refetchClientsData}
                    closeForm={() => setOpenNewClient(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                    isEmailDuplicated={isEmailDuplicated}
                />
            </CustomModal>
            <CustomModal
                open={openNewInsuranceCompany}
                title="Nueva compañía de seguros"
            >
                <InsuranceCompanyForm
                    refreshData={refetchInsuranceCompanyData}
                    closeForm={() => setOpenNewInsuranceCompany(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                />
            </CustomModal>
            <CustomModal open={openNewClinic} title="Nueva clínica">
                <ClinicForm
                    refreshData={refetchClinicsData}
                    closeForm={() => setOpenNewClinic(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                />
            </CustomModal>
            <CustomModal open={openEmail} title="Enviar email">
                <EmailForm
                    closeForm={() => setOpenEmail(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                    toList={clientsSelected
                        .filter((item) => item.client?.email?.length > 0)
                        .map((item) => item.client?.email)}
                />
            </CustomModal>
            <CustomModal
                open={openDocumentation}
                title="Documentación"
                enableClose={true}
                setOpen={setOpenDocumentation}
            >
                <Documentation expedient={expedient} />
            </CustomModal>
            <ConfirmDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                title="Eliminar cliente"
                text="¿Está seguro de que quiere eliminar el expediente?"
                confirmText="Eliminar"
                handleConfirm={confirmDelete}
            />
            <CustomModal
                open={openNewIncidentReason}
                title="Nuevo motivo de accidente"
            >
                <IncidentReasonForm
                    refreshData={refetchIncidentReasonsData}
                    closeForm={() => setOpenNewIncidentReason(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                />
            </CustomModal>
        </>
    )
}

export default ExpedientDetailsHeader

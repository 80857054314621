import Login from './components/common/Login';
import Backoffice from './components/common/Backoffice';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';
import { CssBaseline } from '@material-ui/core';
import Loading from './components/common/Loading';
import Error from './components/common/Error';
import { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import EmployeeContext from './contexts/EmployeeContext';

const EMPLOYEE_QUERY = gql`
    query employeeByEmail ($email: String!) {
        employeeByEmail (email: $email) {
            id
            first_name
            last_name
            email
            phone
            role
            active
        }
    }
`;

const theme = createMuiTheme({
  palette: {
      primary: {
          main: pink[600]
      }
  }
});

const App = () => {

  const { isAuthenticated, isLoading, user, logout } = useAuth0();

  const [employee, setEmployee] = useState(null);
  const [email, setEmail] = useState();

  const { data: employeeData, error: employeeError, loading: employeeLoading } =
    useQuery(EMPLOYEE_QUERY, { skip: email == null, variables: { email: email } });

  useEffect(() => {
    if (employeeLoading === false && employeeData) {
      setEmployee(employeeData.employeeByEmail);
    }
  }, [employeeLoading, employeeData]);

  useEffect(() => {
    if (isLoading === false && user) {
      setEmail(user.email);
    }
  }, [isLoading, user]);

  if (employeeError) {
    return <Error message="Se ha producido un error conectando al servidor. Recargue la página"/>
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          {!isAuthenticated && !isLoading ?
            <Redirect to="/login" /> : isLoading || !employee ? <Loading /> :
              <EmployeeContext.Provider value={employee}>
                <Backoffice userPicture={user.picture} logout={logout} />
              </EmployeeContext.Provider>
          }
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default App;

import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    }
}))

const ExpedientDetailsLocationSection = ({
    provinces,
    province,
    changeProvince,
    cities,
    city,
    setCity,
    address,
    setAddress,
    postalCode,
    setPostalCode,
    statusId
}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Accordion style={{ border: '1px solid #D7D2D1' }}>
                <AccordionSummary
                    style={{ borderBottom: '1px solid #D7D2D1' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="location-content"
                    id="location-header"
                >
                    <Typography variant="h6">
                        Localización del suceso
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid container item className={classes.field}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="province"
                                    select
                                    label="Provincia"
                                    value={
                                        provinces.length === 0
                                            ? ''
                                            : province || ''
                                    }
                                    onChange={changeProvince}
                                    helperText="Selecciona la provincia"
                                    required={statusId !== 0}
                                    style={{
                                        width: 250
                                    }}
                                >
                                    {provinces.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="city"
                                    select
                                    label="Ciudad"
                                    value={
                                        cities.length === 0 ? '' : city || ''
                                    }
                                    onChange={(e) => {
                                        setCity(e.target.value)
                                    }}
                                    helperText="Selecciona la ciudad"
                                    required={statusId !== 0}
                                    style={{
                                        width: 250
                                    }}
                                >
                                    {cities.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="address"
                                label="Dirección"
                                value={address || ''}
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                }}
                                helperText="Dirección del suceso"
                                style={{
                                    width: '80%'
                                }}
                                inputProps={{
                                    maxLength: 255
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <NumberFormat
                                customInput={TextField}
                                id="postal_code"
                                label="Código postal"
                                value={postalCode || ''}
                                onValueChange={(value) => {
                                    setPostalCode(value.floatValue)
                                }}
                                decimalScale="0"
                                isAllowed={({ floatValue }) =>
                                    floatValue == null || floatValue <= 99999
                                }
                                helperText="Código postal"
                                style={{
                                    width: 300
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ExpedientDetailsLocationSection

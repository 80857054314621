import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Typography,
    TableCell,
    Divider,
} from '@material-ui/core'
import {format} from 'date-fns'
import {useState, useEffect} from 'react'
import CustomTable from '../common/CustomTable'
import {calculateReceiveAmount} from '../../functions/compensation'

const TO_BE_PAID_EXPEDIENTS_HEADERS = [
    {id: 'id', label: 'ID', numeric: true, width: '5%'},
    {id: 'title', label: 'Título', width: '40%'},
    {id: 'date_of_entry', label: 'F.Alta', width: '10%'},
    {id: 'account_payment_formatted', label: 'Pago a cuenta', width: '15%'},
    {id: 'final_amount_formatted', label: 'Cantidad final', width: '15%'},
    {
        id: 'amount_to_receive_formatted',
        label: 'Honorarios despacho',
        width: '15%',
    },
]

const TO_BE_PAID_STATUS_ID = 6

const formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
})

const getExpedientsToBePaid = (expedients) =>
    expedients
        .filter((expedient) => expedient.status.id === TO_BE_PAID_STATUS_ID)
        .map((expedient) => {
            const receiveAmount = expedient.final_amount
                ? calculateReceiveAmount(expedient.final_amount)
                : 0
            return {
                ...expedient,
                account_payment_formatted: formatter.format(
                    expedient.account_payment ? expedient.account_payment : 0
                ),
                final_amount_formatted: formatter.format(
                    expedient.final_amount ? expedient.final_amount : 0
                ),
                amount_to_receive: receiveAmount,
                amount_to_receive_formatted: formatter.format(receiveAmount),
                date_of_entry: format(expedient.created_at, 'dd/MM/yyyy'),
            }
        })

const ToBePaid = ({expedients}) => {
    const [rows, setRows] = useState([])
    const [accountPaymentTotal, setAccountPaymentTotal] = useState('0 €')
    const [finalAmountTotal, setFinalAmountTotal] = useState('0 €')
    const [amountToReceiveTotal, setAmountToReceiveTotal] = useState('0 €')

    useEffect(() => {
        setRows(getExpedientsToBePaid(expedients))
    }, [expedients])

    useEffect(() => {
        if (rows.length > 0) {
            setAccountPaymentTotal(
                formatter.format(
                    rows.map((row) => row.account_payment).reduce((a, b) => a + b)
                )
            )
            setFinalAmountTotal(
                formatter.format(
                    rows.map((row) => row.final_amount).reduce((a, b) => a + b)
                )
            )
            setAmountToReceiveTotal(
                formatter.format(
                    rows.map((row) => row.amount_to_receive).reduce((a, b) => a + b)
                )
            )
        }
    }, [rows])

    return (
        <Paper style={{padding: 15, height: '100%'}}>
            <Typography variant="h5">Expedientes a la espera de cobro</Typography>
            <TableContainer style={{maxHeight: 300}}>
                <CustomTable
                    headers={TO_BE_PAID_EXPEDIENTS_HEADERS}
                    rows={rows}
                    handleRowClick={() => {
                    }}
                    orderField="id"
                    orderAsc={true}
                />
            </TableContainer>
            <Divider/>
            <Divider/>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell
                                width="55%"
                                style={{fontWeight: 'bold', fontSize: '16px'}}
                            >
                                Cantidades totales:
                            </TableCell>
                            <TableCell
                                width="15%"
                                style={{fontWeight: 'bold', fontSize: '16px'}}
                            >
                                {accountPaymentTotal}
                            </TableCell>
                            <TableCell
                                width="15%"
                                style={{fontWeight: 'bold', fontSize: '16px'}}
                            >
                                {finalAmountTotal}
                            </TableCell>
                            <TableCell
                                width="15%"
                                style={{fontWeight: 'bold', fontSize: '16px'}}
                            >
                                {amountToReceiveTotal}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default ToBePaid

import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import BackdropLoading from '../common/BackdropLoading';


const CREATE_INCIDENT_REASON = gql`
    mutation createIncidentReason ($incidentReason: IncidentReasonInput!) {
        createIncidentReason (incident_reason: $incidentReason) {
            id
            name
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1000,
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        overflow: 'auto',
        maxHeight: "90%"
    },
    formContainer: {
        marginTop: theme.spacing(4)
    },
    section: {
        marginBottom: theme.spacing(4)
    },
    descriptionField: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    deleteButtonField: {
        marginTop: theme.spacing(2),
        float: "right"
    }
}));


const IncidentReasonForm = ({ refreshData, closeForm, openNotification, setNotificationMessage, setNotificationSeverity }) => {
    const classes = useStyles();

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const incidentReason = {
            name
        };
        createIncidentReason({ variables: { incidentReason } }).catch(err => {
            showNotificationError(err);
        });

    };

    const processSuccess = useCallback((message) => {
        refreshData();
        closeForm();
        setNotificationMessage(message);
        setNotificationSeverity('success');
        openNotification();
    }, [refreshData, closeForm, setNotificationMessage, setNotificationSeverity, openNotification]);

    const showNotificationError = useCallback((message) => {
        setNotificationMessage(message);
        setNotificationSeverity('error');
        openNotification();
    }, [setNotificationMessage, setNotificationSeverity, openNotification]);

    const [name, setName] = useState(null);

    const [createIncidentReason, { data, loading, error }] = useMutation(CREATE_INCIDENT_REASON);

    useEffect(() => {
        if(loading === false && data) {
            processSuccess(`Motivo de accidente ${data.createIncidentReason.id} creado`);
        } else if (error) {
            showNotificationError(error.message);
        }
        // eslint-disable-next-line
    }, [loading, data, error]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <Grid container direction="column" className={classes.formContainer}>
                    <Grid container className={classes.section}>
                        <Grid item xs={12} className={classes.field}>
                            <TextField id="name"
                                       label="Motivo"
                                       value={name || ''}
                                       onChange={(e) => { setName(e.target.value) }}
                                       required
                                       inputProps={{
                                           maxLength: 200,
                                       }}
                                       fullWidth={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                type="submit"
                                className={classes.button}
                            >
                                Guardar
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelIcon />}
                                className={classes.button}
                                onClick={closeForm}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <BackdropLoading open={loading} />
        </>
    );
};

export default IncidentReasonForm;
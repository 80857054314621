import {
  Divider,
  Typography,
  TextField,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FastVehicleForm from '../../vehicle/FastVehicleForm'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}))

const ExpedientDetailsVehicleSection = ({
  vehiclesNumber,
  vehiclesSelected,
  addVehicleToVehiclesSelected,
  showNotificationError,
  setOpenLoading,
  insuranceCompanies,
  addInsuranceCompanyToVehiclesSelected,
  addVehicle,
  removeVehicle,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Accordion style={{ border: '1px solid #D7D2D1' }}>
        <AccordionSummary
          style={{ borderBottom: '1px solid #D7D2D1' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="vehicles-content"
          id="vehicles-header"
        >
          <Typography variant="h6">Vehículos contrarios</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid container item>
              {[...Array(vehiclesNumber).keys()].map((vehicleNumber) => (
                <Grid
                  container
                  key={`opposite-grid-${vehicleNumber}`}
                  style={{ marginBottom: 40 }}
                >
                  <Grid item xs={12} className={classes.field}>
                    <Typography variant="h6">{`Vehículo contrario ${
                      vehicleNumber + 1
                    }`}</Typography>
                    <Divider />
                  </Grid>
                  <FastVehicleForm
                    index={vehicleNumber}
                    addVehicle={addVehicleToVehiclesSelected}
                    showNotificationError={showNotificationError}
                    setOpenLoading={setOpenLoading}
                    vehicleIn={vehiclesSelected[vehicleNumber]?.vehicle}
                    brandIn={
                      vehiclesSelected[vehicleNumber]?.vehicle?.model?.brand
                    }
                    modelIn={vehiclesSelected[vehicleNumber]?.vehicle?.model}
                    plateRequired={true}
                  />
                  <Grid item xs={12} sm={6} className={classes.field}>
                    <Autocomplete
                      id={`insurance-${vehicleNumber}`}
                      options={insuranceCompanies}
                      getOptionLabel={(insuranceCompany) =>
                        insuranceCompany.id ? insuranceCompany.name : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      style={{ width: 400 }}
                      value={
                        insuranceCompanies.length === 0
                          ? ''
                          : vehiclesSelected[vehicleNumber]
                              ?.insurance_company || null
                      }
                      onChange={(event, newValue) =>
                        addInsuranceCompanyToVehiclesSelected(
                          newValue,
                          vehicleNumber
                        )
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Compañía de seguros" />
                      )}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              className={classes.section}
            >
              <Grid item className={classes.field}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<AddCircleIcon />}
                  className={classes.button}
                  onClick={addVehicle}
                >
                  Añadir vehículo
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<RemoveCircleIcon />}
                  className={classes.button}
                  onClick={removeVehicle}
                >
                  Quitar vehículo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default ExpedientDetailsVehicleSection

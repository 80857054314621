import { TextField } from '@material-ui/core'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
const filter = createFilterOptions()

const CreatableAutocomplete = ({
  id,
  label,
  options,
  value,
  onChange,
  disabled,
  style,
  name,
  required,
}) => {
  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option[name])
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            [name]: `Crear "${inputValue}"`,
          })
        }

        return filtered
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={id}
      options={options || []}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option[name]
        }
        // Regular option
        return option[name]
      }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} />
      )}
      disabled={disabled}
      style={style}
    />
  )
}

export default CreatableAutocomplete

import { Grid } from '@material-ui/core'
import NewExpedientsBarChart from './NewExpedientsBarChart'
import { makeStyles } from '@material-ui/core/styles'
import ClientsInClinics from './ClientsInClinics'
import CountChar from './CountChar'
import ExpedientToPrescribe from './ExpedientToPrescribe'
import { useEffect, useState } from 'react'
import { getMonth, getYear } from 'date-fns'
import ToBePaid from './ToBePaid'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import BackdropLoading from '../common/BackdropLoading'
import Notification from '../common/Notification'

const EXPEDIENTS_QUERY = gql`
    query expedients {
        expedients {
            id
            created_at
            updated_at
            estimated_amount
            account_payment
            final_amount
            insurance_offer
            clients {
                client {
                    id
                    first_name
                    last_name
                }
                clinic {
                    id
                    name
                }
                start_treatment_date
            }
            status {
                id
                name
                description
                type
            }
        }
    }
`

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        overflow: 'hidden'
    }
}))

const STATUS_REJECTED = 8
const STATUS_CLOSED = 7
const STATUS_LEGAL_DEFENSE = 9

const getClientsNumberByExpedientsInThisMonth = (expedients) =>
    expedients
        .filter(
            (expedient) =>
                getYear(expedient.created_at) === getYear(Date.now()) &&
                getMonth(expedient.created_at) === getMonth(Date.now()) &&
                expedient.status.id !== STATUS_REJECTED
        )
        .flatMap((expedient) => expedient.clients).length

const getOpenClients = (expedients) =>
    [
        ...new Set(
            expedients
                .filter(
                    (expedient) =>
                        ![
                            STATUS_REJECTED,
                            STATUS_CLOSED,
                            STATUS_LEGAL_DEFENSE
                        ].includes(expedient.status.id)
                )
                .flatMap((expedient) => expedient.clients)
                .map((client) => client.client.id)
        )
    ].length

const Dashboard = () => {
    const classes = useStyles()

    const [expedients, setExpedients] = useState([])
    const [expedientsNumber, setExpedientsNumber] = useState(0)
    const [openClientsNumber, setOpenClientsNumber] = useState(0)
    const [openNotification, setOpenNotification] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationSeverity, setNotificationSeverity] = useState('success')

    const {
        loading: expedientsLoading,
        error: expedientsError,
        data: expedientsData,
        refetch: refetchExpedientData,
        networkStatus
    } = useQuery(EXPEDIENTS_QUERY, {
        pollInterval: 120000,
        notifyOnNetworkStatusChange: true
    })

    useEffect(() => {
        if (
            expedientsLoading === false &&
            expedientsData &&
            networkStatus === NetworkStatus.ready
        ) {
            setExpedients(expedientsData.expedients)
        } else if (expedientsError) {
            setNotificationMessage(expedientsError.message)
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [expedientsError, expedientsLoading, expedientsData, networkStatus])

    useEffect(() => {
        setExpedientsNumber(getClientsNumberByExpedientsInThisMonth(expedients))
    }, [expedients])

    useEffect(() => {
        setOpenClientsNumber(getOpenClients(expedients))
    }, [expedients])

    //cleanup
    useEffect(() => {
        return () => {}
    }, [])

    return (
        <>
            <Grid container className={classes.root} spacing={1}>
                <Grid item xs={12}>
                    <NewExpedientsBarChart
                        expedients={expedients}
                        refresh={refetchExpedientData}
                    />
                </Grid>
                <Grid container item spacing={1}>
                    <Grid item xs={12} md={9}>
                        <ClientsInClinics expedients={expedients} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CountChar
                            title="Nuevos clientes este mes"
                            count={expedientsNumber}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={1}>
                    <Grid item xs={12} md={3}>
                        <CountChar
                            title="Clientes abiertos"
                            count={openClientsNumber}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <ExpedientToPrescribe expedients={expedients} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ToBePaid expedients={expedients} />
                </Grid>
            </Grid>
            <BackdropLoading open={expedientsLoading} />
            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
        </>
    )
}

export default Dashboard

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Slide } from "@material-ui/core";
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = ({ title, text, confirmText, open, setOpen, handleConfirm }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
        >
            <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary">
                    {confirmText}
                </Button>
                <Button onClick={() => setOpen(false)} color="default">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
import React from 'react';
import { Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialog: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const Error = ({ message }) => {
    const classes = useStyles();
    return (
        <Dialog open={true}  className={classes.dialog}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>{message}</DialogContent>
        </Dialog>
    );
}

export default Error;

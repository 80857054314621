import {
    Typography,
    TextField,
    Grid,
    MenuItem,
    ListSubheader,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useState, useEffect, useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import ChangeStatusConfirmDialog from './ChangeStatusConfirmDialog'
import Notification from '../../common/Notification'

const STATUS_COLOR = {
    INITIAL: { background: '#929494', color: 'white' },
    FINAL: { background: '#15A515', color: 'white' },
    REJECTED: { background: '#E12E2E', color: 'white' },
    INTERMEDIATE: { background: '#116BB5', color: 'white' }
}

const OPORTUNITY_COLOR = { background: '#ffca1b', color: 'black' }

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    subheader: {
        pointerEvents: 'none'
    }
}))

const UPDATE_EXPEDIENT_STATUS = gql`
    mutation updateExpedientStatus(
        $id: Int!
        $next_status_id: Int!
        $next_status_description: String!
        $employee_email: String!
        $send_email: Boolean!
        $reject_reason_id: Int
    ) {
        updateExpedientStatus(
            id: $id
            next_status_id: $next_status_id
            next_status_description: $next_status_description
            employee_email: $employee_email
            send_email: $send_email
            reject_reason_id: $reject_reason_id
        ) {
            id
            updated_at
            updated_by {
                id
                first_name
                last_name
                email
            }
        }
    }
`

const ExpedientDetailsStatusSection = ({
    expedient_id,
    status,
    setStatus,
    nextStatus,
    previousStatus,
    employee,
    setEmployee,
    employees,
    createdAt,
    setCreatedAt,
    selectedDate,
    setSelectedDate,
    createdBy,
    updatedAt,
    updatedBy,
    setUpdatedAt,
    setUpdatedBy,
    partner,
    setOpenLoading
}) => {
    const classes = useStyles()

    const [newStatus, setNewStatus] = useState()
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationSeverity, setNotificationSeverity] = useState('success')

    const [updateExpedientStatus, { data, loading, error }] = useMutation(
        UPDATE_EXPEDIENT_STATUS
    )

    const updateStatus = (e) => {
        setNewStatus(e.target.value)
        setOpenConfirmDialog(true)
    }

    const confirmUpdateStatus = (sendEmail, rejectReasonId) => {
        //perform update
        const updateExpedientStatusInput = {
            id: expedient_id,
            next_status_id: newStatus.id,
            next_status_description: newStatus.name,
            employee_email: employee.email,
            send_email: sendEmail,
            reject_reason_id: rejectReasonId
        }
        updateExpedientStatus({ variables: updateExpedientStatusInput })

        //send email
        setOpenConfirmDialog(false)
    }

    const processSuccess = useCallback(
        (message) => {
            setNotificationMessage(message)
            setNotificationSeverity('success')
            setOpenNotification(true)
        },
        [setNotificationMessage, setNotificationSeverity, setOpenNotification]
    )

    const showNotificationError = useCallback(
        (message) => {
            setNotificationMessage(message)
            setNotificationSeverity('error')
            setOpenNotification(true)
        },
        [setNotificationMessage, setNotificationSeverity, setOpenNotification]
    )

    useEffect(() => {
        setOpenLoading(loading)
        if (loading === false && data) {
            setStatus(newStatus)
            setUpdatedAt(data.updateExpedientStatus.updated_at)
            setUpdatedBy(data.updateExpedientStatus.updated_by)
            processSuccess(
                `Estado del expediente ${data.updateExpedientStatus.id} actualizado`
            )
        } else if (error) {
            showNotificationError(error.message)
        }
        // eslint-disable-next-line
    }, [processSuccess, showNotificationError, data, error, loading])

    return (
        <div className={classes.root}>
            <Accordion
                defaultExpanded={true}
                style={{ border: '1px solid #D7D2D1' }}
            >
                <AccordionSummary
                    style={{ borderBottom: '1px solid #D7D2D1' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="status-content"
                    id="status-header"
                >
                    <Typography variant="h6">Estado</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                id="status"
                                select
                                value={status}
                                onChange={updateStatus}
                                helperText={status.description}
                                style={{
                                    width: '100%',
                                    borderColor:
                                        status.id === 0
                                            ? OPORTUNITY_COLOR.background
                                            : STATUS_COLOR[status.type]
                                                  .background
                                }}
                                required
                                variant="outlined"
                                SelectProps={{
                                    style: {
                                        backgroundColor:
                                            status.id === 0
                                                ? OPORTUNITY_COLOR.background
                                                : STATUS_COLOR[status.type]
                                                      .background,
                                        color:
                                            status.id === 0
                                                ? OPORTUNITY_COLOR.color
                                                : STATUS_COLOR[status.type]
                                                      .color
                                    }
                                }}
                            >
                                <MenuItem key={status.id} value={status}>
                                    {status.name}
                                </MenuItem>
                                {nextStatus.length > 0 && (
                                    <ListSubheader
                                        className={classes.subheader}
                                    >
                                        Estados siguientes
                                    </ListSubheader>
                                )}
                                {nextStatus.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                                {previousStatus.length > 0 && (
                                    <ListSubheader
                                        className={classes.subheader}
                                    >
                                        Estados anteriores
                                    </ListSubheader>
                                )}
                                {previousStatus.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    ampm={false}
                                    id="incident_date"
                                    label="Fecha del suceso"
                                    disableFuture
                                    value={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date)
                                    }}
                                    required
                                    format="dd/MM/yyyy HH:mm"
                                    inputVariant="outlined"
                                    style={{ width: '100%' }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <Autocomplete
                                id="employee"
                                options={employees}
                                getOptionLabel={(employee) =>
                                    employee.id
                                        ? `${employee.first_name} ${employee.last_name}`
                                        : ''
                                }
                                style={{ width: '100%' }}
                                value={
                                    employees.length === 0
                                        ? ''
                                        : employee || null
                                }
                                getOptionSelected={(option, value) =>
                                    option.id === value.id
                                }
                                onChange={(event, newValue) => {
                                    setEmployee(newValue)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Asignado a"
                                        variant="outlined"
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    ampm={false}
                                    id="created_at"
                                    label="Fecha de alta"
                                    disableFuture
                                    value={createdAt}
                                    onChange={(date) => {
                                        setCreatedAt(date)
                                    }}
                                    required
                                    format="dd/MM/yyyy HH:mm"
                                    inputVariant="outlined"
                                    style={{ width: '100%' }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="createdBy"
                                label="Creado por"
                                variant="outlined"
                                value={
                                    createdBy
                                        ? `${createdBy.first_name} ${createdBy.last_name}`
                                        : partner
                                        ? `${partner.name}`
                                        : ''
                                }
                                disabled
                                required
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    ampm={false}
                                    id="updated_at"
                                    label="Última actualización"
                                    disableFuture
                                    value={updatedAt}
                                    disabled
                                    required
                                    format="dd/MM/yyyy HH:mm"
                                    inputVariant="outlined"
                                    style={{ width: '100%' }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="updatedBy"
                                label="Última vez actualizado por"
                                variant="outlined"
                                value={
                                    updatedBy
                                        ? `${updatedBy.first_name} ${updatedBy.last_name}`
                                        : ''
                                }
                                disabled
                                required
                                style={{ width: '100%' }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <ChangeStatusConfirmDialog
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                handleConfirm={confirmUpdateStatus}
                newStatus={newStatus}
            />
            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
        </div>
    )
}

export default ExpedientDetailsStatusSection

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Slide } from "@material-ui/core";
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const WarningDialog = ({ title, text, open, setOpen }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
        >
            <DialogTitle id="warnin-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="warning-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="default">
                    Entendido
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default WarningDialog;
import { Grid, Typography, Divider, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../common/ConfirmDialog';
import BackdropLoading from '../common/BackdropLoading';


const CREATE_BRAND = gql`
    mutation createVehicleBrand ($vehicleBrand: VehicleBrandInput!) {
        createVehicleBrand (vehicle_brand: $vehicleBrand) {
            id
            name
        }
    }
`;

const UPDATE_BRAND = gql`
    mutation updateVehicleBrand ($id: Int!, $vehicleBrand: VehicleBrandInput!) {
        updateVehicleBrand (id: $id, vehicle_brand: $vehicleBrand) {
            id
            name
        }
    }
`;

const DELETE_BRAND = gql`
    mutation deleteVehicleBrand ($id: Int!) {
        deleteVehicleBrand (id: $id)
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1000,
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        overflow: 'auto',
        maxHeight: "90%"
    },
    formContainer: {
        marginTop: theme.spacing(4)
    },
    section: {
        marginBottom: theme.spacing(4)
    },
    descriptionField: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    deleteButtonField: {
        marginTop: theme.spacing(2),
        float: "right"
    }
}));


const VehicleBrandForm = ({ refreshData, closeForm, dataToUpdate, openNotification, setNotificationMessage, setNotificationSeverity }) => {
    const classes = useStyles();

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const vehicleBrand = {
            name
        };
        if (dataToUpdate) {
            updateVehicleBrand({ variables: { id: dataToUpdate.id, vehicleBrand: vehicleBrand } }).catch(err => {
                showNotificationError(err);
            });
        } else {
            createVehicleBrand({ variables: { vehicleBrand: vehicleBrand } }).catch(err => {
                showNotificationError(err);
            });
        }
    };

    const handleDeleteButton = () => {
        deleteVehicleBrand({ variables: { id: dataToUpdate.id } }).catch(err => {
            showNotificationError(err);
            setOpenDeleteDialog(false);
        });
    }

    const processSuccess = useCallback((message) => {
        refreshData();
        closeForm();
        setNotificationMessage(message);
        setNotificationSeverity('success');
        openNotification();
    }, [refreshData, closeForm, setNotificationMessage, setNotificationSeverity, openNotification]);

    const showNotificationError = useCallback((message) => {
        setNotificationMessage(message);
        setNotificationSeverity('error');
        openNotification();
    }, [setNotificationMessage, setNotificationSeverity, openNotification]);

    const [name, setName] = useState(dataToUpdate?.name);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [createVehicleBrand, { data: createVehicleBrandData, loading: createVehicleBrandLoading,
        error: createVehicleBrandError }] = useMutation(CREATE_BRAND);

    const [updateVehicleBrand, { data: updateVehicleBrandData, loading: updateVehicleBrandLoading,
        error: updateVehicleBrandError }] = useMutation(UPDATE_BRAND);

    const [deleteVehicleBrand, { data: deleteVehicleBrandData, loading: deleteVehicleBrandLoading,
        error: deleteVehicleBrandError }] = useMutation(DELETE_BRAND);

    useEffect(() => {
        if(createVehicleBrandLoading === false && createVehicleBrandData) {
            processSuccess(`Marca ${createVehicleBrandData.createVehicleBrand.id} creada`);
        } else if (createVehicleBrandError) {
            showNotificationError(createVehicleBrandError.message);
        }
    }, [createVehicleBrandData, createVehicleBrandLoading, createVehicleBrandError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(updateVehicleBrandLoading === false && updateVehicleBrandData) {
            processSuccess(`Marca ${updateVehicleBrandData.updateVehicleBrand.id} actualizada`);
        } else if (updateVehicleBrandError) {
            showNotificationError(updateVehicleBrandError.message);
        }
    }, [updateVehicleBrandData, updateVehicleBrandLoading, updateVehicleBrandError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(deleteVehicleBrandLoading === false && deleteVehicleBrandData) {
            if (deleteVehicleBrandData.deleteVehicleBrand) {
                processSuccess(`Marca ${deleteVehicleBrandData.deleteVehicleBrand} eliminada`);
            } else {
                showNotificationError('Error eliminando la clínica');
            }
        } else if (deleteVehicleBrandError) {
            showNotificationError(deleteVehicleBrandError.message);
        }
    }, [deleteVehicleBrandData, deleteVehicleBrandLoading, deleteVehicleBrandError, processSuccess, showNotificationError]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <Grid container direction="column" className={classes.formContainer}>
                    <Typography variant="subtitle1">Datos de la marca</Typography>
                    <Divider />
                    <Grid container className={classes.section}>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="name"
                                label="Nombre"
                                value={name || ''}
                                onChange={(e)=>{setName(e.target.value)}}
                                required
                                inputProps={{
                                    maxLength: 50,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                type="submit"
                                className={classes.button}
                            >
                                Guardar
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelIcon />}
                                className={classes.button}
                                onClick={closeForm}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        {dataToUpdate &&
                            <Grid item className={classes.deleteButtonField}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => setOpenDeleteDialog(true)}
                                >
                                    Eliminar
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </form>
            <BackdropLoading open={createVehicleBrandLoading || updateVehicleBrandLoading || deleteVehicleBrandLoading} />
            <ConfirmDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                title="Eliminar marca"
                text="¿Está seguro de que quiere eliminar la marca de vehículos?"
                confirmText="Eliminar"
                handleConfirm={handleDeleteButton}
            />
        </>
    );
};

export default VehicleBrandForm;
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Menu,
    MenuItem,
    Avatar
} from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import SendIcon from '@material-ui/icons/Send'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useContext } from 'react'
import TrafilexImage from '../../icons/Icono-trafilex-150-1.png'
import ExpedientForm from '../expedient/ExpedientForm'
import { Link } from 'react-router-dom'
import CustomModal from './CustomModal'
import EmployeeContext from '../../contexts/EmployeeContext'
import Notification from '../common/Notification'
import EmailForm from '../email/EmailForm'
import { useHistory } from 'react-router-dom'

require('dotenv').config()

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    homeButton: {
        marginRight: theme.spacing(3),
        minWidth: '50px',
        maxWidth: '50px',
        height: '50px',
        backgroundSize: '50px',
        backgroundImage: `url(${TrafilexImage})`
    },
    title: {
        flexGrow: 1
    },
    userMenu: {
        marginTop: theme.spacing(4)
    },
    newExpedientButton: {
        marginRight: theme.spacing(2)
    }
}))

const Header = ({ userPicture, logout }) => {
    const [openUserMenu, setOpenUserMenu] = useState(null)
    const [openNewExpedient, setOpenNewExpedient] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationSeverity, setNotificationSeverity] = useState('success')
    const [openEmail, setOpenEmail] = useState(false)

    const classes = useStyles()

    const employee = useContext(EmployeeContext)
    const history = useHistory()

    const userMenuHandleCloseSession = () => {
        setOpenUserMenu(null)
        logout({ returnTo: process.env.REACT_APP_TRAFILEX_URL })
    }

    return (
        <>
            <AppBar position="fixed" color="primary" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Button
                        edge="start"
                        color="inherit"
                        className={classes.homeButton}
                        component={Link}
                        to="/"
                    />
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                    >
                        Trafilex backoffice
                    </Typography>
                    <Button
                        color="inherit"
                        startIcon={<SendIcon>send</SendIcon>}
                        onClick={() => setOpenEmail(true)}
                        className={classes.newExpedientButton}
                    >
                        Enviar email
                    </Button>
                    <Button
                        color="inherit"
                        startIcon={<AddBoxIcon>send</AddBoxIcon>}
                        onClick={() =>
                            history.push('/', { newExpedient: true })
                        }
                        className={classes.newExpedientButton}
                    >
                        Nuevo expediente
                    </Button>
                    <CustomModal
                        open={openNewExpedient}
                        title="Nuevo expediente"
                    >
                        <ExpedientForm
                            closeForm={() => setOpenNewExpedient(false)}
                            openNotification={() => setOpenNotification(true)}
                            setNotificationMessage={setNotificationMessage}
                            setNotificationSeverity={setNotificationSeverity}
                        />
                    </CustomModal>
                    <CustomModal open={openEmail} title="Enviar email">
                        <EmailForm
                            closeForm={() => setOpenEmail(false)}
                            openNotification={() => setOpenNotification(true)}
                            setNotificationMessage={setNotificationMessage}
                            setNotificationSeverity={setNotificationSeverity}
                            toList={[]}
                        />
                    </CustomModal>
                    <Avatar
                        alt={employee.name}
                        src={userPicture}
                        variant="rounded"
                        onClick={(e) => {
                            setOpenUserMenu(e.currentTarget)
                        }}
                    />
                    <Menu
                        id="user-menu"
                        anchorEl={openUserMenu}
                        keepMounted
                        open={Boolean(openUserMenu)}
                        onClose={() => {
                            setOpenUserMenu(null)
                        }}
                        className={classes.userMenu}
                    >
                        <MenuItem>Mi perfil</MenuItem>
                        <MenuItem onClick={userMenuHandleCloseSession}>
                            Cerrar sesión
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
        </>
    )
}

export default Header

import Loading from '../common/Loading';
import { useState, useEffect } from 'react';
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import Error from '../common/Error';
import VehicleForm from './VehicleForm';
import VehicleModelForm from './VehicleModelForm';
import VehicleBrandForm from './VehicleBrandForm';
import CustomTableContainer from '../common/CustomTableContainer';
import CustomModal from '../common/CustomModal';
import Notification from '../common/Notification';


const VEHICLES_QUERY = gql`
    query vehicles {
        vehicles {
            id
            model {
                id
                name
                brand {
                    id
                    name
                }
                type
            }
            plate
        }
    }
`;

const VEHICLE_MODELS_QUERY = gql`
    query vehicleModels {
        vehicleModels {
            id
            name
            brand {
                id
                name
            }
            type
        }
    }
`;

const VEHICLE_BRANDS_QUERY = gql`
    query vehicleBrands {
        vehicleBrands {
            id
            name
        }
    }
`;

const vehicleHeaders = [
    { id: 'id', label: 'ID', numeric: true, width: '5%' },
    { id: 'plate', label: 'Matrícula' },
    { id: 'brand', label: 'Marca' },
    { id: 'model', label: 'Modelo' },
    { id: 'type_name', label: 'Tipo' }
];

const modelHeaders = [
    { id: 'id', label: 'ID', numeric: true, width: '5%' },
    { id: 'brand', label: 'Marca' },
    { id: 'name', label: 'Modelo' },
    { id: 'type_name', label: 'Tipo' }
];

const brandHeaders = [
    { id: 'id', label: 'ID', numeric: true, width: '5%' },
    { id: 'name', label: 'Marca' }
];

const vehicleTypes = {
    'CAR': 'Coche',
    'MOTORBIKE': 'Motocicleta',
    'BIKE': 'Bicicleta',
    'TRUCK': 'Camión'
};

const Vehicles = () => {

    const [vehicles, setVehicles] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [vehicleBrands, setVehicleBrands] = useState([]);
    const { data: vehiclesData, loading: vehiclesLoading, error: vehiclesError, refetch: refetchVehiclesData, networkStatus: vehiclesNetworkStatus } =
        useQuery(VEHICLES_QUERY, { notifyOnNetworkStatusChange: true });
    const { data: vehicleModelsData, loading: vehicleModelsLoading, error: vehicleModelsError, refetch: refetchVehicleModelsData, networkStatus: modelsNetworkStatus } =
        useQuery(VEHICLE_MODELS_QUERY, { notifyOnNetworkStatusChange: true });
    const { data: vehicleBrandsData, loading: vehicleBrandsLoading, error: vehicleBrandsError, refetch: refetchVehicleBrandsData, networkStatus: brandsNetworkStatus } =
        useQuery(VEHICLE_BRANDS_QUERY, { notifyOnNetworkStatusChange: true });
    const [openNewVehicle, setOpenNewVehicle] = useState(false);
    const [openUpdateVehicle, setOpenUpdateVehicle] = useState(false);
    const [vehicleToUpdate, setVehicleToUpdate] = useState({});
    const [openNewVehicleModel, setOpenNewVehicleModel] = useState(false);
    const [openUpdateVehicleModel, setOpenUpdateVehicleModel] = useState(false);
    const [vehicleModelToUpdate, setVehicleModelToUpdate] = useState({});
    const [openNewVehicleBrand, setOpenNewVehicleBrand] = useState(false);
    const [openUpdateVehicleBrand, setOpenUpdateVehicleBrand] = useState(false);
    const [vehicleBrandToUpdate, setVehicleBrandToUpdate] = useState({});
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('success');

    const formatVehicle = (vehicle) => {
        return {
            ...vehicle,
            model_id: vehicle.model?.id,
            model: vehicle.model?.name,
            brand_id: vehicle.model?.brand.id,
            brand: vehicle.model?.brand.name,
            type_name: vehicleTypes[vehicle?.model?.type]
        };
    };

    const formatVehicleModel = (vehicleModel) => {
        return {
            ...vehicleModel,
            type_name: vehicleTypes[vehicleModel.type],
            brand_id: vehicleModel.brand.id,
            brand: vehicleModel.brand.name
        };
    };

    useEffect(() => {
        if (vehiclesLoading === false && vehiclesData && vehiclesNetworkStatus === NetworkStatus.ready) {
            setVehicles(vehiclesData.vehicles.map((vehicle) => {
                return formatVehicle(vehicle);
            }));
        }
    }, [vehiclesLoading, vehiclesData, vehiclesNetworkStatus]);

    useEffect(() => {
        if (vehicleModelsLoading === false && vehicleModelsData && modelsNetworkStatus === NetworkStatus.ready) {
            setVehicleModels(vehicleModelsData.vehicleModels.map((vehicleModel) => {
                return formatVehicleModel(vehicleModel);
            }));
        }
    }, [vehicleModelsLoading, vehicleModelsData, modelsNetworkStatus]);

    useEffect(() => {
        if (vehicleBrandsLoading === false && vehicleBrandsData && brandsNetworkStatus === NetworkStatus.ready) {
            setVehicleBrands(vehicleBrandsData.vehicleBrands);
        }
    }, [vehicleBrandsLoading, vehicleBrandsData, brandsNetworkStatus]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    const handleVehicleRowClick = (event, id) => {
        setVehicleToUpdate(vehicles.filter(vehicle => vehicle.id === id)[0]);
        setOpenUpdateVehicle(true);
    };

    const handleModelRowClick = (event, id) => {
        setVehicleModelToUpdate(vehicleModels.filter(model => model.id === id)[0]);
        setOpenUpdateVehicleModel(true);
    };

    const handleBrandRowClick = (event, id) => {
        setVehicleBrandToUpdate(vehicleBrands.filter(brand => brand.id === id)[0]);
        setOpenUpdateVehicleBrand(true);
    };


    if (vehiclesError || vehicleModelsError || vehicleBrandsError) {
        return <Error error={vehiclesError?.message + vehicleModelsError?.message + vehicleBrandsError?.message} />;
    } else if (!vehicles || !vehicleModels || !vehicleBrands) {
        return <Loading />;
    }

    return (
        <>
            <CustomTableContainer
                title="Vehículos"
                headers={vehicleHeaders}
                rows={vehicles}
                handleRowClick={handleVehicleRowClick}
                handleNew={() => setOpenNewVehicle(true)}
                refresh={refetchVehiclesData}
            />
            <CustomTableContainer
                title="Modelos"
                headers={modelHeaders}
                rows={vehicleModels}
                handleRowClick={handleModelRowClick}
                handleNew={() => setOpenNewVehicleModel(true)}
                refresh={refetchVehicleModelsData}
            />
            <CustomTableContainer
                title="Marcas"
                headers={brandHeaders}
                rows={vehicleBrands}
                handleRowClick={handleBrandRowClick}
                handleNew={() => setOpenNewVehicleBrand(true)}
                refresh={refetchVehicleBrandsData}
            />
            <CustomModal open={openNewVehicle} title="Nuevo vehículo">
                <VehicleForm refreshData={refetchVehiclesData}
                    closeForm={() => setOpenNewVehicle(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <CustomModal open={openUpdateVehicle} title={`Editar vehículo ${vehicleToUpdate.id}`}>
                <VehicleForm refreshData={refetchVehiclesData}
                    closeForm={() => setOpenUpdateVehicle(false)}
                    dataToUpdate={vehicleToUpdate}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <CustomModal open={openNewVehicleModel} title="Nuevo modelo">
                <VehicleModelForm refreshData={refetchVehicleModelsData}
                    closeForm={() => setOpenNewVehicleModel(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <CustomModal open={openUpdateVehicleModel} title={`Editar modelo ${vehicleModelToUpdate.id}`}>
                <VehicleModelForm refreshData={refetchVehicleModelsData}
                    closeForm={() => setOpenUpdateVehicleModel(false)}
                    dataToUpdate={vehicleModelToUpdate}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <CustomModal open={openNewVehicleBrand} title="Nueva marca">
                <VehicleBrandForm refreshData={refetchVehicleBrandsData}
                    closeForm={() => setOpenNewVehicleBrand(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <CustomModal open={openUpdateVehicleBrand} title={`Editar marca ${vehicleToUpdate.id}`}>
                <VehicleBrandForm refreshData={refetchVehicleBrandsData}
                    closeForm={() => setOpenUpdateVehicleBrand(false)}
                    dataToUpdate={vehicleBrandToUpdate}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
        </>
    );
}

export default Vehicles;
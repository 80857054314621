import { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Collapse, IconButton, TableCell, TableRow, Typography, TextField } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { format } from 'date-fns';

const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const ExpedientHistoryRow = ({ row, field, fieldName, fieldRows }) => {
    const classes = useStyles();
    
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{format(row.modification_date, "dd/MM/yyyy HH:mm")}</TableCell>
                <TableCell>{row.modification_employee.first_name} {row.modification_employee.last_name}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                {fieldName}
                            </Typography>
                            <TextField id="history_field"
                                value={row[field] || ''}
                                variant="outlined"
                                multiline
                                rows={fieldRows}
                                disabled
                                style={{ width: '100%' }}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

export default ExpedientHistoryRow;
import { Grid, Typography, Divider, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, gql, useMutation, NetworkStatus } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../common/ConfirmDialog';
import VehicleBrandForm from './VehicleBrandForm';
import CustomModal from '../common/CustomModal';
import BackdropLoading from '../common/BackdropLoading';


const CREATE_MODEL = gql`
    mutation createVehicleModel ($vehicleModel: VehicleModelInput!) {
        createVehicleModel (vehicle_model: $vehicleModel) {
            id
            name
            brand {
                id
                name
            }
            type
        }
    }
`;

const UPDATE_MODEL = gql`
    mutation updateVehicleModel ($id: Int!, $vehicleModel: VehicleModelInput!) {
        updateVehicleModel (id: $id, vehicle_model: $vehicleModel) {
            id
            name
            brand {
                id
                name
            }
            type
        }
    }
`;

const DELETE_MODEL = gql`
    mutation deleteVehicleModel ($id: Int!) {
        deleteVehicleModel (id: $id)
    }
`;

const VEHICLE_BRANDS_QUERY = gql`
    query vehicleBrands {
        vehicleBrands {
            id
            name
        }
    }
`;

const TYPES = [
    { id: 1, value: 'CAR', name: 'Coche' },
    { id: 2, value: 'MOTORBIKE', name: 'Motocicleta' },
    { id: 3, value: 'BIKE', name: 'Bicicleta' },
    { id: 4, value: 'TRUCK', name: 'Camión' }
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1000,
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        overflow: 'auto',
        maxHeight: "90%"
    },
    formContainer: {
        marginTop: theme.spacing(4)
    },
    section: {
        marginBottom: theme.spacing(4)
    },
    descriptionField: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    deleteButtonField: {
        marginTop: theme.spacing(2),
        float: "right"
    }
}));


const VehicleModelForm = ({ refreshData, closeForm, dataToUpdate, openNotification, setNotificationMessage, setNotificationSeverity }) => {
    const classes = useStyles();

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const vehicleModel = {
            name,
            type,
            brand_id: brandId
        };
        if (dataToUpdate) {
            updateVehicleModel({ variables: { id: dataToUpdate.id, vehicleModel: vehicleModel } }).catch(err => {
                showNotificationError(err);
            });
        } else {
            createVehicleModel({ variables: { vehicleModel: vehicleModel } }).catch(err => {
                showNotificationError(err);
            });
        }
    };

    const handleDeleteButton = () => {
        deleteVehicleModel({ variables: { id: dataToUpdate.id } }).catch(err => {
            showNotificationError(err);
            setOpenDeleteDialog(false);
        });
    }

    const processSuccess = useCallback((message) => {
        refreshData();
        closeForm();
        setNotificationMessage(message);
        setNotificationSeverity('success');
        openNotification();
    }, [refreshData, closeForm, setNotificationMessage, setNotificationSeverity, openNotification]);

    const showNotificationError = useCallback((message) => {
        setNotificationMessage(message);
        setNotificationSeverity('error');
        openNotification();
    }, [setNotificationMessage, setNotificationSeverity, openNotification]);

    const [name, setName] = useState(dataToUpdate?.name);
    const [type, setType] = useState(dataToUpdate?.type ?? 'CAR');
    const [brandId, setBrandId] = useState(dataToUpdate?.brand_id);
    const [brands, setBrands] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openNewVehicleBrand, setOpenNewVehicleBrand] = useState(false);

    const [createVehicleModel, { data: createVehicleModelData, loading: createVehicleModelLoading,
        error: createVehicleModelError }] = useMutation(CREATE_MODEL);

    const [updateVehicleModel, { data: updateVehicleModelData, loading: updateVehicleModelLoading,
        error: updateVehicleModelError }] = useMutation(UPDATE_MODEL);

    const [deleteVehicleModel, { data: deleteVehicleModelData, loading: deleteVehicleModelLoading,
        error: deleteVehicleModelError }] = useMutation(DELETE_MODEL);

    const { data: vehicleBrandsData, loading: vehicleBrandsLoading, refetch: vehicleBrandsRefetch, networkStatus } =
        useQuery(VEHICLE_BRANDS_QUERY, { notifyOnNetworkStatusChange: true });


    useEffect(() => {
        if(createVehicleModelLoading === false && createVehicleModelData) {
            processSuccess(`Modelo ${createVehicleModelData.createVehicleModel.id} creado`);
        } else if (createVehicleModelError) {
            showNotificationError(createVehicleModelError.message);
        }
    }, [createVehicleModelData, createVehicleModelLoading, createVehicleModelError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(updateVehicleModelLoading === false && updateVehicleModelData) {
            processSuccess(`Modelo ${updateVehicleModelData.updateVehicleModel.id} actualizado`);
        } else if (updateVehicleModelError) {
            showNotificationError(updateVehicleModelError.message);
        }
    }, [updateVehicleModelData, updateVehicleModelLoading, updateVehicleModelError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(deleteVehicleModelLoading === false && deleteVehicleModelData) {
            if (deleteVehicleModelData.deleteVehicleModel) {
                processSuccess(`Modelo ${deleteVehicleModelData.deleteVehicleModel} eliminado`);
            } else {
                showNotificationError('Error eliminando la clínica');
            }
        } else if (deleteVehicleModelError) {
            showNotificationError(deleteVehicleModelError.message);
        }
    }, [deleteVehicleModelData, deleteVehicleModelLoading, deleteVehicleModelError, processSuccess, showNotificationError]);

    useEffect(() => {
        if (vehicleBrandsLoading === false && vehicleBrandsData && networkStatus === NetworkStatus.ready) {
            setBrands([{ id: null, name: '' }, ...vehicleBrandsData.vehicleBrands]);
        }
    }, [vehicleBrandsLoading, vehicleBrandsData, networkStatus]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <Grid container direction="column" className={classes.formContainer}>
                    <Typography variant="subtitle1">Datos del modelo</Typography>
                    <Divider />
                    <Grid container className={classes.section}>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="name"
                                label="Nombre"
                                value={name || ''}
                                onChange={(e)=>{setName(e.target.value)}}
                                required
                                inputProps={{
                                    maxLength: 100,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="type"
                                select
                                label="Tipo"
                                value={type}
                                onChange={(e) => { setType(e.target.value) }}
                                helperText="Selecciona el género"
                                required
                            >
                                {TYPES.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field} container alignItems="center" spacing={2} justifyContent="flex-start">
                            <Grid item>
                                <TextField
                                    id="brand"
                                    select
                                    label="Marca del modelo"
                                    value={brands.length === 0 ? '' : (brandId || '')}
                                    onChange={(e) => { setBrandId(e.target.value) }}
                                    helperText="Selecciona la marca del modelo"
                                    required
                                >
                                    {brands.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="default"
                                    startIcon={<AddIcon />}
                                    className={classes.button}
                                    onClick={() => setOpenNewVehicleBrand(true)}
                                >
                                    Nueva marca
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                type="submit"
                                className={classes.button}
                            >
                                Guardar
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelIcon />}
                                className={classes.button}
                                onClick={closeForm}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        {dataToUpdate &&
                            <Grid item className={classes.deleteButtonField}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => setOpenDeleteDialog(true)}
                                >
                                    Eliminar
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </form>
            <BackdropLoading open={createVehicleModelLoading || updateVehicleModelLoading || deleteVehicleModelLoading || vehicleBrandsLoading} />
            <ConfirmDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                title="Eliminar modelo"
                text="¿Está seguro de que quiere eliminar el modelo de vehículo?"
                confirmText="Eliminar"
                handleConfirm={handleDeleteButton}
            />
            <CustomModal open={openNewVehicleBrand} title="Nueva marca">
                <VehicleBrandForm refreshData={vehicleBrandsRefetch}
                    closeForm={() => setOpenNewVehicleBrand(false)}
                    openNotification={openNotification}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
        </>
    );
};

export default VehicleModelForm;
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center',
        marginTop: theme.spacing(4)
    }
}));

const Loading = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <CircularProgress />
        </Grid>
    );
}

export default Loading;
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
const IsDoneIconModule = ({ isDone }) => {
  return isDone ? (
    <CheckIcon color="primary"></CheckIcon>
  ) : (
    <CloseIcon color="disabled"></CloseIcon>
  )
}

export default IsDoneIconModule

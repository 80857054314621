import {
    Grid,
    Typography,
    Divider,
    TextField,
    MenuItem,
    IconButton,
    Menu
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, gql, useMutation, NetworkStatus } from '@apollo/client'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useState, useEffect, useContext, useCallback } from 'react'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import Button from '@material-ui/core/Button'
import ClientForm from '../client/ClientForm'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import EmployeeContext from '../../contexts/EmployeeContext'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CustomModal from '../common/CustomModal'
import InsuranceCompanyForm from '../insuranceCompany/InsuranceCompanyForm'
import ClinicForm from '../clinic/ClinicForm'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import NumberFormat from 'react-number-format'
import FastVehicleForm from '../vehicle/FastVehicleForm'
import BackdropLoading from '../common/BackdropLoading'
import IncidentReasonForm from '../incidentReason/IncidentReasonForm'

const PROVINCES_QUERY = gql`
    query provincesByCountry($country_id: Int!) {
        provincesByCountry(country_id: $country_id) {
            id
            name
        }
    }
`

const CITIES_QUERY = gql`
    query citiesByProvince($province_id: Int!) {
        citiesByProvince(province_id: $province_id) {
            id
            name
        }
    }
`

const CLIENTS_QUERY = gql`
    query clients {
        clients {
            id
            first_name
            last_name
            dni
            email
        }
    }
`

const CREATE_EXPEDIENT = gql`
    mutation createExpedient($expedient: ExpedientInput!) {
        createExpedient(expedient: $expedient) {
            id
        }
    }
`

const INSURANCE_COMPANIES_QUERY = gql`
    query insurance_companies {
        insurance_companies {
            id
            name
        }
    }
`

const CLINICS_QUERY = gql`
    query clinics {
        clinics {
            id
            name
        }
    }
`

const INCIDENT_REASONS_QUERY = gql`
    query incident_reasons {
        incident_reasons {
            id
            name
        }
    }
`

const useStyles = makeStyles((theme) => ({
    formContainer: {
        marginTop: theme.spacing(4)
    },
    section: {
        marginBottom: theme.spacing(4)
    },
    descriptionField: {
        width: '100%',
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    }
}))

const COUNTRY_ID = 1
const MAX_CLIENTS = 8
const MAX_VEHICLES = 8
const MAX_AMOUNT_VAL = 9999999999
const peopleAffectedNumber = [...Array(MAX_CLIENTS).keys()].map((i) => i + 1)
const STATUS_NEW = { id: 1, name: 'Nuevo' }
const STATUS_OPORTUNITY = { id: 0, name: 'Oportunidad' }
const STATUS_LIST = [STATUS_OPORTUNITY, STATUS_NEW]
const CONTACT_CHANNEL_LIST = [
    { id: 'PHONE', value: 'Teléfono' },
    { id: 'EMAIL', value: 'Email' },
    { id: 'FACE-TO-FACE', value: 'Presencial' },
    { id: 'FORM', value: 'Formulario web' }
]

const DEAL = [
    { id: 1, value: 'FRIENDLY', name: 'Parte amistoso' },
    { id: 2, value: 'ATTESTATION', name: 'Atestado' }
]

const ExpedientForm = ({
    refreshData,
    closeForm,
    openNotification,
    setNotificationMessage,
    setNotificationSeverity
}) => {
    const classes = useStyles()

    const employee = useContext(EmployeeContext)

    const handleCreateExpedient = (e) => {
        e.preventDefault()
        const expedient = {
            created_at: createdAt,
            created_by: employee.id,
            title: title,
            description: description,
            incident_date: selectedDate,
            affected_people: affectedPeople,
            clients: clientsSelected.map((client) => {
                return {
                    client_id: client.client.id,
                    vehicle_id: client.vehicle?.id,
                    insurance_company_id: client.insurance_company?.id,
                    clinic_id: client.clinic?.id
                }
            }),
            opposite_vehicles: vehiclesSelected.map((vehicle) => {
                return {
                    vehicle_id: vehicle.vehicle.id,
                    insurance_company_id: vehicle.insurance_company?.id
                }
            }),
            employee_id: employee.id,
            status_id: status,
            city_id: city,
            deal: deal !== '' ? deal : null,
            estimated_amount: estimatedAmount,
            referred_contact: contact,
            postal_code: postalCode,
            address: address,
            incident_reason_id:
                incidentReason !== '' ? incidentReason?.id : null,
            contact_channel: contactChannel
        }
        createExpedient({ variables: { expedient: expedient } })
    }

    const [createdAt, setCreatedAt] = useState(new Date())
    const [description, setDescription] = useState()
    const [title, setTitle] = useState('')
    const [affectedPeople, setAffectedPeople] = useState(1)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [deal, setDeal] = useState('FRIENDLY')
    const [contact, setContact] = useState()
    const [postalCode, setPostalCode] = useState()
    const [address, setAddress] = useState()
    const [estimatedAmount, setEstimatedAmount] = useState()
    const [province, setProvince] = useState('')
    const [provinces, setProvinces] = useState([])
    const [city, setCity] = useState()
    const [cities, setCities] = useState([])
    const [clientsSelected, setClientsSelected] = useState([])
    const [clients, setClients] = useState([])
    const [clientsNumber, setClientsNumber] = useState(1)
    const [openNewClient, setOpenNewClient] = useState(false)
    const [vehiclesSelected, setVehiclesSelected] = useState([])
    const [vehiclesNumber, setVehiclesNumber] = useState(0)
    const [insuranceCompanies, setInsuranceCompanies] = useState([])
    const [openNewInsuranceCompany, setOpenNewInsuranceCompany] =
        useState(false)
    const [clinics, setClinics] = useState([])
    const [openNewClinic, setOpenNewClinic] = useState(false)
    const [openClientActionsMenu, setOpenClientActionsMenu] = useState(null)
    const [openOppositeVehicleActionsMenu, setOpenOppositeVehicleActionsMenu] =
        useState(null)
    const [incidentReason, setIncidentReason] = useState('')
    const [incidentReasons, setIncidentReasons] = useState([])
    const [openExpedientActionsMenu, setOpenExpedientActionsMenu] =
        useState(null)
    const [openNewIncidentReason, setOpenNewIncidentReason] = useState(false)
    const [status, setStatus] = useState(STATUS_OPORTUNITY.id)
    const [contactChannel, setContactChannel] = useState(
        CONTACT_CHANNEL_LIST[0].id
    )

    const { data: provincesData, loading: provincesLoading } = useQuery(
        PROVINCES_QUERY,
        { variables: { country_id: COUNTRY_ID } }
    )

    const { data: citiesData, loading: citiesLoading } = useQuery(
        CITIES_QUERY,
        {
            skip: province === '',
            variables: { province_id: province }
        }
    )

    const {
        data: clientsData,
        loading: clientsLoading,
        refetch: refetchClientsData,
        networkStatus: expClientsNetworkStatus
    } = useQuery(CLIENTS_QUERY, { notifyOnNetworkStatusChange: true })

    const {
        data: insuranceCompanyData,
        loading: insuranceCompanyLoading,
        refetch: refetchInsuranceCompanyData,
        networkStatus: insuranceCompaniesNetworkStatus
    } = useQuery(INSURANCE_COMPANIES_QUERY, {
        notifyOnNetworkStatusChange: true
    })

    const {
        data: clinicsData,
        loading: clinicsLoading,
        refetch: refetchClinicsData,
        networkStatus: clinicsNetworkStatus
    } = useQuery(CLINICS_QUERY, { notifyOnNetworkStatusChange: true })

    const {
        data: incidentReasonsData,
        loading: incidentReasonsLoading,
        refetch: refetchIncidentReasonsData,
        networkStatus: incidentReasonsNetworkStatus
    } = useQuery(INCIDENT_REASONS_QUERY, { notifyOnNetworkStatusChange: true })

    const processSuccess = useCallback(
        (message) => {
            refreshData()
            closeForm()
            setNotificationMessage(message)
            setNotificationSeverity('success')
            openNotification()
        },
        [
            refreshData,
            closeForm,
            setNotificationMessage,
            setNotificationSeverity,
            openNotification
        ]
    )

    const showNotificationError = useCallback(
        (message) => {
            setNotificationMessage(message)
            setNotificationSeverity('error')
            openNotification()
        },
        [setNotificationMessage, setNotificationSeverity, openNotification]
    )

    useEffect(() => {
        if (provincesLoading === false && provincesData) {
            setProvinces(provincesData.provincesByCountry)
        }
    }, [provincesLoading, provincesData])

    useEffect(() => {
        if (citiesLoading === false && citiesData) {
            setCities(citiesData.citiesByProvince)
        }
    }, [citiesLoading, citiesData])

    useEffect(() => {
        if (
            clientsLoading === false &&
            clientsData &&
            expClientsNetworkStatus === NetworkStatus.ready
        ) {
            setClients(clientsData.clients)
        }
    }, [clientsLoading, clientsData, expClientsNetworkStatus])

    useEffect(() => {
        if (
            insuranceCompanyLoading === false &&
            insuranceCompanyData &&
            insuranceCompaniesNetworkStatus === NetworkStatus.ready
        ) {
            setInsuranceCompanies(insuranceCompanyData.insurance_companies)
        }
    }, [
        insuranceCompanyLoading,
        insuranceCompanyData,
        insuranceCompaniesNetworkStatus
    ])

    useEffect(() => {
        if (
            clinicsLoading === false &&
            clinicsData &&
            clinicsNetworkStatus === NetworkStatus.ready
        ) {
            setClinics(clinicsData.clinics)
        }
    }, [clinicsLoading, clinicsData, clinicsNetworkStatus])

    useEffect(() => {
        if (
            incidentReasonsLoading === false &&
            incidentReasonsData &&
            incidentReasonsNetworkStatus === NetworkStatus.ready
        ) {
            setIncidentReasons(incidentReasonsData.incident_reasons)
        }
    }, [
        incidentReasonsLoading,
        incidentReasonsData,
        incidentReasonsNetworkStatus
    ])

    //cleanup
    useEffect(() => {
        return () => {}
    }, [])

    const [
        createExpedient,
        {
            data: createExpedientData,
            loading: createExpedientLoading,
            error: createExpedientError
        }
    ] = useMutation(CREATE_EXPEDIENT)

    useEffect(() => {
        if (createExpedientLoading === false && createExpedientData) {
            processSuccess(
                `Expediente ${createExpedientData.createExpedient.id} creado`
            )
        } else if (createExpedientError) {
            showNotificationError(createExpedientError.message)
        }
    }, [
        createExpedientLoading,
        createExpedientData,
        createExpedientError,
        processSuccess,
        showNotificationError
    ])

    const addClientToClientsSelected = (client, index) => {
        const newClientsSelected = clientsSelected.slice()
        newClientsSelected[index] = {
            ...newClientsSelected[index],
            client: client
        }
        setClientsSelected(newClientsSelected)
    }

    const addVehicleToClientsSelected = (vehicle, index) => {
        const newClientsSelected = clientsSelected.slice()
        newClientsSelected[index] = {
            ...newClientsSelected[index],
            vehicle: vehicle
        }
        setClientsSelected(newClientsSelected)
    }

    const addInsuranceCompanyToClientsSelected = (insuranceCompany, index) => {
        const newClientsSelected = clientsSelected.slice()
        newClientsSelected[index] = {
            ...newClientsSelected[index],
            insurance_company: insuranceCompany
        }
        setClientsSelected(newClientsSelected)
    }

    const addClinicToClientsSelected = (clinic, index) => {
        const newClientsSelected = clientsSelected.slice()
        newClientsSelected[index] = {
            ...newClientsSelected[index],
            clinic: clinic
        }
        setClientsSelected(newClientsSelected)
    }

    const removeClient = () => {
        if (clientsNumber > 1) {
            if (clientsSelected.length === clientsNumber) {
                const newClientsSelected = clientsSelected.slice()
                newClientsSelected.pop()
                setClientsSelected(newClientsSelected)
            }
            setClientsNumber(clientsNumber - 1)
        }
    }

    const addClient = () => {
        if (clientsNumber < MAX_CLIENTS) {
            setClientsNumber(clientsNumber + 1)
        }
    }

    const addVehicleToVehiclesSelected = (vehicle, index) => {
        const newVehiclesSelected = vehiclesSelected.slice()
        newVehiclesSelected[index] = {
            ...newVehiclesSelected[index],
            vehicle: vehicle
        }
        setVehiclesSelected(newVehiclesSelected)
    }

    const addInsuranceCompanyToVehiclesSelected = (insuranceCompany, index) => {
        const newVehiclesSelected = vehiclesSelected.slice()
        newVehiclesSelected[index] = {
            ...newVehiclesSelected[index],
            insurance_company: insuranceCompany
        }
        setVehiclesSelected(newVehiclesSelected)
    }

    const removeVehicle = () => {
        if (vehiclesNumber > 0) {
            if (vehiclesSelected.length === vehiclesNumber) {
                const newVehiclesSelected = vehiclesSelected.slice()
                newVehiclesSelected.pop()
                setVehiclesSelected(newVehiclesSelected)
            }
            setVehiclesNumber(vehiclesNumber - 1)
        }
    }

    const addVehicle = () => {
        if (vehiclesNumber < MAX_VEHICLES) {
            setVehiclesNumber(vehiclesNumber + 1)
        }
    }

    const changeProvince = (e) => {
        setCity('')
        setProvince(e.target.value)
    }

    const handleOpenNewClient = () => {
        setOpenNewClient(true)
        setOpenClientActionsMenu(null)
    }

    const handleOpenNewInsuranceCompany = () => {
        setOpenNewInsuranceCompany(true)
        setOpenClientActionsMenu(null)
    }

    const handleOpenNewClinic = () => {
        setOpenNewClinic(true)
        setOpenClientActionsMenu(null)
    }

    const handleOpenNewIncidentReason = () => {
        setOpenNewIncidentReason(true)
        setOpenExpedientActionsMenu(null)
    }

    const isClientEmailDuplicated = (email, id) => {
        return (
            clients.filter(
                (client) =>
                    client.email &&
                    client.email.toUpperCase() === email.toUpperCase() &&
                    client.id !== id
            ).length > 0
        )
    }

    return (
        <>
            <form
                onSubmit={handleCreateExpedient}
                onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
            >
                <Grid
                    container
                    direction="column"
                    className={classes.formContainer}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="subtitle1">
                                Datos del expediente
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                aria-label="Acciones"
                                onClick={(e) =>
                                    setOpenExpedientActionsMenu(e.currentTarget)
                                }
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="expedients-actions-menu"
                                anchorEl={openExpedientActionsMenu}
                                keepMounted
                                open={Boolean(openExpedientActionsMenu)}
                                onClose={() =>
                                    setOpenExpedientActionsMenu(null)
                                }
                            >
                                <MenuItem onClick={handleOpenNewIncidentReason}>
                                    Nuevo motivo de accidente
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.section}>
                        <Grid item xs={12}>
                            <TextField
                                id="title"
                                label="Título"
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                }}
                                required
                                inputProps={{
                                    maxLength: 50
                                }}
                                style={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="status"
                                select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                style={{
                                    width: '100%'
                                }}
                                required
                                variant="outlined"
                                label="Estado"
                            >
                                {STATUS_LIST.map((status) => (
                                    <MenuItem key={status.id} value={status.id}>
                                        {status.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <TextField
                                id="contact_channel"
                                select
                                value={contactChannel}
                                onChange={(e) =>
                                    setContactChannel(e.target.value)
                                }
                                style={{
                                    width: '100%'
                                }}
                                required
                                variant="outlined"
                                label="Canal de contacto"
                            >
                                {CONTACT_CHANNEL_LIST.map((contact_channel) => (
                                    <MenuItem
                                        key={contact_channel.id}
                                        value={contact_channel.id}
                                    >
                                        {contact_channel.value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description"
                                label="Descripción"
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value)
                                }}
                                variant="outlined"
                                multiline
                                rows={15}
                                className={classes.descriptionField}
                                inputProps={{
                                    maxLength: 5000
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.stopPropagation()
                                    }
                                }}
                            />
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} sm={6} className={classes.field}>
                                <DateTimePicker
                                    id="created_at"
                                    label="Fecha de alta"
                                    ampm={false}
                                    disableFuture
                                    value={createdAt}
                                    onChange={(date) => {
                                        setCreatedAt(date)
                                    }}
                                    helperText="Selecciona la fecha"
                                    required
                                    format="dd/MM/yyyy HH:mm"
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} sm={6} className={classes.field}>
                                <DateTimePicker
                                    ampm={false}
                                    id="incident_date"
                                    label="Fecha del suceso"
                                    disableFuture
                                    value={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date)
                                    }}
                                    helperText="Selecciona la fecha"
                                    required
                                    format="dd/MM/yyyy HH:mm"
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="province"
                                select
                                label="Provincia"
                                value={
                                    provinces.length === 0 ? '' : province || ''
                                }
                                onChange={changeProvince}
                                helperText="Selecciona la provincia"
                                required={status === 1}
                            >
                                {provinces.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="city"
                                select
                                label="Ciudad"
                                value={cities.length === 0 ? '' : city || ''}
                                onChange={(e) => {
                                    setCity(e.target.value)
                                }}
                                helperText="Selecciona la ciudad"
                                required={status === 1}
                            >
                                {cities.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="address"
                                label="Dirección"
                                value={address || ''}
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                }}
                                helperText="Selecciona la dirección"
                                style={{
                                    width: 400
                                }}
                                inputProps={{
                                    maxLength: 255
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <NumberFormat
                                customInput={TextField}
                                id="postal_code"
                                label="Código postal"
                                value={postalCode || ''}
                                onValueChange={(value) => {
                                    setPostalCode(value.floatValue)
                                }}
                                decimalScale="0"
                                isAllowed={({ floatValue }) =>
                                    floatValue == null || floatValue <= 99999
                                }
                                helperText="Código postal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="affected_people"
                                select
                                label="Personas implicadas"
                                value={affectedPeople}
                                onChange={(e) => {
                                    setAffectedPeople(e.target.value)
                                }}
                                helperText="Selecciona el número de personas implicadas"
                                required
                            >
                                {peopleAffectedNumber.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="deal"
                                select
                                label="Tipo de parte"
                                value={deal}
                                onChange={(e) => {
                                    setDeal(e.target.value)
                                }}
                                helperText="Selecciona el tipo de parte"
                            >
                                {DEAL.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="referred_contact"
                                label="Contacto"
                                value={contact || ''}
                                onChange={(e) => {
                                    setContact(e.target.value)
                                }}
                                helperText="Fuente del expediente"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="incident_reason"
                                select
                                label="Motivo del accidente"
                                value={
                                    incidentReasons.length === 0
                                        ? ''
                                        : incidentReason || ''
                                }
                                onChange={(e) => {
                                    setIncidentReason(e.target.value)
                                }}
                                helperText="Selecciona el motivo del accidente"
                            >
                                <MenuItem key={'empty'} value={null} />
                                {incidentReasons.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <NumberFormat
                                customInput={TextField}
                                id="estimated_amount"
                                label="Indemnización estimada"
                                helperText="Indemnización estimada"
                                value={estimatedAmount || ''}
                                onValueChange={(value) => {
                                    setEstimatedAmount(value.floatValue)
                                }}
                                suffix="€"
                                thousandSeparator={true}
                                decimalScale="2"
                                isAllowed={({ floatValue }) =>
                                    floatValue == null ||
                                    floatValue <= MAX_AMOUNT_VAL
                                }
                                style={{ width: 200 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="subtitle1">
                                Clientes
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                aria-label="Acciones"
                                onClick={(e) =>
                                    setOpenClientActionsMenu(e.currentTarget)
                                }
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="client-actions-menu"
                                anchorEl={openClientActionsMenu}
                                keepMounted
                                open={Boolean(openClientActionsMenu)}
                                onClose={() => setOpenClientActionsMenu(null)}
                            >
                                <MenuItem onClick={handleOpenNewClient}>
                                    Nuevo cliente
                                </MenuItem>
                                <MenuItem
                                    onClick={handleOpenNewInsuranceCompany}
                                >
                                    Nueva compañía de seguros
                                </MenuItem>
                                <MenuItem onClick={handleOpenNewClinic}>
                                    Nueva clínica
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.section}>
                        {[...Array(clientsNumber).keys()].map(
                            (clientNumber) => (
                                <Grid
                                    container
                                    key={`client-grid-${clientNumber}`}
                                    style={{ marginBottom: 40 }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.field}
                                    >
                                        <Typography variant="h6">{`Cliente ${
                                            clientNumber + 1
                                        }`}</Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.field}
                                    >
                                        <Autocomplete
                                            id={`client-${clientNumber}`}
                                            required
                                            options={clients}
                                            getOptionLabel={(client) =>
                                                client.id
                                                    ? `${client.first_name} ${client.last_name}`
                                                    : ''
                                            }
                                            getOptionSelected={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            style={{ width: 600 }}
                                            value={
                                                clientsSelected[clientNumber]
                                                    ?.client || null
                                            }
                                            onChange={(event, newValue) =>
                                                addClientToClientsSelected(
                                                    newValue,
                                                    clientNumber
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`Cliente ${
                                                        clientNumber + 1
                                                    }`}
                                                    required={status === 1}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <FastVehicleForm
                                        index={clientNumber}
                                        addVehicle={addVehicleToClientsSelected}
                                        showNotificationError={
                                            showNotificationError
                                        }
                                    />
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        className={classes.field}
                                    >
                                        <Autocomplete
                                            id={`insurance-${clientNumber}`}
                                            options={insuranceCompanies}
                                            getOptionLabel={(
                                                insuranceCompany
                                            ) =>
                                                insuranceCompany.id
                                                    ? insuranceCompany.name
                                                    : ''
                                            }
                                            getOptionSelected={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            style={{ width: 300 }}
                                            value={
                                                clientsSelected[clientNumber]
                                                    ?.insurance_company || null
                                            }
                                            onChange={(event, newValue) =>
                                                addInsuranceCompanyToClientsSelected(
                                                    newValue,
                                                    clientNumber
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Compañía de seguros"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={3}
                                        className={classes.field}
                                    >
                                        <Autocomplete
                                            id={`clinic-${clientNumber}`}
                                            options={clinics}
                                            getOptionLabel={(clinic) =>
                                                clinic.id ? clinic.name : ''
                                            }
                                            getOptionSelected={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            style={{ width: 300 }}
                                            value={
                                                clientsSelected[clientNumber]
                                                    ?.clinic || null
                                            }
                                            onChange={(event, newValue) =>
                                                addClinicToClientsSelected(
                                                    newValue,
                                                    clientNumber
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Clínica"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        )}
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-start"
                        className={classes.section}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<AddCircleIcon />}
                                className={classes.button}
                                onClick={addClient}
                            >
                                Añadir cliente
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<RemoveCircleIcon />}
                                className={classes.button}
                                onClick={removeClient}
                            >
                                Quitar cliente
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="subtitle1">
                                Vehículos contrarios
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                aria-label="Acciones"
                                onClick={(e) =>
                                    setOpenOppositeVehicleActionsMenu(
                                        e.currentTarget
                                    )
                                }
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="opposite-actions-menu"
                                anchorEl={openOppositeVehicleActionsMenu}
                                keepMounted
                                open={Boolean(openOppositeVehicleActionsMenu)}
                                onClose={() =>
                                    setOpenOppositeVehicleActionsMenu(null)
                                }
                            >
                                <MenuItem
                                    onClick={handleOpenNewInsuranceCompany}
                                >
                                    Nueva compañía de seguros
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.section}>
                        {[...Array(vehiclesNumber).keys()].map(
                            (vehicleNumber) => (
                                <Grid
                                    container
                                    key={`opposite-grid-${vehicleNumber}`}
                                    style={{ marginBottom: 40 }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.field}
                                    >
                                        <Typography variant="h6">{`Vehículo contrario ${
                                            vehicleNumber + 1
                                        }`}</Typography>
                                        <Divider />
                                    </Grid>
                                    <FastVehicleForm
                                        index={vehicleNumber}
                                        addVehicle={
                                            addVehicleToVehiclesSelected
                                        }
                                        showNotificationError={
                                            showNotificationError
                                        }
                                        plateRequired={true}
                                    />
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        className={classes.field}
                                    >
                                        <Autocomplete
                                            id={`insurance-${vehicleNumber}`}
                                            options={insuranceCompanies}
                                            getOptionLabel={(
                                                insuranceCompany
                                            ) =>
                                                insuranceCompany.id
                                                    ? insuranceCompany.name
                                                    : ''
                                            }
                                            getOptionSelected={(
                                                option,
                                                value
                                            ) => option.id === value.id}
                                            style={{ width: 400 }}
                                            value={
                                                insuranceCompanies.length === 0
                                                    ? ''
                                                    : vehiclesSelected[
                                                          vehicleNumber
                                                      ]?.insurance_company ||
                                                      null
                                            }
                                            onChange={(event, newValue) =>
                                                addInsuranceCompanyToVehiclesSelected(
                                                    newValue,
                                                    vehicleNumber
                                                )
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Compañía de seguros"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        )}
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-start"
                        className={classes.section}
                    >
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<AddCircleIcon />}
                                className={classes.button}
                                onClick={addVehicle}
                            >
                                Añadir vehículo
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<RemoveCircleIcon />}
                                className={classes.button}
                                onClick={removeVehicle}
                            >
                                Quitar vehículo
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start">
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                type="submit"
                                className={classes.button}
                            >
                                Guardar
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelIcon />}
                                className={classes.button}
                                onClick={closeForm}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <BackdropLoading
                open={
                    provincesLoading ||
                    citiesLoading ||
                    insuranceCompanyLoading ||
                    clinicsLoading ||
                    clientsLoading ||
                    createExpedientLoading
                }
            />
            <CustomModal open={openNewClient} title="Nuevo cliente">
                <ClientForm
                    refreshData={refetchClientsData}
                    closeForm={() => setOpenNewClient(false)}
                    openNotification={openNotification}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                    isEmailDuplicated={isClientEmailDuplicated}
                />
            </CustomModal>
            <CustomModal
                open={openNewInsuranceCompany}
                title="Nueva compañía de seguros"
            >
                <InsuranceCompanyForm
                    refreshData={refetchInsuranceCompanyData}
                    closeForm={() => setOpenNewInsuranceCompany(false)}
                    openNotification={openNotification}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                />
            </CustomModal>
            <CustomModal open={openNewClinic} title="Nueva clínica">
                <ClinicForm
                    refreshData={refetchClinicsData}
                    closeForm={() => setOpenNewClinic(false)}
                    openNotification={openNotification}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                />
            </CustomModal>
            <CustomModal
                open={openNewIncidentReason}
                title="Nuevo motivo de accidente"
            >
                <IncidentReasonForm
                    refreshData={refetchIncidentReasonsData}
                    closeForm={() => setOpenNewIncidentReason(false)}
                    openNotification={openNotification}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                />
            </CustomModal>
        </>
    )
}

export default ExpedientForm

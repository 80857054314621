import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpedientHistoryRow from './ExpedientHistoryRow';


const ExpedientHistoryTable = ({ historyRows, field, fieldName }) => {

    const arrayUniqueByField = historyRows.filter((e, i) => historyRows.findIndex(a => a[field] === e[field]) === i && e[field] !== null && e[field] !== '');

    return (
        <TableContainer component={Paper}>
            <Table aria-label="history table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Fecha del cambio</TableCell>
                        <TableCell>Empleado</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {arrayUniqueByField.map((row, index) => 
                        <ExpedientHistoryRow row={row} field={field} fieldName={fieldName} fieldRows={15} key={`history_row_${index}`}/>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ExpedientHistoryTable;
import {
    Divider,
    Typography,
    TextField,
    Grid,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FastVehicleForm from '../../vehicle/FastVehicleForm'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
    section: {
        marginBottom: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    }
}))

const ExpedientDetailsClientSection = ({
    clientsNumber,
    clients,
    clientsSelected,
    addClientToClientsSelected,
    addVehicleToClientsSelected,
    showNotificationError,
    setOpenLoading,
    insuranceCompanies,
    addInsuranceCompanyToClientsSelected,
    clinics,
    addClinicToClientsSelected,
    addClient,
    removeClient,
    addStartTreatmentDateToClientsSelected
}) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Accordion style={{ border: '1px solid #D7D2D1' }}>
                <AccordionSummary
                    style={{ borderBottom: '1px solid #D7D2D1' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="clients-content"
                    id="clients-header"
                >
                    <Typography variant="h6">Clientes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid container item>
                            {[...Array(clientsNumber).keys()].map(
                                (clientNumber) => (
                                    <Grid
                                        container
                                        key={`client-grid-${clientNumber}`}
                                        style={{ marginBottom: 40 }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="h6">{`Cliente ${
                                                clientNumber + 1
                                            }`}</Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.field}
                                        >
                                            <Autocomplete
                                                id={`client-${clientNumber}`}
                                                required
                                                options={clients}
                                                getOptionLabel={(client) =>
                                                    client.id
                                                        ? `${client.first_name} ${client.last_name}`
                                                        : ''
                                                }
                                                getOptionSelected={(
                                                    option,
                                                    value
                                                ) => option.id === value.id}
                                                style={{ width: '80%' }}
                                                value={
                                                    clients.length === 0
                                                        ? ''
                                                        : clientsSelected[
                                                              clientNumber
                                                          ]?.client || null
                                                }
                                                onChange={(event, newValue) =>
                                                    addClientToClientsSelected(
                                                        newValue,
                                                        clientNumber
                                                    )
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`Cliente ${
                                                            clientNumber + 1
                                                        }`}
                                                        required
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <FastVehicleForm
                                            index={clientNumber}
                                            addVehicle={
                                                addVehicleToClientsSelected
                                            }
                                            showNotificationError={
                                                showNotificationError
                                            }
                                            setOpenLoading={setOpenLoading}
                                            vehicleIn={
                                                clientsSelected[clientNumber]
                                                    ?.vehicle
                                            }
                                            brandIn={
                                                clientsSelected[clientNumber]
                                                    ?.vehicle?.model?.brand
                                            }
                                            modelIn={
                                                clientsSelected[clientNumber]
                                                    ?.vehicle?.model
                                            }
                                        />
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.field}
                                            >
                                                <Autocomplete
                                                    id={`insurance-${clientNumber}`}
                                                    options={insuranceCompanies}
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    getOptionLabel={(
                                                        insuranceCompany
                                                    ) =>
                                                        insuranceCompany.id
                                                            ? insuranceCompany.name
                                                            : ''
                                                    }
                                                    style={{ width: 600 }}
                                                    value={
                                                        insuranceCompanies.length ===
                                                        0
                                                            ? ''
                                                            : clientsSelected[
                                                                  clientNumber
                                                              ]
                                                                  ?.insurance_company ||
                                                              null
                                                    }
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) =>
                                                        addInsuranceCompanyToClientsSelected(
                                                            newValue,
                                                            clientNumber
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Compañía de seguros"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid
                                                item
                                                md={12}
                                                lg={6}
                                                className={classes.field}
                                            >
                                                <Autocomplete
                                                    id={`clinic-${clientNumber}`}
                                                    options={clinics}
                                                    getOptionLabel={(clinic) =>
                                                        clinic.id
                                                            ? clinic.name
                                                            : ''
                                                    }
                                                    getOptionSelected={(
                                                        option,
                                                        value
                                                    ) => option.id === value.id}
                                                    style={{ width: 400 }}
                                                    value={
                                                        clinics.length === 0
                                                            ? ''
                                                            : clientsSelected[
                                                                  clientNumber
                                                              ]?.clinic || null
                                                    }
                                                    onChange={(
                                                        event,
                                                        newValue
                                                    ) =>
                                                        addClinicToClientsSelected(
                                                            newValue,
                                                            clientNumber
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Clínica"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                lg={6}
                                                className={classes.field}
                                            >
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                >
                                                    <DatePicker
                                                        ampm={false}
                                                        id="start_treatment_date"
                                                        label="Fecha de comienzo del tratamiento"
                                                        value={
                                                            clientsSelected[
                                                                clientNumber
                                                            ]
                                                                ?.start_treatment_date ||
                                                            null
                                                        }
                                                        onChange={(date) =>
                                                            addStartTreatmentDateToClientsSelected(
                                                                date,
                                                                clientNumber
                                                            )
                                                        }
                                                        format="dd/MM/yyyy"
                                                        style={{ width: 300 }}
                                                        clearable
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            justifyContent="flex-start"
                            className={classes.section}
                        >
                            <Grid item className={classes.field}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    startIcon={<AddCircleIcon />}
                                    className={classes.button}
                                    onClick={addClient}
                                >
                                    Añadir cliente
                                </Button>
                                <Button
                                    variant="contained"
                                    color="default"
                                    startIcon={<RemoveCircleIcon />}
                                    className={classes.button}
                                    onClick={removeClient}
                                >
                                    Quitar cliente
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ExpedientDetailsClientSection

import Loading from '../common/Loading';
import { useState, useEffect } from 'react';
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import Error from '../common/Error';
import InsuranceCompanyForm from './InsuranceCompanyForm';
import CustomTableContainer from '../common/CustomTableContainer';
import CustomModal from '../common/CustomModal';
import Notification from '../common/Notification';


const INSURANCE_COMPANIES_QUERY = gql`
    query insurance_companies {
        insurance_companies {
            id
            name
            email
            phone
        }
    }
`;

const headers = [
    { id: 'id', label: 'ID', numeric: true, width: '5%' },
    { id: 'name', label: 'Nombre' },
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Teléfono' }
];

const InsuranceCompany = () => {

    const [insuranceCompanies, setInsuranceCompanies] = useState([]);
    const { data: insuranceCompaniesData, loading: insuranceCompaniesLoading, error: insuranceCompaniesError, refetch: refetchInsuranceCompaniesData, networkStatus }
        = useQuery(INSURANCE_COMPANIES_QUERY, { notifyOnNetworkStatusChange: true });
    const [openNewInsuranceCompany, setOpenNewInsuranceCompany] = useState(false);
    const [openUpdateInsuranceCompany, setOpenUpdateInsuranceCompany] = useState(false);
    const [insuranceCompanyToUpdate, setInsuranceCompanyToUpdate] = useState({});
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('success');

    const formatInsuranceCompany = (insuranceCompany) => {
        return {
            ...insuranceCompany
        };
    };

    useEffect(() => {
        if (insuranceCompaniesLoading === false && insuranceCompaniesData && networkStatus === NetworkStatus.ready) {
            setInsuranceCompanies(insuranceCompaniesData.insurance_companies.map((insuranceCompany) => {
                return formatInsuranceCompany(insuranceCompany);
            }));
        }
    }, [insuranceCompaniesLoading, insuranceCompaniesData, networkStatus]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    const handleRowClick = (event, id) => {
        setInsuranceCompanyToUpdate(insuranceCompanies.filter(insuranceCompany => insuranceCompany.id === id)[0]);
        setOpenUpdateInsuranceCompany(true);
    };


    if (insuranceCompaniesError) {
        return <Error error={insuranceCompaniesError.message} />;
    } else if (!insuranceCompanies) {
        return <Loading />;
    }

    return (
        <>
            <CustomTableContainer
                title="Compañías de seguros"
                headers={headers}
                rows={insuranceCompanies}
                handleRowClick={handleRowClick}
                handleNew={() => setOpenNewInsuranceCompany(true)}
                refresh={refetchInsuranceCompaniesData}
            />
            <CustomModal open={openNewInsuranceCompany} title="Nueva compañía de seguros">
                <InsuranceCompanyForm refreshData={refetchInsuranceCompaniesData}
                    closeForm={() => setOpenNewInsuranceCompany(false)}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <CustomModal open={openUpdateInsuranceCompany} title={`Editar compañía de seguros ${insuranceCompanyToUpdate.id}`}>
                <InsuranceCompanyForm refreshData={refetchInsuranceCompaniesData}
                    closeForm={() => setOpenUpdateInsuranceCompany(false)}
                    dataToUpdate={insuranceCompanyToUpdate}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity} />
            </CustomModal>
            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
        </>
    );
}

export default InsuranceCompany;
import Header from './Header'
import Menu from './Menu'
import Dashboard from '../dashboard/Dashboard'
import Expedients from '../expedient/Expedients'
import Clients from '../client/Clients'
import Clinics from '../clinic/Clinics'
import InsuranceCompanies from '../insuranceCompany/InsuranceCompany'
import Vehicles from '../vehicle/Vehicles'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Route, Switch } from 'react-router-dom'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        overflow: 'auto',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(7),
        width: '100%',
        marginTop: theme.spacing(2)
    }
}))

const Backoffice = ({ userPicture, logout }) => {
    const classes = useStyles()

    const [expedientRowClicked, setExpedientRowClicked] = useState(null)

    return (
        <div className={classes.root}>
            <Header userPicture={userPicture} logout={logout} />
            <Menu closeExpedientDetail={() => setExpedientRowClicked(null)} />
            <main className={classes.content}>
                <Grid container>
                    <Switch>
                        <Route path="/clientes">
                            <Clients />
                        </Route>
                        <Route path="/clinicas">
                            <Clinics />
                        </Route>
                        <Route path="/aseguradoras">
                            <InsuranceCompanies />
                        </Route>
                        <Route path="/vehiculos">
                            <Vehicles />
                        </Route>
                        <Route path="/dashboard">
                            <Dashboard />
                        </Route>
                        <Route path="/">
                            <Expedients
                                expedientSelected={expedientRowClicked}
                                setExpedientSelected={setExpedientRowClicked}
                            />
                        </Route>
                    </Switch>
                </Grid>
            </main>
        </div>
    )
}

export default Backoffice

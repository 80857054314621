import { Grid, TextField, Button, Chip, Typography } from '@material-ui/core';
import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/client';
import BackdropLoading from '../common/BackdropLoading';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import EmployeeContext from '../../contexts/EmployeeContext';
import isEmail from 'validator/lib/isEmail';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const GENERIC_TEMPLATE_ID = 4471303;
const FROM_NAME = 'Trafilex';

const SEND_EMAIL = gql`
    mutation sendEmailFromTemplate ($emailInput: EmailTemplateInput!) {
        sendEmailFromTemplate (emailInput: $emailInput)
    }
`;

const useStyles = makeStyles((theme) => ({
    formContainer: {
        marginTop: theme.spacing(2)
    },
    text: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    chip: {
        margin: theme.spacing(0.5, 0.2)
    }
}));

const EmailForm = ({ closeForm, openNotification, setNotificationMessage, setNotificationSeverity, toList }) => {

    const classes = useStyles();
    const employee = useContext(EmployeeContext);

    const [toEmail, setToEmail] = useState();
    const [toEmails, setToEmails] = useState(toList);
    const [subject, setSubject] = useState();
    const [text, setText] = useState();
    const [attachments, setAttachments] = useState([]);
    const [loadingAttach, setLoadingAttach] = useState(false);

    const [sendEmailFromTemplate, { data, loading, error }] = useMutation(SEND_EMAIL);

    const isValidForm = () => {
        const emailsNotEmpty = toEmails.length > 0;
        const isValidEmail = toEmails.every(email => isEmail(email));
        return emailsNotEmpty && isValidEmail;
    };

    const handleSendEmail = (e) => {
        e.preventDefault();
        if (isValidForm()) {
            const email = {
                from: { Email: employee.email, Name: FROM_NAME },
                to: toEmails.map(email => {return {Email: email}}),
                subject: subject,
                variables: { subject: subject, text: text.replace(/(?:\r\n|\r|\n)/g, '<br>') },
                template: GENERIC_TEMPLATE_ID,
                attachments: attachments.length > 0 ? attachments : null
            };

            //send email
            sendEmailFromTemplate({ variables: { emailInput: email } }).catch(err => {
                console.error(`Error enviando el email" ${err}`);
            });
        } else {
            setNotificationMessage('No has añadido direcciones a enviar');
            setNotificationSeverity('error');
            openNotification();
        }
    };

    useEffect(() => {
        if(loading === false && data) {
            console.log(data.sendEmailFromTemplate);
            if (data.sendEmailFromTemplate && data.sendEmailFromTemplate === 'success') {
                setNotificationSeverity('success');
                setNotificationMessage('Email enviado correctamente');
                openNotification();
                closeForm();
            } else {
                setNotificationSeverity('error');
                setNotificationMessage('El email no ha podido ser enviado');
                openNotification();
            }
        } else if (error) {
            setNotificationSeverity('error');
            setNotificationMessage(error.message);
            openNotification();
        }
    }, [loading, data, error, closeForm, setNotificationSeverity, setNotificationMessage, openNotification]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    const addFile = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            // use a regex to remove data url part
            const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");

            const newAttach = {
                ContentType: file.type,
                Filename: file.name,
                Base64Content: base64String
            };
            const newAttachments = attachments.slice();
            newAttachments.push(newAttach);
            setAttachments(newAttachments);
            setLoadingAttach(false);
        };
        reader.onerror = e => {
            console.error(e);
            setLoadingAttach(false);
        };
        reader.readAsDataURL(file);
    };

    const attachFiles = (e) => {
        const arrFiles = Array.from(e.target.files);
        arrFiles.forEach(file => {
            setLoadingAttach(true);
            addFile(file);
        });
    };

    const handleDeleteAttachment = (index) => {
        const newAttachments = attachments.slice();
        newAttachments.splice(index, 1);
        setAttachments(newAttachments);
    };

    const handleKeyDown = evt => {
        if (["Enter", "Tab", ",", " "].includes(evt.key)) {
            evt.preventDefault();
            const value = toEmail.trim();

            if (value && isValid(value)) {
                setToEmails([...toEmails, value]);
                setToEmail(null);
            }
        }
    };
    
    const handleChange = evt => {
        setToEmail(evt.target.value);
    };

    const handleDelete = item => {
        const newToEmails = toEmails.slice();
        setToEmails(newToEmails.filter(i => i !== item));
    };

    const isValid = email => {
        let error = null;
        if (isInList(email)) {
            error = `${email} ya está añadido`;
        }
        if (!isEmail(email)) {
            error = `${email} no es un email válido`;
        }
        if (error) {
            setNotificationSeverity('error');
            setNotificationMessage(error);
            openNotification();
            return false;
        }
        return true;
    }

    const isInList = email => {
        return toEmails.includes(email);
    }

    return (
        <>
            <form onSubmit={handleSendEmail} className={classes.formContainer}>
                <Grid container direction="column">
                    <Grid item xs={12}>
                        <TextField id="email"
                            label="Añadir emails: Escribe el email y pulsa Enter para añadirlo"
                            style={{ width: "100%" }}
                            variant="outlined"
                            InputProps={{
                                onKeyDown: handleKeyDown,
                                onChange: handleChange,
                            }}
                            value={toEmail || ''}
                        />
                        <Typography variant="subtitle1">Para:</Typography>
                        {toEmails.map(email => (
                            <Chip
                                key={email}
                                label={email}
                                className={classes.chip}
                                onDelete={() => handleDelete(email)}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12} className={classes.field}>
                        <TextField id="subject"
                            label="Asunto"
                            value={subject || ''}
                            onChange={(e) => { setSubject(e.target.value) }}
                            required
                            style={{ width: "100%" }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.field}>
                        <TextField id="text"
                            label="Mensaje"
                            value={text || ''}
                            onChange={(e) => { setText(e.target.value) }}
                            variant="outlined"
                            multiline
                            rows={10}
                            className={classes.text}
                            inputProps={{
                                maxLength: 1000,
                            }}
                            required
                        />
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} className={classes.field}>
                            <input
                                style={{ display: 'none' }}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={e => attachFiles(e)}
                            />
                            <label htmlFor="contained-button-file">
                                <Button variant="contained" color="default" component="span" startIcon={<AttachFileIcon />}>
                                    Adjuntar archivos
                                </Button>
                            </label>
                        </Grid>
                        <Grid item xs={9} className={classes.field}>
                            {attachments.map((attachment, index) => (
                                <Chip
                                    key={`attachment-${index}`}
                                    size="small"
                                    label={attachment.Filename}
                                    onDelete={() => handleDeleteAttachment(index)}
                                    style={{margin: 5}}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start">
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SendIcon />}
                                type="submit"
                                className={classes.button}
                            >
                                Enviar
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelIcon />}
                                className={classes.button}
                                onClick={closeForm}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <BackdropLoading open={loading || loadingAttach} />
        </>
    );
}

export default EmailForm;

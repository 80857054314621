import CustomTableContainer from '../common/CustomTableContainer'
import React, { useContext, useState, useEffect } from 'react'
import EmployeeContext from '../../contexts/EmployeeContext'
import CustomModal from '../common/CustomModal'
import ExpedientForm from './ExpedientForm'
import { format } from 'date-fns'
import Notification from '../common/Notification'
import ExpedientDetails from '../expedient/ExpedientDetails'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import BackdropLoading from '../common/BackdropLoading'
import { useLocation } from 'react-router-dom'

const colorByStatus = (status) => {
    switch (status) {
        case 'Nuevo':
            return '#bac3d1'
        case 'Rechazado':
            return '#ff9a9a'
        case 'Cerrado':
            return '#9cf09d'
        case 'Oportunidad':
            return '#ffca1b'
        default:
            return '#6294e3'
    }
}

const headers = [
    { id: 'id', label: 'ID', numeric: true, width: '5%' },
    { id: 'formatted_created_at', label: 'F. Alta', width: '10%' },
    { id: 'status', label: 'Estado', width: '10%', color: colorByStatus },
    { id: 'title', label: 'Título', width: '30%' },
    { id: 'clients', label: 'Clientes', width: '35%' },
    { id: 'city', label: 'Ciudad', width: '10%' }
    //{ id: 'estimated_amount', label: 'Estimado', width: '5%' },
    //{ id: 'final_amount', label: 'Final', width: '5%' }
]

const EXPEDIENTS_QUERY = gql`
    query expedients {
        expedients {
            id
            created_at
            title
            clients {
                client {
                    id
                    first_name
                    last_name
                    dni
                    email
                }
            }
            city {
                id
                name
            }
            employee {
                id
                first_name
                last_name
                email
            }
            status {
                id
                name
                type
            }
        }
    }
`
const Expedients = ({ expedientSelected, setExpedientSelected }) => {
    const location = useLocation()
    const employee = useContext(EmployeeContext)
    const [openNewExpedient, setOpenNewExpedient] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationSeverity, setNotificationSeverity] = useState('success')
    const [expedients, setExpedients] = useState([])

    const {
        loading: expedientsLoading,
        error: expedientsError,
        data: expedientsData,
        refetch: refetchExpedientData,
        networkStatus
    } = useQuery(EXPEDIENTS_QUERY, {
        pollInterval: 120000,
        notifyOnNetworkStatusChange: true
    })

    useEffect(() => {
        if (
            expedientsLoading === false &&
            expedientsData &&
            networkStatus === NetworkStatus.ready
        ) {
            setExpedients(expedientsData.expedients)
        } else if (expedientsError) {
            setNotificationMessage('Error al cargar los expedientes')
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [expedientsError, expedientsLoading, expedientsData, networkStatus])

    useEffect(() => {
        if (location?.state?.newExpedient) {
            setOpenNewExpedient(true)
        }
    }, [location])

    //cleanup
    useEffect(() => {
        return () => {}
    }, [])

    const formatedExpedients = expedients.map((expedient) => {
        return {
            ...expedient,
            clients: expedient.clients.reduce((result, client) => {
                return (
                    result +
                    (result !== '' ? ', ' : '') +
                    client.client.first_name +
                    ' ' +
                    client.client.last_name
                )
            }, ''),
            city: expedient.city?.name,
            status: expedient.status.name,
            formatted_created_at: format(expedient.created_at, 'dd/MM/yyyy'),
            status_type: expedient.status.type
        }
    })

    const handleRowClick = (event, id) => {
        setExpedientSelected(id)
    }

    const goBack = () => {
        setExpedientSelected(null)
    }

    if (expedientSelected) {
        return (
            <ExpedientDetails
                expedientId={expedientSelected}
                refresh={refetchExpedientData}
                goBack={goBack}
            />
        )
    }

    return (
        <>
            <CustomTableContainer
                title="Mis expedientes asignados"
                headers={headers}
                rows={formatedExpedients.filter(
                    (expedient) =>
                        expedient.employee.id === employee.id &&
                        ['INITIAL', 'INTERMEDIATE'].includes(
                            expedient.status_type
                        )
                )}
                handleRowClick={handleRowClick}
                handleNew={() => setOpenNewExpedient(true)}
                refresh={refetchExpedientData}
            />
            <CustomTableContainer
                title="Todos los expedientes"
                headers={headers}
                rows={formatedExpedients}
                handleRowClick={handleRowClick}
                handleNew={() => setOpenNewExpedient(true)}
                refresh={refetchExpedientData}
            />
            <CustomModal open={openNewExpedient} title="Nuevo expediente">
                <ExpedientForm
                    closeForm={() => setOpenNewExpedient(false)}
                    refreshData={refetchExpedientData}
                    openNotification={() => setOpenNotification(true)}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationSeverity={setNotificationSeverity}
                />
            </CustomModal>
            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
            <BackdropLoading open={expedientsLoading} />
        </>
    )
}

export default Expedients

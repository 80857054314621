import { Grid, Typography, Divider, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../common/ConfirmDialog';
import isEmail from 'validator/lib/isEmail';
import BackdropLoading from '../common/BackdropLoading';


const CREATE_INSURANCE = gql`
    mutation createInsuranceCompany ($insuranceCompany: InsuranceCompanyInput!) {
        createInsuranceCompany (insurance_company: $insuranceCompany) {
            id
            name
            email
            phone
        }
    }
`;

const UPDATE_INSURANCE = gql`
    mutation updateInsuranceCompany ($id: Int!, $insuranceCompany: InsuranceCompanyInput!) {
        updateInsuranceCompany (id: $id, insurance_company: $insuranceCompany) {
            id
            name
            email
            phone
        }
    }
`;

const DELETE_INSURANCE = gql`
    mutation deleteInsuranceCompany ($id: Int!) {
        deleteInsuranceCompany (id: $id)
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1000,
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        overflow: 'auto',
        maxHeight: "90%"
    },
    formContainer: {
        marginTop: theme.spacing(4)
    },
    section: {
        marginBottom: theme.spacing(4)
    },
    descriptionField: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    deleteButtonField: {
        marginTop: theme.spacing(2),
        float: "right"
    }
}));


const InsuranceCompanyForm = ({ refreshData, closeForm, dataToUpdate, openNotification, setNotificationMessage, setNotificationSeverity }) => {
    const classes = useStyles();

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (isValidForm()) {
            const insuranceCompany = {
                name,
                email: email != null && email.length > 0 ? email : null,
                phone
            };
            if (dataToUpdate) {
                udpateInsuranceCompany({ variables: { id: dataToUpdate.id, insuranceCompany: insuranceCompany } }).catch(err => {
                    showNotificationError(err);
                });
            } else {
                createInsuranceCompany({ variables: { insuranceCompany: insuranceCompany } }).catch(err => {
                    showNotificationError(err);
                });
            }
        } else {
            showNotificationError("Campos incorrectos");
        }
    };

    const handleDeleteButton = () => {
        deleteInsuranceCompany({ variables: { id: dataToUpdate.id } }).catch(err => {
            showNotificationError(err);
            setOpenDeleteDialog(false);
        });
    }

    const processSuccess = useCallback((message) => {
        refreshData();
        closeForm();
        setNotificationMessage(message);
        setNotificationSeverity('success');
        openNotification();
    }, [refreshData, closeForm, setNotificationMessage, setNotificationSeverity, openNotification]);

    const showNotificationError = useCallback((message) => {
        setNotificationMessage(message);
        setNotificationSeverity('error');
        openNotification();
    }, [setNotificationMessage, setNotificationSeverity, openNotification]);

    const [name, setName] = useState(dataToUpdate?.name);
    const [email, setEmail] = useState(dataToUpdate?.email);
    const [phone, setPhone] = useState(dataToUpdate?.phone);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const isValidForm = () => {
        const isValidEmail = !email || isEmail(email);
        return isValidEmail;
    };

    const [createInsuranceCompany, { data: createInsuranceCompanyData, loading: createInsuranceCompanyLoading,
        error: createInsuranceCompanyError }] = useMutation(CREATE_INSURANCE);

    const [udpateInsuranceCompany, { data: updateInsuranceCompanyData, loading: updateInsuranceCompanyLoading,
        error: updateInsuranceCompanyError }] = useMutation(UPDATE_INSURANCE);

    const [deleteInsuranceCompany, { data: deleteInsuranceCompanyData, loading: deleteInsuranceCompanyLoading,
        error: deleteInsuranceCompanyError }] = useMutation(DELETE_INSURANCE);

    useEffect(() => {
        if(createInsuranceCompanyLoading === false && createInsuranceCompanyData) {
            processSuccess(`Compañía de seguros ${createInsuranceCompanyData.createInsuranceCompany.id} creada`);
        } else if (createInsuranceCompanyError) {
            showNotificationError(createInsuranceCompanyError.message);
        }
    }, [createInsuranceCompanyData, createInsuranceCompanyLoading, createInsuranceCompanyError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(updateInsuranceCompanyLoading === false && updateInsuranceCompanyData) {
            processSuccess(`Compañía de seguros ${updateInsuranceCompanyData.updateInsuranceCompany.id} actualizada`);
        } else if (updateInsuranceCompanyError) {
            showNotificationError(updateInsuranceCompanyError.message);
        }
    }, [updateInsuranceCompanyData, updateInsuranceCompanyLoading, updateInsuranceCompanyError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(deleteInsuranceCompanyLoading === false && deleteInsuranceCompanyData) {
            if (deleteInsuranceCompanyData.deleteInsuranceCompany) {
                processSuccess(`Compañía de seguros ${deleteInsuranceCompanyData.deleteInsuranceCompany} eliminada`);
            } else {
                showNotificationError('Error eliminando la clínica');
            }
        } else if (deleteInsuranceCompanyError) {
            showNotificationError(deleteInsuranceCompanyError.message);
        }
    }, [deleteInsuranceCompanyData, deleteInsuranceCompanyLoading, deleteInsuranceCompanyError, processSuccess, showNotificationError]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <Grid container direction="column" className={classes.formContainer}>
                    <Typography variant="subtitle1">Datos de la compañía de seguros</Typography>
                    <Divider />
                    <Grid container className={classes.section}>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="name"
                                label="Nombre"
                                value={name || ''}
                                onChange={(e)=>{setName(e.target.value)}}
                                required
                                inputProps={{
                                    maxLength: 100,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="email"
                                label="Email"
                                value={email || ''}
                                onChange={(e)=>{setEmail(e.target.value)}}
                                inputProps={{
                                    maxLength: 255,
                                }}
                                error={email && !isEmail(email)}
                                helperText="Introduce un email válido"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="phone"
                                label="Teléfono"
                                value={phone || ''}
                                onChange={(e)=>{setPhone(e.target.value)}}
                                inputProps={{
                                    maxLength: 25,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                type="submit"
                                className={classes.button}
                            >
                                Guardar
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelIcon />}
                                className={classes.button}
                                onClick={closeForm}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        {dataToUpdate &&
                            <Grid item className={classes.deleteButtonField}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => setOpenDeleteDialog(true)}
                                >
                                    Eliminar
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </form>
            <BackdropLoading open={createInsuranceCompanyLoading || updateInsuranceCompanyLoading || deleteInsuranceCompanyLoading} />
            <ConfirmDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                title="Eliminar compañía de seguros"
                text="¿Está seguro de que quiere eliminar la compañía de seguros?"
                confirmText="Eliminar"
                handleConfirm={handleDeleteButton}
            />
        </>
    );
};

export default InsuranceCompanyForm;
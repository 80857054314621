import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TableHead,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core'
import {
    useMutation,
    gql,
    useQuery,
    NetworkStatus,
    useLazyQuery
} from '@apollo/client'
import { useEffect, useState } from 'react'
import Notification from '../../common/Notification'
import { format } from 'date-fns'
import es from 'date-fns/locale/es'
import DocumentationBtn from './DocumentationBtn'
import IsDoneIconModule from '../../common/IsDoneIconModule'
import BackdropLoading from '../../common/BackdropLoading'

const DOCUMENTS = [
    { label: 'Designación', type: 'DESIGNACION' },
    { label: 'Hoja de encargo profesional', type: 'ENCARGO' }
]

const DOCUMENTS_QUERY = gql`
    query getExpedientDocuments($expedientId: Int!) {
        getExpedientDocuments(expedientId: $expedientId) {
            documents {
                created_at
                doc_type
                expedient_id
                id
                status
                updated_at
                plate
            }
        }
    }
`
const DOWNLOAD_QUERY = gql`
    query downloadDocumentById($documentId: Int!) {
        downloadDocumentById(documentId: $documentId) {
            created_at
            doc_type
            expedient_id
            id
            status
            updated_at
            plate
            file
            file_name
            status
        }
    }
`

const GENERATE_DESIGNATION_DOCUMENT = gql`
    mutation generateDesignationDocument(
        $designationDocumentInput: DesignationDocumentInput!
    ) {
        generateDesignationDocument(
            designationDocumentInput: $designationDocumentInput
        ) {
            id
        }
    }
`

const GENERATE_PROFESSIONAL_ORDER_DOCUMENT = gql`
    mutation generateProfessionalOrderDocument(
        $professionalOrderDocumentInput: ProfessionalOrderDocumentInput!
    ) {
        generateProfessionalOrderDocument(
            professionalOrderDocumentInput: $professionalOrderDocumentInput
        ) {
            id
        }
    }
`

const GENERATE_FIVA_DOCUMENT = gql`
    mutation generateFivaDocument($fivaDocumentInput: FivaDocumentInput!) {
        generateFivaDocument(fivaDocumentInput: $fivaDocumentInput) {
            id
        }
    }
`

const ATTACH_DOCUMENT = gql`
    mutation attachDocument(
        $file: String!
        $expedientId: Int!
        $type: String!
        $plate: String
    ) {
        attachDocument(
            file: $file
            expedientId: $expedientId
            type: $type
            plate: $plate
        ) {
            id
        }
    }
`

const UPDATE_DOCUMENT = gql`
    mutation updateDocument($id: Int!, $status: String, $file: String) {
        updateDocument(id: $id, status: $status, file: $file) {
            id
        }
    }
`

const DELETE_DOCUMENT = gql`
    mutation deleteDocument($id: Int!) {
        deleteDocument(id: $id)
    }
`

const downloadBase64File = (contentBase64, fileName) => {
    let a = document.createElement('a')
    a.href = contentBase64
    a.download = fileName
    a.click()
    a.remove()
}

const status = {
    NEW: { label: 'Nuevo', color: '#CDD8CA' },
    PENDING: { label: 'Pendiente de firma', color: '#88BEEC' },
    SIGNED: { label: 'Firmado', color: '#96D782' }
}

const Documentation = ({ expedient }) => {
    //useStates
    const [documents, setDocuments] = useState([])
    const [openNotification, setOpenNotification] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationSeverity, setNotificationSeverity] = useState('success')
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [documentToDelete, setDocumentToDelete] = useState(null)

    //useQueries
    const {
        data: storedDocumentsData,
        loading: storedDocumentsLoading,
        error: storedDocumentsError,
        refetch: refetchDocumentsData,
        networkStatus
    } = useQuery(DOCUMENTS_QUERY, {
        variables: { expedientId: expedient.id }
    })
    //useLazyQuery
    const [
        downloadDocument,
        { loading: downloadLoading, error: downloadError, data: downloadData }
    ] = useLazyQuery(DOWNLOAD_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'no-cache'
    })

    //useMutations
    const [
        deleteDocument,
        { data: deleteData, loading: deleteLoading, error: deleteError }
    ] = useMutation(DELETE_DOCUMENT)

    const [
        updateDocument,
        { data: updateData, loading: updateLoading, error: updateError }
    ] = useMutation(UPDATE_DOCUMENT)

    const [
        attachNewDocument,
        { data: attachData, loading: attachUpdateLoading, error: attachError }
    ] = useMutation(ATTACH_DOCUMENT)

    const [
        generateDesignationDocument,
        {
            data: designationData,
            loading: designationLoading,
            error: designationError
        }
    ] = useMutation(GENERATE_DESIGNATION_DOCUMENT)
    const [
        generateProfessionalOrderDocument,
        {
            data: professionalOrderData,
            loading: professionalOrderLoading,
            error: professionalOrderError
        }
    ] = useMutation(GENERATE_PROFESSIONAL_ORDER_DOCUMENT)
    const [
        generateFivaDocument,
        { data: fivaData, loading: fivaLoading, error: fivaError }
    ] = useMutation(GENERATE_FIVA_DOCUMENT)

    // useEffects
    useEffect(() => {
        if (
            storedDocumentsLoading === false &&
            storedDocumentsData &&
            networkStatus === NetworkStatus.ready
        ) {
            const fivaDocuments = expedient.opposite_vehicles
                ?.filter((vehicle) => vehicle?.vehicle?.plate)
                .map((vehicle) => {
                    return {
                        label: `FIVA ${vehicle.vehicle.plate}`,
                        type: 'FIVA',
                        plate: vehicle.vehicle.plate
                    }
                })

            const storedDocuments =
                storedDocumentsData.getExpedientDocuments?.documents

            const documentsToUpdate = structuredClone([
                ...DOCUMENTS,
                ...fivaDocuments
            ])
            if (storedDocuments) {
                documentsToUpdate.forEach((documentToUpdate) => {
                    const storedDocument = storedDocuments.find((document) => {
                        return (
                            document.doc_type === documentToUpdate.type &&
                            (document.plate === documentToUpdate.plate ||
                                (document.doc_type !== 'FIVA' &&
                                    document.plate === null))
                        )
                    })

                    if (storedDocument) {
                        documentToUpdate.id = storedDocument.id
                        documentToUpdate.status = storedDocument.status
                    } else {
                        documentToUpdate.id = undefined
                        documentToUpdate.status = undefined
                    }
                })
            }
            setDocuments(documentsToUpdate)
        } else if (storedDocumentsError) {
            setNotificationMessage(
                'Error al obtener los documentos del expediente'
            )
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [
        storedDocumentsLoading,
        storedDocumentsData,
        storedDocumentsError,
        networkStatus,
        expedient
    ])

    useEffect(() => {
        if (designationLoading === false && designationData) {
            setNotificationMessage('Hoja de designación generada correctamente')
            setNotificationSeverity('success')
            setOpenNotification(true)
            refetchDocumentsData()
        } else if (designationError) {
            if (typeof designationError === 'object') {
                setNotificationMessage(
                    JSON.stringify(
                        designationError.graphQLErrors[0]?.message
                    )?.replace(/['"]+/g, '')
                )
            } else {
                setNotificationMessage(designationError)
            }
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [
        designationLoading,
        designationData,
        designationError,
        refetchDocumentsData
    ])

    useEffect(() => {
        if (professionalOrderLoading === false && professionalOrderData) {
            setNotificationMessage('Hoja de encargo generada correctamente')
            setNotificationSeverity('success')
            setOpenNotification(true)
            refetchDocumentsData()
        } else if (professionalOrderError) {
            if (typeof professionalOrderError === 'object') {
                setNotificationMessage(
                    JSON.stringify(
                        professionalOrderError.graphQLErrors[0]?.message
                    ).replace(/['"]+/g, '')
                )
            } else {
                setNotificationMessage(professionalOrderError)
            }
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [
        professionalOrderLoading,
        professionalOrderData,
        professionalOrderError,
        expedient,
        refetchDocumentsData
    ])

    useEffect(() => {
        if (fivaLoading === false && fivaData) {
            setNotificationMessage('Fiva generado correctamente')
            setNotificationSeverity('success')
            setOpenNotification(true)
            refetchDocumentsData()
        } else if (fivaError) {
            if (typeof fivaError === 'object') {
                setNotificationMessage(
                    JSON.stringify(fivaError.graphQLErrors[0].message).replace(
                        /['"]+/g,
                        ''
                    )
                )
            } else {
                setNotificationMessage(fivaError)
            }
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [fivaLoading, fivaData, fivaError, refetchDocumentsData])

    useEffect(() => {
        if (downloadLoading === false && downloadData) {
            downloadBase64File(
                downloadData.downloadDocumentById.file,
                downloadData.downloadDocumentById.file_name
            )
            setNotificationMessage('Documento descargado con éxito')
            setNotificationSeverity('success')
            setOpenNotification(true)
        } else if (downloadError) {
            if (typeof downloadError === 'object') {
                setNotificationMessage(
                    JSON.stringify(
                        downloadError.graphQLErrors[0]?.message
                    )?.replace(/['"]+/g, '')
                )
            } else {
                setNotificationMessage(downloadError)
            }
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [downloadLoading, downloadData, downloadError])

    // cleanup
    useEffect(() => {
        return () => { }
    }, [])

    //functions
    const handleDocumentGeneration = (type, plate) => {
        switch (type) {
            case 'DESIGNACION':
                handleDesignationDocumentGeneration()
                break
            case 'ENCARGO':
                handleProfessionalOrderDocumentGeneration()
                break
            case 'FIVA':
                handleFivaDocumentGeneration(plate)
                break
            default:
                setNotificationMessage('Tipo de documento incorrecto')
                setNotificationSeverity('error')
                setOpenNotification(true)
        }
    }

    const handleDesignationDocumentGeneration = () => {
        const clients = expedient.clients.map((client) => {
            return {
                first_name: client.client.first_name,
                last_name: client.client.last_name,
                dni: client.client.dni,
                genre: client.client.genre
            }
        })
        const plates =
            expedient.clients
                .map((client) => client.vehicle?.plate)
                .filter((plate) => plate != null) ?? null
        const insuranceCompanies =
            expedient.clients
                .map((client) => client.insurance_company?.name)
                .filter((insuranceCompany) => insuranceCompany != null) ?? null
        const oppositeVehicles = expedient.opposite_vehicles
        const oppositePlates =
            oppositeVehicles
                .map((vehicle) => vehicle?.vehicle?.plate)
                .filter((plate) => plate != null) ?? null
        const oppositeInsuranceCompanies =
            oppositeVehicles
                .map((vehicle) => vehicle?.insurance_company?.name)
                .filter((vehicle) => vehicle != null) ?? null
        const designationDocumentInput = {
            expedient_id: expedient.id,
            clients: clients,
            incident_date: format(expedient.incident_date, 'dd/MM/yyyy'),
            incident_time: format(expedient.incident_date, 'HH:mm'),
            address: expedient.address,
            city: expedient.city.name,
            plates: plates?.length > 0 ? plates : null,
            insurance_companies:
                insuranceCompanies?.length > 0 ? insuranceCompanies : null,
            opposite_plates: oppositePlates?.length > 0 ? oppositePlates : null,
            opposite_insurance_companies:
                oppositeInsuranceCompanies?.length > 0
                    ? oppositeInsuranceCompanies
                    : null,
            today_day: format(Date.now(), 'dd'),
            today_month: format(Date.now(), 'MMMM', { locale: es }),
            today_year: format(Date.now(), 'yyyy'),
            incident_reason: expedient.incident_reason?.name.toLowerCase()
        }
        generateDesignationDocument({
            variables: { designationDocumentInput }
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleProfessionalOrderDocumentGeneration = () => {
        const clients = expedient.clients.map((client) => {
            return {
                first_name: client.client.first_name,
                last_name: client.client.last_name,
                dni: client.client.dni
            }
        })
        const professionalOrderDocumentInput = {
            expedient_id: expedient.id,
            clients: clients,
            today_day: format(Date.now(), 'dd'),
            today_month: format(Date.now(), 'MMMM', { locale: es }),
            today_year: format(Date.now(), 'yyyy')
        }
        generateProfessionalOrderDocument({
            variables: { professionalOrderDocumentInput }
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleFivaDocumentGeneration = (oppositePlate) => {
        const expedientClient = expedient.clients[0]
        const fivaDocumentInput = {
            expedient_id: expedient.id,
            first_name: expedientClient.client.first_name,
            last_name: expedientClient.client.last_name,
            incident_date: format(expedient.incident_date, 'dd/MM/yyyy'),
            dni: expedientClient.client.dni ?? null,
            today_day: format(Date.now(), 'dd'),
            today_month: format(Date.now(), 'MMMM', { locale: es }),
            today_year: format(Date.now(), 'yyyy'),
            opposite_plate: oppositePlate,
            client_plate: expedientClient.vehicle?.plate
        }
        generateFivaDocument({ variables: { fivaDocumentInput } }).catch(
            (e) => {
                console.log(e)
            }
        )
    }

    const handleDocumentDelete = (id) => {
        const document = documents.find((document) => document.id === id)
        if (document?.status === 'SIGNED') {
            setDocumentToDelete(id)
            setOpenDeleteDialog(true)
        } else {
            confirmDeleteDocument(id)
        }
    }

    const confirmDeleteDocument = (id) => {
        setDocumentToDelete(null)
        setOpenDeleteDialog(false)
        deleteDocument({
            variables: { id }
        }).catch((e) => {
            setNotificationMessage(e)
            setNotificationSeverity('error')
            setOpenNotification(true)
        })
    }

    const handleDocumentUpdate = (id, status, file) => {
        updateDocument({
            variables: { id, status, file }
        }).catch((e) => {
            console.log(e)
            setNotificationMessage(e)
            setNotificationSeverity('error')
            setOpenNotification(true)
        })
    }
    const handleAttachNewDocument = (file, type, plate) => {
        let expedientId = expedient.id
        attachNewDocument({
            variables: { file, expedientId, type, plate }
        }).catch((e) => {
            setNotificationMessage(e)
            setNotificationSeverity('error')
            setOpenNotification(true)
        })
    }

    const handleDocumentDownload = (id) => {
        downloadDocument({
            variables: { documentId: id }
        })
    }

    useEffect(() => {
        if (deleteLoading === false && deleteData) {
            setNotificationMessage('Documento eliminado correctamente')
            setNotificationSeverity('success')
            setOpenNotification(true)
            refetchDocumentsData()
        } else if (deleteError) {
            setNotificationMessage('Error al eliminar el documento')
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [deleteLoading, deleteData, deleteError, refetchDocumentsData])

    useEffect(() => {
        if (updateLoading === false && updateData) {
            setNotificationMessage('Documento actualizado correctamente')
            setNotificationSeverity('success')
            setOpenNotification(true)
            refetchDocumentsData()
        } else if (updateError) {
            setNotificationMessage('Error al actualizar el documento')
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [updateLoading, updateData, updateError, refetchDocumentsData])

    useEffect(() => {
        if (attachUpdateLoading === false && attachData) {
            setNotificationMessage('Documento nuevo adjuntado correctamente')
            setNotificationSeverity('success')
            setOpenNotification(true)
            refetchDocumentsData()
        } else if (attachError) {
            setNotificationMessage('Error al adjuntar el nuevo documento')
            setNotificationSeverity('error')
            setOpenNotification(true)
        }
    }, [attachUpdateLoading, attachData, attachError, refetchDocumentsData])

    const actionLoading =
        storedDocumentsLoading ||
        deleteLoading ||
        updateLoading ||
        designationLoading ||
        professionalOrderLoading ||
        fivaLoading

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Documento</TableCell>
                            <TableCell align="left">Estado</TableCell>
                            <TableCell align="right">Generado</TableCell>
                            <TableCell align="right">Firmado</TableCell>
                            <TableCell align="right">Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((document) => (
                            <TableRow
                                key={`${document.type}-${document.plate}`}
                            >
                                <TableCell align="left">
                                    <Typography variant="body1">
                                        {document.label}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Chip
                                        style={{
                                            backgroundColor:
                                                status[document.status]
                                                    ?.color ?? '#E97979'
                                        }}
                                        label={
                                            status[document.status]?.label ??
                                            'No generado'
                                        }
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    {/* Has been generated? */}
                                    <IsDoneIconModule
                                        isDone={
                                            documents.includes(document) &&
                                            document.id !== undefined &&
                                            document.status !== undefined
                                        }
                                    />
                                </TableCell>
                                {/* Has been signed? */}
                                <TableCell align="right">
                                    <IsDoneIconModule
                                        isDone={document.status === 'SIGNED'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    {expedient.status.id > 0 &&
                                        <DocumentationBtn
                                            handleDocumentGeneration={
                                                handleDocumentGeneration
                                            }
                                            handleDocumentDelete={
                                                handleDocumentDelete
                                            }
                                            handleDocumentUpdate={
                                                handleDocumentUpdate
                                            }
                                            handleDocumentAttach={
                                                handleAttachNewDocument
                                            }
                                            handleDocumentDownload={
                                                handleDocumentDownload
                                            }
                                            document={document}
                                        />
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <BackdropLoading open={actionLoading} />
            </TableContainer>
            <Notification
                open={openNotification}
                setOpen={setOpenNotification}
                message={notificationMessage}
                severity={notificationSeverity}
            />
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>¿Eliminar documento firmado?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Al eliminar el documento firmado, se perderá la firma,
                        ¿está seguro?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => confirmDeleteDocument(documentToDelete)}
                    >
                        Eliminar documento firmado
                    </Button>
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Documentation

import {
    Grid,
    Typography,
    Divider,
    TextField,
    MenuItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, gql, useMutation } from '@apollo/client'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useState, useEffect, useCallback } from 'react'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import ConfirmDialog from '../common/ConfirmDialog'
import isEmail from 'validator/lib/isEmail'
import BackdropLoading from '../common/BackdropLoading'
import EmailIcon from '@material-ui/icons/Email'

const PROVINCES_QUERY = gql`
    query provincesByCountry($country_id: Int!) {
        provincesByCountry(country_id: $country_id) {
            id
            name
        }
    }
`

const CITIES_QUERY = gql`
    query citiesByProvince($province_id: Int!) {
        citiesByProvince(province_id: $province_id) {
            id
            name
        }
    }
`

const CREATE_CLIENT = gql`
    mutation createClient($client: ClientInput!) {
        createClient(client: $client) {
            id
        }
    }
`

const UPDATE_CLIENT = gql`
    mutation updateClient($id: Int!, $client: ClientInput!) {
        updateClient(id: $id, client: $client) {
            id
        }
    }
`

const DELETE_CLIENT = gql`
    mutation deleteClient($id: Int!) {
        deleteClient(id: $id)
    }
`

const RESEND_WELCOME_EMAIL = gql`
    mutation resendWelcomeEmail($id: Int!, $client: ResendClient!) {
        resendWelcomeEmail(id: $id, client: $client)
    }
`

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1000,
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        overflow: 'auto',
        maxHeight: '90%'
    },
    formContainer: {
        marginTop: theme.spacing(4)
    },
    section: {
        marginBottom: theme.spacing(4)
    },
    descriptionField: {
        width: '100%',
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    deleteButtonField: {
        marginTop: theme.spacing(2),
        float: 'right'
    }
}))

const COUNTRY_ID = 1

const GENRE = [
    { id: 1, value: '', name: '' },
    { id: 2, value: 'MALE', name: 'Hombre' },
    { id: 3, value: 'FEMALE', name: 'Mujer' }
]

const EXERCISE = [
    { id: 1, value: '', name: '' },
    { id: 2, value: 'LOW', name: 'Bajo' },
    { id: 3, value: 'MEDIUM', name: 'Medio' },
    { id: 4, value: 'HIGH', name: 'Alto' }
]

const PREFERRED_CONTACT = [
    { id: 1, value: '', name: '' },
    { id: 2, value: 'EMAIL', name: 'Email' },
    { id: 3, value: 'PHONE', name: 'Teléfono' }
]

const CONTACT_TIME = [
    { id: 1, value: '', name: '' },
    { id: 2, value: 'MORNING', name: 'Mañana' },
    { id: 3, value: 'AFTERNOON', name: 'Tarde' }
]

const ClientForm = ({
    refreshData,
    closeForm,
    dataToUpdate,
    openNotification,
    setNotificationMessage,
    setNotificationSeverity,
    isEmailDuplicated
}) => {
    const classes = useStyles()

    const handleSubmitForm = (e) => {
        e.preventDefault()
        if (isValidForm()) {
            const client = {
                first_name: firstName,
                last_name: lastName,
                dni: dni,
                nif,
                dob,
                genre: genre !== '' ? genre : null,
                email: email != null && email.length > 0 ? email : null,
                phone,
                phone2,
                nationality,
                job,
                exercise: exercise !== '' ? exercise : null,
                preferred_contact:
                    preferredContact !== '' ? preferredContact : null,
                contact_time: contactTime !== '' ? contactTime : null,
                city_id: city !== '' ? city : null
            }
            if (dataToUpdate) {
                udpateClient({
                    variables: { id: dataToUpdate.id, client: client }
                }).catch((err) => {
                    showNotificationError(err)
                })
            } else {
                createClient({ variables: { client: client } }).catch((err) => {
                    showNotificationError(err)
                })
            }
        } else {
            showNotificationError('Campos incorrectos')
        }
    }

    const handleDeleteButton = () => {
        deleteClient({ variables: { id: dataToUpdate.id } }).catch((err) => {
            showNotificationError(err.message)
            setOpenDeleteDialog(false)
        })
    }

    const processSuccess = useCallback(
        (message) => {
            refreshData()
            closeForm()
            setNotificationMessage(message)
            setNotificationSeverity('success')
            openNotification()
        },
        [
            refreshData,
            closeForm,
            setNotificationMessage,
            setNotificationSeverity,
            openNotification
        ]
    )

    const processSuccessWithoutClose = useCallback(
        (message) => {
            setNotificationMessage(message)
            setNotificationSeverity('success')
            openNotification()
        },
        [
            setNotificationMessage,
            setNotificationSeverity,
            openNotification
        ]
    )

    const showNotificationError = useCallback(
        (message) => {
            setNotificationMessage(message)
            setNotificationSeverity('error')
            openNotification()
        },
        [setNotificationMessage, setNotificationSeverity, openNotification]
    )

    const [firstName, setFirstName] = useState(dataToUpdate?.first_name)
    const [lastName, setLastName] = useState(dataToUpdate?.last_name)
    const [dni, setDni] = useState(dataToUpdate?.dni)
    const [nif, setNif] = useState(dataToUpdate?.nif)
    const [dob, setDob] = useState(dataToUpdate ? dataToUpdate.dob : new Date())
    const [genre, setGenre] = useState(dataToUpdate?.genre ?? '')
    const [email, setEmail] = useState(dataToUpdate?.email)
    const [phone, setPhone] = useState(dataToUpdate?.phone)
    const [phone2, setPhone2] = useState(dataToUpdate?.phone2)
    const [nationality, setNationality] = useState(dataToUpdate?.nationality)
    const [job, setJob] = useState(dataToUpdate?.job)
    const [exercise, setExercise] = useState(dataToUpdate?.exercise ?? '')
    const [preferredContact, setPreferredContact] = useState(
        dataToUpdate?.preferred_contact ?? ''
    )
    const [contactTime, setContactTime] = useState(
        dataToUpdate?.contact_time ?? ''
    )
    const [province, setProvince] = useState(dataToUpdate?.province_id ?? '')
    const [provinces, setProvinces] = useState([])
    const [city, setCity] = useState(dataToUpdate?.city_id ?? '')
    const [cities, setCities] = useState([])
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    const isValidForm = () => {
        const isValidEmail =
            !email ||
            (isEmail(email) && !isEmailDuplicated(email, dataToUpdate?.id))
        return isValidEmail
    }

    const { data: provincesData, loading: provincesLoading } = useQuery(
        PROVINCES_QUERY,
        { variables: { country_id: COUNTRY_ID } }
    )

    const { data: citiesData, loading: citiesLoading } = useQuery(
        CITIES_QUERY,
        { skip: province === '', variables: { province_id: province } }
    )

    useEffect(() => {
        if (provincesLoading === false && provincesData) {
            setProvinces([
                { id: null, name: '' },
                ...provincesData.provincesByCountry
            ])
        }
    }, [provincesLoading, provincesData])

    useEffect(() => {
        if (citiesLoading === false && citiesData) {
            setCities([{ id: null, name: '' }, ...citiesData.citiesByProvince])
        }
    }, [citiesLoading, citiesData])

    const [
        createClient,
        {
            data: createClientData,
            loading: createClientLoading,
            error: createClientError
        }
    ] = useMutation(CREATE_CLIENT)

    const [
        udpateClient,
        {
            data: updateClientData,
            loading: updateClientLoading,
            error: updateClientError
        }
    ] = useMutation(UPDATE_CLIENT)

    const [
        deleteClient,
        {
            data: deleteClientData,
            loading: deleteClientLoading,
            error: deleteClientError
        }
    ] = useMutation(DELETE_CLIENT)

    useEffect(() => {
        if (createClientLoading === false && createClientData) {
            processSuccess(`Cliente ${createClientData.createClient.id} creado`)
        } else if (createClientError) {
            showNotificationError(createClientError.message)
        }
    }, [
        createClientData,
        createClientLoading,
        createClientError,
        processSuccess,
        showNotificationError
    ])

    useEffect(() => {
        if (updateClientLoading === false && updateClientData) {
            processSuccess(
                `Cliente ${updateClientData.updateClient.id} actualizado`
            )
        } else if (updateClientError) {
            showNotificationError(updateClientError.message)
        }
    }, [
        updateClientData,
        updateClientLoading,
        updateClientError,
        processSuccess,
        showNotificationError
    ])

    useEffect(() => {
        if (deleteClientLoading === false && deleteClientData) {
            if (deleteClientData.deleteClient) {
                processSuccess(
                    `Cliente ${deleteClientData.deleteClient} eliminado`
                )
            } else {
                showNotificationError('Error eliminando el cliente')
            }
        } else if (deleteClientError) {
            showNotificationError(deleteClientError.message)
        }
    }, [
        deleteClientData,
        deleteClientLoading,
        deleteClientError,
        processSuccess,
        showNotificationError
    ])

    const [
        resendWelcomeEmail,
        {
            data: resendWelcomeEmailData,
            loading: resendWelcomeEmailLoading,
            error: resendWelcomeEmailError
        }
    ] = useMutation(RESEND_WELCOME_EMAIL)

    const onResendWelcomeClick = () => {
        resendWelcomeEmail({ variables: { id: dataToUpdate.id, 
            client: {
                id: dataToUpdate.id,
                first_name: dataToUpdate.first_name,
                last_name: dataToUpdate.last_name,
                email: dataToUpdate.email
            } }})
            .catch((err) => {
            showNotificationError(err.message)
        })
    }

    useEffect(() => {
        if (resendWelcomeEmailLoading === false && resendWelcomeEmailData) {
            if (resendWelcomeEmailData.resendWelcomeEmail === 'success') {
                processSuccessWithoutClose('Email de bienvenida enviado')
            } else {
                showNotificationError('Error al enviar el email de bienvenida')
            }
        } else if (resendWelcomeEmailError) {
            showNotificationError('Error al enviar el email de bienvenida')
        }
    }, [
        resendWelcomeEmailData,
        resendWelcomeEmailError,
        resendWelcomeEmailLoading,
        processSuccessWithoutClose,
        showNotificationError
    ])

    //cleanup
    useEffect(() => {
        return () => {}
    }, [])

    const changeProvince = (e) => {
        setCity('')
        setProvince(e.target.value)
    }

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <Grid
                    container
                    direction="column"
                    className={classes.formContainer}
                >
                    <Typography variant="subtitle1">
                        Datos del cliente
                    </Typography>
                    <Divider />
                    <Grid container className={classes.section}>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="first_name"
                                label="Nombre"
                                value={firstName || ''}
                                onChange={(e) => {
                                    setFirstName(e.target.value)
                                }}
                                required
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="last_name"
                                label="Apellidos"
                                value={lastName || ''}
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                                inputProps={{
                                    maxLength: 100
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="dni"
                                label="DNI"
                                value={dni || ''}
                                onChange={(e) => {
                                    setDni(e.target.value)
                                }}
                                inputProps={{
                                    maxLength: 9
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="nif"
                                label="NIF"
                                value={nif || ''}
                                onChange={(e) => {
                                    setNif(e.target.value)
                                }}
                                inputProps={{
                                    maxLength: 9
                                }}
                            />
                        </Grid>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12} sm={6} className={classes.field}>
                                <KeyboardDatePicker
                                    disableFuture
                                    openTo="year"
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="dob"
                                    label="Fecha de nacimiento"
                                    value={dob}
                                    onChange={(date) => {
                                        setDob(date)
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Cambiar fecha'
                                    }}
                                    helperText="Selecciona la fecha de nacimiento"
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="genre"
                                select
                                label="Género"
                                value={genre}
                                onChange={(e) => {
                                    setGenre(e.target.value)
                                }}
                                helperText="Selecciona el género"
                            >
                                {GENRE.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="email"
                                label="Email"
                                value={email || ''}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                inputProps={{
                                    maxLength: 255
                                }}
                                error={
                                    email &&
                                    (!isEmail(email) ||
                                        isEmailDuplicated(
                                            email,
                                            dataToUpdate?.id
                                        ))
                                }
                                helperText="Introduce un email válido y que no use otro usuario"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="phone"
                                label="Teléfono"
                                value={phone || ''}
                                onChange={(e) => {
                                    setPhone(e.target.value)
                                }}
                                inputProps={{
                                    maxLength: 25
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="phone2"
                                label="Teléfono 2"
                                value={phone2 || ''}
                                onChange={(e) => {
                                    setPhone2(e.target.value)
                                }}
                                inputProps={{
                                    maxLength: 25
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="nationality"
                                label="Nacionalidad"
                                value={nationality || ''}
                                onChange={(e) => {
                                    setNationality(e.target.value)
                                }}
                                inputProps={{
                                    maxLength: 90
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="province"
                                select
                                label="Provincia"
                                value={
                                    provinces.length === 0 ? '' : province || ''
                                }
                                onChange={changeProvince}
                                helperText="Selecciona la provincia"
                            >
                                {provinces.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="city"
                                select
                                label="Ciudad"
                                value={cities.length === 0 ? '' : city || ''}
                                onChange={(e) => {
                                    setCity(e.target.value)
                                }}
                                helperText="Selecciona la ciudad"
                            >
                                {cities.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="job"
                                label="Empleo"
                                value={job || ''}
                                onChange={(e) => {
                                    setJob(e.target.value)
                                }}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="exercise"
                                select
                                label="Ejercicio"
                                value={exercise}
                                onChange={(e) => {
                                    setExercise(e.target.value)
                                }}
                                helperText="Selecciona el ejercicio"
                            >
                                {EXERCISE.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="preferred_contact"
                                select
                                label="Método preferido de contacto"
                                value={preferredContact}
                                onChange={(e) => {
                                    setPreferredContact(e.target.value)
                                }}
                                helperText="Selecciona el método preferido de contacto"
                            >
                                {PREFERRED_CONTACT.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="contact_time"
                                select
                                label="Momento preferido de contacto"
                                value={contactTime}
                                onChange={(e) => {
                                    setContactTime(e.target.value)
                                }}
                                helperText="Selecciona el momento preferido de contacto"
                            >
                                {CONTACT_TIME.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                type="submit"
                                className={classes.button}
                            >
                                Guardar
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelIcon />}
                                className={classes.button}
                                onClick={closeForm}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        {dataToUpdate && (
                            <Grid item className={classes.deleteButtonField}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DeleteIcon />}
                                    className={classes.button}
                                    onClick={() => setOpenDeleteDialog(true)}
                                >
                                    Eliminar
                                </Button>
                                {dataToUpdate.email && <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<EmailIcon />}
                                        onClick={() =>
                                            onResendWelcomeClick(dataToUpdate?.id)
                                        }
                                    >
                                        Reenviar bienvenida
                                    </Button>
                                }
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </form>
            <BackdropLoading
                open={
                    createClientLoading ||
                    updateClientLoading ||
                    deleteClientLoading ||
                    provincesLoading ||
                    citiesLoading
                }
            />
            <ConfirmDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                title="Eliminar cliente"
                text="¿Está seguro de que quiere eliminar el cliente?"
                confirmText="Eliminar"
                handleConfirm={handleDeleteButton}
            />
        </>
    )
}

export default ClientForm

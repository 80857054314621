import { Grid, Typography, Divider, TextField, MenuItem, TableContainer, TableCell, TableRow, Table, TableHead, TableBody, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../common/ConfirmDialog';
import isEmail from 'validator/lib/isEmail';
import BackdropLoading from '../common/BackdropLoading';

const PROVINCES_QUERY = gql`
    query provincesByCountry ($country_id: Int!) {
        provincesByCountry (country_id: $country_id) {
            id
            name
        }
}
`;

const CITIES_QUERY = gql`
    query citiesByProvince ($province_id: Int!) {
        citiesByProvince (province_id: $province_id) {
            id
            name
        }
    }
`;

const CREATE_CLINIC = gql`
    mutation createClinic ($clinic: ClinicInput!) {
        createClinic (clinic: $clinic) {
            id
            name
            email
            phone
            phone2
            city {
                id
                name
                province {
                    id
                    name
                }
            }
            address1
            address2
        }
    }
`;

const UPDATE_CLINIC = gql`
    mutation updateClinic ($id: Int!, $clinic: ClinicInput!) {
        updateClinic (id: $id, clinic: $clinic) {
            id
            name
            email
            phone
            phone2
            city {
                id
                name
                province {
                    id
                    name
                }
            }
            address1
            address2
        }
    }
`;

const DELETE_CLINIC = gql`
    mutation deleteClinic ($id: Int!) {
        deleteClinic (id: $id)
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1000,
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        overflow: 'auto',
        maxHeight: "90%"
    },
    formContainer: {
        marginTop: theme.spacing(4)
    },
    section: {
        marginBottom: theme.spacing(4)
    },
    descriptionField: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    field: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginRight: theme.spacing(1)
    },
    deleteButtonField: {
        marginTop: theme.spacing(2),
        float: "right"
    }
}));

const COUNTRY_ID = 1;

const ClinicForm = ({ refreshData, closeForm, dataToUpdate, openNotification, setNotificationMessage, setNotificationSeverity }) => {
    const classes = useStyles();

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (isValidForm()) {
            const clinic = {
                name: name,
                email: email != null && email.length > 0 ? email : null,
                phone,
                phone2,
                address1,
                address2,
                city_id: city
            };
            if (dataToUpdate) {
                udpateClinic({ variables: { id: dataToUpdate.id, clinic: clinic } }).catch(err => {
                    showNotificationError(err);
                });
            } else {
                createClinic({ variables: { clinic: clinic } }).catch(err => {
                    showNotificationError(err);
                });
            }
        } else {
            showNotificationError("Campos incorrectos"); 
        }
    };

    const handleDeleteButton = () => {
        deleteClinic({ variables: { id: dataToUpdate.id } }).catch(err => {
            showNotificationError(err);
            setOpenDeleteDialog(false);
        });
    }

    const processSuccess = useCallback((message) => {
        refreshData();
        closeForm();
        setNotificationMessage(message);
        setNotificationSeverity('success');
        openNotification();
    }, [refreshData, closeForm, setNotificationMessage, setNotificationSeverity, openNotification]);

    const showNotificationError = useCallback((message) => {
        setNotificationMessage(message);
        setNotificationSeverity('error');
        openNotification();
    }, [setNotificationMessage, setNotificationSeverity, openNotification]);

    const [name, setName] = useState(dataToUpdate?.name);
    const [email, setEmail] = useState(dataToUpdate?.email);
    const [phone, setPhone] = useState(dataToUpdate?.phone);
    const [phone2, setPhone2] = useState(dataToUpdate?.phone2);
    const [address1, setAddress1] = useState(dataToUpdate?.address1);
    const [address2, setAddress2] = useState(dataToUpdate?.address1);
    const [province, setProvince] = useState(dataToUpdate?.province_id ?? '');
    const [provinces, setProvinces] = useState([]);
    const [city, setCity] = useState(dataToUpdate?.city_id ?? '');
    const [cities, setCities] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const isValidForm = () => {
        const isValidEmail = !email || isEmail(email);
        return isValidEmail;
    };

    const { data: provincesData, loading: provincesLoading } =
        useQuery(PROVINCES_QUERY, { variables: { country_id: COUNTRY_ID } });

    const { data: citiesData, loading: citiesLoading } =
        useQuery(CITIES_QUERY, { skip: province === '', variables: { province_id: province } });

    useEffect(() => {
        if(provincesLoading === false && provincesData){
            setProvinces([{ id: null, name: '' }, ...provincesData.provincesByCountry]);
        }
    }, [provincesLoading, provincesData]);

    useEffect(() => {
        if(citiesLoading === false && citiesData){
            setCities([{ id: null, name: '' }, ...citiesData.citiesByProvince]);
        }
    }, [citiesLoading, citiesData]);

    const [createClinic, { data: createClinicData, loading: createClinicLoading,
        error: createClinicError }] = useMutation(CREATE_CLINIC);

    const [udpateClinic, { data: updateClinicData, loading: updateClinicLoading,
        error: updateClinicError }] = useMutation(UPDATE_CLINIC);

    const [deleteClinic, { data: deleteClinicData, loading: deleteClinicLoading,
        error: deleteClinicError }] = useMutation(DELETE_CLINIC);

    useEffect(() => {
        if(createClinicLoading === false && createClinicData) {
            processSuccess(`Clínica ${createClinicData.createClinic.id} creada`);
        } else if (createClinicError) {
            showNotificationError(createClinicError.message);
        }
    }, [createClinicData, createClinicLoading, createClinicError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(updateClinicLoading === false && updateClinicData) {
            processSuccess(`Clínica ${updateClinicData.updateClinic.id} actualizada`);
        } else if (updateClinicError) {
            showNotificationError(updateClinicError.message);
        }
    }, [updateClinicData, updateClinicLoading, updateClinicError, processSuccess, showNotificationError]);

    useEffect(() => {
        if(deleteClinicLoading === false && deleteClinicData) {
            if (deleteClinicData.deleteClinic) {
                processSuccess(`Clínica ${deleteClinicData.deleteClinic} eliminada`);
            } else {
                showNotificationError('Error eliminando la clínica');
            }
        } else if (deleteClinicError) {
            showNotificationError(deleteClinicError.message);
        }
    }, [deleteClinicData, deleteClinicLoading, deleteClinicError, processSuccess, showNotificationError]);

    //cleanup
    useEffect(() => {
        return () => {};
    }, []);

    const chageProvince = e => {
        setCity('');
        setProvince(e.target.value);
    };

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <Grid container direction="column" className={classes.formContainer}>
                    <Typography variant="subtitle1">Datos de la clínica</Typography>
                    <Divider />
                    <Grid container className={classes.section}>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="name"
                                label="Nombre"
                                value={name || ''}
                                onChange={(e)=>{setName(e.target.value)}}
                                required
                                inputProps={{
                                    maxLength: 100,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="email"
                                label="Email"
                                value={email || ''}
                                onChange={(e)=>{setEmail(e.target.value)}}
                                inputProps={{
                                    maxLength: 255,
                                }}
                                error={email && !isEmail(email)}
                                helperText="Introduce un email válido"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="phone"
                                label="Teléfono"
                                value={phone || ''}
                                onChange={(e)=>{setPhone(e.target.value)}}
                                inputProps={{
                                    maxLength: 25,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="phone2"
                                label="Teléfono 2"
                                value={phone2 || ''}
                                onChange={(e)=>{setPhone2(e.target.value)}}
                                inputProps={{
                                    maxLength: 25,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="address1"
                                label="Dirección 1"
                                value={address1 || ''}
                                onChange={(e)=>{setAddress1(e.target.value)}}
                                inputProps={{
                                    maxLength: 255,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField id="address2"
                                label="Dirección 2"
                                value={address2 || ''}
                                onChange={(e)=>{setAddress2(e.target.value)}}
                                inputProps={{
                                    maxLength: 255,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="province"
                                select
                                label="Provincia"
                                value={provinces.length === 0 ? '' : (province || '')}
                                onChange={chageProvince}
                                helperText="Selecciona la provincia"
                                required
                            >
                                {provinces.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.field}>
                            <TextField
                                id="city"
                                select
                                label="Ciudad"
                                value={cities.length === 0 ? '' : (city || '')}
                                onChange={(e)=>{setCity(e.target.value)}}
                                helperText="Selecciona la ciudad"
                                required
                            >
                                {cities.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    {(dataToUpdate?.clients?.length > 0) &&
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Clientes asociados</Typography>
                                <TableContainer component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Expediente</TableCell>
                                                <TableCell>Nombre</TableCell>
                                                <TableCell>Apellidos</TableCell>
                                                <TableCell>Fecha de tratramiento</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataToUpdate.clients.slice().sort((a, b) => b.start_treatment_date - a.start_treatment_date).map((expedientClient) => (
                                                <TableRow key={`${expedientClient.expedient.id} - ${expedientClient.client.id}`}>
                                                    <TableCell>{expedientClient.expedient.id}</TableCell>
                                                    <TableCell>{expedientClient.client.first_name}</TableCell>
                                                    <TableCell>{expedientClient.client.last_name}</TableCell>
                                                    <TableCell>
                                                        {expedientClient.start_treatment_date ? 
                                                            new Date(expedientClient.start_treatment_date).toLocaleDateString() : ''}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    }
                    <Grid container justifyContent="space-between">
                        <Grid item className={classes.field}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                type="submit"
                                className={classes.button}
                            >
                                Guardar
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CancelIcon />}
                                className={classes.button}
                                onClick={closeForm}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        {dataToUpdate &&
                            <Grid item className={classes.deleteButtonField}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => setOpenDeleteDialog(true)}
                                >
                                    Eliminar
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </form>
            <BackdropLoading open={createClinicLoading || updateClinicLoading || deleteClinicLoading || provincesLoading || citiesLoading} />
            <ConfirmDialog
                open={openDeleteDialog}
                setOpen={setOpenDeleteDialog}
                title="Eliminar cliníca"
                text="¿Está seguro de que quiere eliminar la clínica?"
                confirmText="Eliminar"
                handleConfirm={handleDeleteButton}
            />
        </>
    );
};

export default ClinicForm;
